import { SORT_DIRECTIONS } from '@core/constants';

const currentYear = new Date().getFullYear();

export const COLUMNS = {
  // NAME

  name: {
    value: 'name',
    label: 'Name of the Fund/Program',
    columnLabel: 'Name of the Fund/Program',
  },

  compName: {
    value: 'compName',
    label: 'Name',
    columnLabel: 'Name',
  },

  // BASIC

  type: {
    value: 'type',
    label: 'Fund/Program Type',
    columnLabel: 'Type',
  },
  compName: {
    value: 'compName',
    label: 'Management Company Name',
    columnLabel: 'Mgt Comp Name',
  },
  cik: {
    value: 'cik',
    label: 'CIK (Central Index Key)',
    columnLabel: 'CIK',
  },
  compCountry: {
    value: 'compCountry',
    label: 'Management Company Country',
    columnLabel: 'Mgt Comp Country',
  },
  compState: {
    value: 'compState',
    label: 'Management Company State',
    columnLabel: 'Mgt Comp State',
  },
  denomination: {
    value: 'denomination',
    label: 'Fund Local Currency',
    columnLabel: 'Fund Currency',
  },
  denominationMum: {
    value: 'denominationMum',
    label: 'Currency Denomination of Fund AUM',
    columnLabel: 'AUM Currency',
  },
  hurdle: {
    value: 'hurdle',
    label: 'Fund Hurdle Rate',
    columnLabel: 'Hurdle Rate',
  },
  isin: {
    value: 'isin',
    label: 'ISIN (International Securities Identification Number)',
    columnLabel: 'ISIN',
  },
  lockup: {
    value: 'lockup',
    label: 'Initial Lockup Period',
    columnLabel: 'Lockup',
  },
  primaryBenchmark: {
    value: 'primaryBenchmark',
    label: 'Primary Benchmark',
    columnLabel: 'Benchmark1',
  },
  secondaryBenchmark: {
    value: 'secondaryBenchmark',
    label: 'Secondary Benchmark',
    columnLabel: 'Benchmark2',
  },
  redemptionFees: {
    value: 'redemptionFees',
    label: 'Redemption Fee Policy',
    columnLabel: 'Redempt. Fees',
  },
  redemptions: {
    value: 'redemptions',
    label: 'Redemption Window Frequency',
    columnLabel: 'Redempt. Freq.',
  },
  stateDomicile: {
    value: 'stateDomicile',
    label: 'Fund/Program Domicile',
    columnLabel: 'Domicile',
  },
  subscriptions: {
    value: 'subscriptions',
    label: 'Subscription Window Frequency',
    columnLabel: 'Subscr. Freq.',
  },
  subsequentInvestmentSize: {
    value: 'subsequentInvestmentSize',
    label: 'Subsequent Investment Minimum',
    columnLabel: 'Follow-on Min.',
  },
  us: {
    value: 'us',
    label: 'Fund Structure Modifiers',
    columnLabel: 'Struct. Modifiers',
  },

  // STRATEGY

  strategy: {
    value: 'strategy',
    label: 'Strategy',
    columnLabel: 'Strategy',
  },
  proprietaryAccount: {
    value: 'proprietaryAccount',
    label: 'Proprietary Trading Accounts',
    columnLabel: 'Prop. Trading Acc.',
  },
  managedAccount: {
    value: 'managedAccount',
    label: 'Managed Accounts',
    columnLabel: 'Managed Accounts',
  },
  primaryCat: {
    value: 'primaryCat',
    label: 'Primary Strategy',
    columnLabel: 'Primary Strategy',
  },
  sec13f: {
    value: 'sec13f',
    label: 'SEC 13F',
    columnLabel: 'SEC 13F',
  },

  // DETAILED

  open: {
    value: 'open',
    label: 'Open/Closed Status',
    columnLabel: 'Open/Closed',
  },
  denominat: {
    value: 'denominat',
    label: 'Fund/Program Denomination',
    columnLabel: 'Denomination',
  },
  exchangeListing: {
    value: 'exchangeListing',
    label: 'Listed on Exchange',
    columnLabel: 'Listed on Exchange',
  },
  geoFocus: {
    value: 'geoFocus',
    label: 'Geographical Focus',
    columnLabel: 'Geo. Focus',
  },
  highwater: {
    value: 'highwater',
    label: 'Uses a High Water Mark?',
    columnLabel: 'Highwater Mark',
  },
  returnType: {
    value: 'returnType',
    label: 'Reporting Basis',
    columnLabel: 'Reporting Basis',
  },

  // CUSTOM

  // CUSTOM -> GENERAL

  active: {
    value: 'active',
    label: 'Active Status',
    columnLabel: 'Status',
  },
  annualStandardDev: {
    value: 'annualStandardDev',
    label: 'Annual STD',
    columnLabel: 'Annual STD',
  },
  aum: {
    value: 'aum',
    label: 'Fund AUM',
    columnLabel: 'Fund AUM',
  },
  createDate: {
    value: 'createDate',
    label: 'Created on Date',
    columnLabel: 'Created Date',
  },
  dataUsage: {
    value: 'dataUsage',
    label: 'Data Usage',
    columnLabel: 'Data Usage',
  },
  inceptionDate: {
    value: 'inceptionDate',
    label: 'Fund Inception Date',
    columnLabel: 'Fund Inception',
  },
  incentiveFee: {
    value: 'incentiveFee',
    label: 'Incentive Fee',
    columnLabel: 'Incentive Fee',
  },
  lastModified: {
    value: 'lastModified',
    label: 'Last Modified Date',
    columnLabel: 'Last Mod. Date',
  },
  lastRor: {
    value: 'lastRoR',
    label: 'Last RoR',
    columnLabel: 'Last RoR',
  },
  lastMonth: {
    value: 'lastMonth',
    label: 'Last Updated Track Record',
    columnLabel: 'Last Modified',
  },
  leadFund: {
    value: 'leadFund',
    label: 'Is this a lead fund?',
    columnLabel: 'Lead?',
  },
  leverage: {
    value: 'leverage',
    label: 'Leverage',
    columnLabel: 'Leverage',
  },
  managerAssets: {
    value: 'managerAssets',
    label: 'Manager Assets',
    columnLabel: 'Manager Assets',
  },
  managementFee: {
    value: 'managementFee',
    label: 'Management Fee',
    columnLabel: 'Mgt Fee',
  },
  meRatio: {
    label: 'Inception to Date Margin/Equity Ratio',
    columnLabel: '(ITD) Margin/Equity Ratio',
    value: 'meRatio',
  },
  monthlyStandardDev: {
    value: 'monthlyStandardDev',
    label: 'Monthly STD',
    columnLabel: 'Monthly STD',
  },
  minAccount: {
    value: 'minAccount',
    label: 'Minimum Account',
    columnLabel: 'Minimum Account',
  },
  minInvest: {
    value: 'minInvest',
    label: 'Minimum Investment',
    columnLabel: 'Minimum Investment',
  },
  skewness: {
    value: 'skewness',
    label: 'Skewness',
    columnLabel: 'Skewness',
  },
  sharpeRatio: {
    value: 'sharpeRatio',
    label: 'Sharpe Ratio',
    columnLabel: 'Sharpe Ratio',
  },
  worstDrawDown: {
    value: 'worstDrawDown',
    label: 'Worst Drawdown',
    columnLabel: 'Worst Drawdown',
  },

  // CUSTOM -> RETURN

  // CUSTOM -> RETURN -> ANNUALIZED RETURN

  compoundAnnualReturn3year: {
    value: 'compoundAnnualReturn3year',
    label: '3 Years',
    columnLabel: '(3Y) Ann. Return',
  },
  compoundAnnualReturn5year: {
    value: 'compoundAnnualReturn5year',
    label: '5 Years',
    columnLabel: '(5Y) Ann. Return',
  },
  compoundAnnualReturn7year: {
    value: 'compoundAnnualReturn7year',
    label: '7 Years',
    columnLabel: '(7Y) Ann. Return',
  },
  compoundAnnualReturn10year: {
    value: 'compoundAnnualReturn10year',
    label: '10 Years',
    columnLabel: '(10Y) Ann. Return',
  },
  compoundAnnualReturn: {
    value: 'compoundAnnualReturn',
    label: 'Inception to Date (ITD)',
    columnLabel: '(ITD) Ann. Return',
  },

  // CUSTOM -> RETURN -> COMPOUNDED RETURN

  yearToDate: {
    value: 'yearToDate',
    label: 'Year to Date',
    columnLabel: '(YTD) Comp. Return',
  },
  return1Year: {
    value: 'return1Year',
    label: `${currentYear - 1}`,
    columnLabel: `(${currentYear - 1}) Comp. Return`,
  },
  return2Year: {
    value: 'return2Year',
    label: `${currentYear - 2}`,
    columnLabel: `(${currentYear - 2}) Comp. Return`,
  },
  return3Year: {
    value: 'return3Year',
    label: `${currentYear - 3}`,
    columnLabel: `(${currentYear - 3}) Comp. Return`,
  },
  return4Year: {
    value: 'return4Year',
    label: `${currentYear - 4}`,
    columnLabel: `(${currentYear - 4}) Comp. Return`,
  },
  return5Year: {
    value: 'return5Year',
    label: `${currentYear - 5}`,
    columnLabel: `(${currentYear - 5}) Comp. Return`,
  },
  totalReturn: {
    value: 'totalReturn',
    label: 'Inception to Date',
    columnLabel: '(ITD) Comp. Return',
  },

  // CUSTOM -> RETURN -> Average Monthly Return

  averageMonthlyReturn: {
    value: 'averageMonthlyReturn',
    label: 'Average Monthly Return',
    columnLabel: 'Avg. Monthly Return',
  },

  // CUSTOM -> COMPARISON

  // CUSTOM -> COMPARISON -> ALPHA VS

  alphaVsUsTreasury: {
    value: 'alphaVsUsTreasury',
    label: '3m US T-Bills',
    columnLabel: 'Alpha v. T-Bills',
  },
  alphaVsCta: {
    value: 'alphaVsCta',
    label: 'BarclayHedge CTA Index',
    columnLabel: 'Alpha v. CTA',
  },
  alphaVsEquity: {
    value: 'alphaVsEquity',
    label: 'BarclayHedge Equity L/S Index',
    columnLabel: 'Alpha v. Equity L/S',
  },
  alphaVsFof: {
    value: 'alphaVsFof',
    label: 'BarclayHedge FoF Index',
    columnLabel: 'Alpha v. FoF',
  },
  alphaVsHf: {
    value: 'alphaVsHf',
    label: 'BarclayHedge HF Index',
    columnLabel: 'Alpha v. HF',
  },
  alphaVsDow: {
    value: 'alphaVsDow',
    label: 'Dow Jones Industrial Average',
    columnLabel: 'Alpha v. Dow',
  },
  alphaVsFtSe: {
    value: 'alphaVsFtSe',
    label: 'FTSE 100 Index',
    columnLabel: 'Alpha v. FTSE',
  },
  alphaVsJpMorgan: {
    value: 'alphaVsJpMorgan',
    label: 'JPMorgan World Government Bond Index',
    columnLabel: 'Alpha v. JPM Gov Bond',
  },
  alphaVsLehman: {
    value: 'alphaVsLehman',
    label: 'Lehman Brothers Treasury Index ',
    columnLabel: 'Alpha v. LehmanTreasury',
  },
  alphaVsEafe: {
    value: 'alphaVsEafe',
    label: 'MSCI EAFE Index',
    columnLabel: 'Alpha v. MSCI EAFE',
  },
  alphaVsMsciWorld: {
    value: 'alphaVsMsciWorld',
    label: 'MSCI World',
    columnLabel: 'Alpha v. MSCI World',
  },
  alphaVsNasdaq: {
    value: 'alphaVsNasdaq',
    label: 'NASDAQ',
    columnLabel: 'Alpha v. NASDAQ',
  },
  alphaVsNikkei: {
    value: 'alphaVsNikkei',
    label: 'Nikkei 225 Index',
    columnLabel: 'Alpha v. Nikkei225',
  },
  alphaVsRussel: {
    value: 'alphaVsRussel',
    label: 'Russell 2000',
    columnLabel: 'Alpha v. Russel2k',
  },
  alphaVsSp500: {
    value: 'alphaVsSp500',
    label: 'S&P 500',
    columnLabel: 'Alpha v. S&P500',
  },

  // CUSTOM -> COMPARISON -> BETA VS

  betaVsUsTreasury: {
    value: 'betaVsUsTreasury',
    label: '3m US T-Bills',
    columnLabel: 'Beta v. T-Bills',
  },
  betaVsCta: {
    value: 'betaVsCta',
    label: 'BarclayHedge CTA Index',
    columnLabel: 'Beta v. CTA',
  },
  betaVsEquity: {
    value: 'betaVsEquity',
    label: 'BarclayHedge Equity L/S Index',
    columnLabel: 'Beta v. Equity L/S',
  },
  betaVsFof: {
    value: 'betaVsFof',
    label: 'BarclayHedge FoF Index',
    columnLabel: 'Beta v. FoF',
  },
  betaVsHf: {
    value: 'betaVsHf',
    label: 'BarclayHedge HF Index',
    columnLabel: 'Beta v. HF',
  },
  betaVsDow: {
    value: 'betaVsDow',
    label: 'Dow Jones Industrial Average',
    columnLabel: 'Beta v. Dow',
  },
  betaVsFtSe: {
    value: 'betaVsFtSe',
    label: 'FTSE 100 Index',
    columnLabel: 'Beta v. FTSE',
  },
  betaVsJpMorgan: {
    value: 'betaVsJpMorgan',
    label: 'JPMorgan World Government Bond Index',
    columnLabel: 'Beta v. JPM Gov Bond',
  },
  betaVsLehman: {
    value: 'betaVsLehman',
    label: 'Lehman Brothers Treasury Index ',
    columnLabel: 'Beta v. LehmanTreasury',
  },
  betaVsEafe: {
    value: 'betaVsEafe',
    label: 'MSCI EAFE Index',
    columnLabel: 'Beta v. MSCI EAFE',
  },
  betaVsMsciWorld: {
    value: 'betaVsMsciWorld',
    label: 'MSCI World',
    columnLabel: 'Beta v. MSCI World',
  },
  betaVsNasdaq: {
    value: 'betaVsNasdaq',
    label: 'NASDAQ',
    columnLabel: 'Beta v. NASDAQ',
  },
  betaVsNikkei: {
    value: 'betaVsNikkei',
    label: 'Nikkei 225 Index',
    columnLabel: 'Beta v. Nikkei225',
  },
  betaVsRussel: {
    value: 'betaVsRussel',
    label: 'Russell 2000',
    columnLabel: 'Beta v. Russel2k',
  },
  betaVsSp500: {
    value: 'betaVsSp500',
    label: 'S&P 500',
    columnLabel: 'Beta v. S&P500',
  },

  // CUSTOM -> COMPARISON -> CORRELATION VS

  corrVsUsTreasury: {
    value: 'corrVsUsTreasury',
    label: '3m US T-Bills',
    columnLabel: 'Correl v. T-Bills',
  },
  corrVsCta: {
    value: 'corrVsCta',
    label: 'BarclayHedge CTA Index',
    columnLabel: 'Correl v. CTA',
  },
  corrVsEquity: {
    value: 'corrVsEquity',
    label: 'BarclayHedge Equity L/S Index',
    columnLabel: 'Correl v. Equity L/S',
  },
  corrVsFof: {
    value: 'corrVsFof',
    label: 'BarclayHedge FoF Index',
    columnLabel: 'Correl v. FoF',
  },
  corrVsHf: {
    value: 'corrVsHf',
    label: 'BarclayHedge HF Index',
    columnLabel: 'Correl v. HF',
  },
  corrVsDow: {
    value: 'corrVsDow',
    label: 'Dow Jones Industrial Average',
    columnLabel: 'Correl v. Dow',
  },
  corrVsFtSe: {
    value: 'corrVsFtSe',
    label: 'FTSE 100 Index',
    columnLabel: 'Correl v. FTSE',
  },
  corrVsJpMorgan: {
    value: 'corrVsJpMorgan',
    label: 'JPMorgan World Government Bond Index',
    columnLabel: 'Correl v. JPM Gov Bond',
  },
  corrVsLehman: {
    value: 'corrVsLehman',
    label: 'Lehman Brothers Treasury Index ',
    columnLabel: 'Correl v. LehmanTreasury',
  },
  corrVsEafe: {
    value: 'corrVsEafe',
    label: 'MSCI EAFE Index',
    columnLabel: 'Correl v. MSCI EAFE',
  },
  corrVsMsciWorld: {
    value: 'corrVsMsciWorld',
    label: 'MSCI World',
    columnLabel: 'Correl v. MSCI World',
  },
  corrVsNasdaq: {
    value: 'corrVsNasdaq',
    label: 'NASDAQ',
    columnLabel: 'Correl v. NASDAQ',
  },
  corrVsNikkei: {
    value: 'corrVsNikkei',
    label: 'Nikkei 225 Index',
    columnLabel: 'Correl v. Nikkei225',
  },
  corrVsRussel: {
    value: 'corrVsRussel',
    label: 'Russell 2000',
    columnLabel: 'Correl v. Russel2k',
  },
  corrVsSp500: {
    value: 'corrVsSp500',
    label: 'S&P 500',
    columnLabel: 'Correl v. S&P500',
  },

  // CUSTOM -> COMPARISON -> R SQUARED VS

  rSqVsUsTreasury: {
    value: 'rSqVsUsTreasury',
    label: '3m US T-Bills',
    columnLabel: 'R2 v. T-Bills',
  },
  rSqVsCta: {
    value: 'rSqVsCta',
    label: 'BarclayHedge CTA Index',
    columnLabel: 'R2 v. CTA',
  },
  rSqVsEquity: {
    value: 'rSqVsEquity',
    label: 'BarclayHedge Equity L/S Index',
    columnLabel: 'R2 v. Equity L/S',
  },
  rSqVsFof: {
    value: 'rSqVsFof',
    label: 'BarclayHedge FoF Index',
    columnLabel: 'R2 v. FoF',
  },
  rSqVsHf: {
    value: 'rSqVsHf',
    label: 'BarclayHedge HF Index',
    columnLabel: 'R2 v. HF',
  },
  rSqVsDow: {
    value: 'rSqVsDow',
    label: 'Dow Jones Industrial Average',
    columnLabel: 'R2 v. Dow',
  },
  rSqVsFtSe: {
    value: 'rSqVsFtSe',
    label: 'FTSE 100 Index',
    columnLabel: 'R2 v. FTSE',
  },
  rSqVsJpMorgan: {
    value: 'rSqVsJpMorgan',
    label: 'JPMorgan World Government Bond Index',
    columnLabel: 'R2 v. JPM Gov Bond',
  },
  rSqVsLehman: {
    value: 'rSqVsLehman',
    label: 'Lehman Brothers Treasury Index ',
    columnLabel: 'R2 v. LehmanTreasury',
  },
  rSqVsEafe: {
    value: 'rSqVsEafe',
    label: 'MSCI EAFE Index',
    columnLabel: 'R2 v. MSCI EAFE',
  },
  rSqVsMsciWorld: {
    value: 'rSqVsMsciWorld',
    label: 'MSCI World',
    columnLabel: 'R2 v. MSCI World',
  },
  rSqVsNasdaq: {
    value: 'rSqVsNasdaq',
    label: 'NASDAQ',
    columnLabel: 'R2 v. NASDAQ',
  },
  rSqVsNikkei: {
    value: 'rSqVsNikkei',
    label: 'Nikkei 225 Index',
    columnLabel: 'R2 v. Nikkei225',
  },
  rSqVsRussel: {
    value: 'rSqVsRussel',
    label: 'Russell 2000',
    columnLabel: 'R2 v. Russel2k',
  },
  rSqVsSp500: {
    value: 'rSqVsSp500',
    label: 'S&P 500',
    columnLabel: 'R2 v. S&P500',
  },

  // CUSTOM -> COMPARISON -> UP CAPTURE VS

  upCapVsUsTreasury: {
    value: 'upCapVsUsTreasury',
    label: '3m US T-Bills',
    columnLabel: 'UpCap v. T-Bills',
  },
  upCapVsCta: {
    value: 'upCapVsCta',
    label: 'BarclayHedge CTA Index',
    columnLabel: 'UpCap v. CTA',
  },
  upCapVsEquity: {
    value: 'upCapVsEquity',
    label: 'BarclayHedge Equity L/S Index',
    columnLabel: 'UpCap v. Equity L/S',
  },
  upCapVsFof: {
    value: 'upCapVsFof',
    label: 'BarclayHedge FoF Index',
    columnLabel: 'UpCap v. FoF',
  },
  upCapVsHf: {
    value: 'upCapVsHf',
    label: 'BarclayHedge HF Index',
    columnLabel: 'UpCap v. HF',
  },
  upCapVsDow: {
    value: 'upCapVsDow',
    label: 'Dow Jones Industrial Average',
    columnLabel: 'UpCap v. Dow',
  },
  upCapVsFtSe: {
    value: 'upCapVsFtSe',
    label: 'FTSE 100 Index',
    columnLabel: 'UpCap v. FTSE',
  },
  upCapVsJpMorgan: {
    value: 'upCapVsJpMorgan',
    label: 'JPMorgan World Government Bond Index',
    columnLabel: 'UpCap v. JPM Gov Bond',
  },
  upCapVsLehman: {
    value: 'upCapVsLehman',
    label: 'Lehman Brothers Treasury Index ',
    columnLabel: 'UpCap v. LehmanTreasury',
  },
  upCapVsEafe: {
    value: 'upCapVsEafe',
    label: 'MSCI EAFE Index',
    columnLabel: 'UpCap v. MSCI EAFE',
  },
  upCapVsMsciWorld: {
    value: 'upCapVsMsciWorld',
    label: 'MSCI World',
    columnLabel: 'UpCap v. MSCI World',
  },
  upCapVsNasdaq: {
    value: 'upCapVsNasdaq',
    label: 'NASDAQ',
    columnLabel: 'UpCap v. NASDAQ',
  },
  upCapVsNikkei: {
    value: 'upCapVsNikkei',
    label: 'Nikkei 225 Index',
    columnLabel: 'UpCap v. Nikkei225',
  },
  upCapVsRussel: {
    value: 'upCapVsRussel',
    label: 'Russell 2000',
    columnLabel: 'UpCap v. Russel2k',
  },
  upCapVsSp500: {
    value: 'upCapVsSp500',
    label: 'S&P 500',
    columnLabel: 'UpCap v. S&P500',
  },

  // CUSTOM -> COMPARISON -> DOWN CAPTURE VS

  downCapVsUsTreasury: {
    value: 'downCapVsUsTreasury',
    label: '3m US T-Bills',
    columnLabel: 'DownCap v. T-Bills',
  },
  downCapVsCta: {
    value: 'downCapVsCta',
    label: 'BarclayHedge CTA Index',
    columnLabel: 'DownCap v. CTA',
  },
  downCapVsEquity: {
    value: 'downCapVsEquity',
    label: 'BarclayHedge Equity L/S Index',
    columnLabel: 'DownCap v. Equity L/S',
  },
  downCapVsFof: {
    value: 'downCapVsFof',
    label: 'BarclayHedge FoF Index',
    columnLabel: 'DownCap v. FoF',
  },
  downCapVsHf: {
    value: 'downCapVsHf',
    label: 'BarclayHedge HF Index',
    columnLabel: 'DownCap v. HF',
  },
  downCapVsDow: {
    value: 'downCapVsDow',
    label: 'Dow Jones Industrial Average',
    columnLabel: 'DownCap v. Dow',
  },
  downCapVsFtSe: {
    value: 'downCapVsFtSe',
    label: 'FTSE 100 Index',
    columnLabel: 'DownCap v. FTSE',
  },
  downCapVsJpMorgan: {
    value: 'downCapVsJpMorgan',
    label: 'JPMorgan World Government Bond Index',
    columnLabel: 'DownCap v. JPM Gov Bond',
  },
  downCapVsLehman: {
    value: 'downCapVsLehman',
    label: 'Lehman Brothers Treasury Index ',
    columnLabel: 'DownCap v. LehmanTreasury',
  },
  downCapVsEafe: {
    value: 'downCapVsEafe',
    label: 'MSCI EAFE Index',
    columnLabel: 'DownCap v. MSCI EAFE',
  },
  downCapVsMsciWorld: {
    value: 'downCapVsMsciWorld',
    label: 'MSCI World',
    columnLabel: 'DownCap v. MSCI World',
  },
  downCapVsNasdaq: {
    value: 'downCapVsNasdaq',
    label: 'NASDAQ',
    columnLabel: 'DownCap v. NASDAQ',
  },
  downCapVsNikkei: {
    value: 'downCapVsNikkei',
    label: 'Nikkei 225 Index',
    columnLabel: 'DownCap v. Nikkei225',
  },
  downCapVsRussel: {
    value: 'downCapVsRussel',
    label: 'Russell 2000',
    columnLabel: 'DownCap v. Russel2k',
  },
  downCapVsSp500: {
    value: 'downCapVsSp500',
    label: 'S&P 500',
    columnLabel: 'DownCap v. S&P500',
  },
};

export const alwaysVisible = [COLUMNS.name.value];

export const visibleByDefault = [
  COLUMNS.lastMonth.value,
  COLUMNS.lastRor.value,
  COLUMNS.leadFund.value,
  COLUMNS.active.value,
  COLUMNS.dataUsage.value,
];

export const defaultSortingState = [
  {
    columnName: COLUMNS.name.value,
    direction: SORT_DIRECTIONS.ASC,
  },
];
