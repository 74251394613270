import * as types from '../actions/action-types';
import initialState from './initial-state';

export default function companyReducer(state = initialState.company, action) {
  switch (action.type) {
    case types.SET_COMP_CONTACT_DATA_REFRESH_STATE:
      return {
        ...state,
        compContactDataState: action.state,
      };

    case types.SET_COMP_CLIENT_BASE_REFRESH_STATE:
      return {
        ...state,
        compClientBaseDataState: action.state,
      };

    case types.SET_COMP_NOTES_REFRESH_STATE:
      return {
        ...state,
        compNotesDataState: action.state,
      };

    case types.SET_MANAGED_FUNDS_DATA_REFRESH_STATE:
      return {
        ...state,
        compManagedFundsDataState: action.state,
      };
    default:
      return state;
  }
}
