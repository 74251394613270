export const HEDGE_FUND_STRATEGY_OPTIONS = [
  {
    id: 'activist',
    optionLabel: 'Activist',
  },
  {
    id: 'algorithmic',
    optionLabel: 'Algorithmic',
  },
  {
    id: 'balanced',
    optionLabel: 'Balanced (Stocks and Bonds)',
  },
  {
    id: 'closedEnd',
    optionLabel: 'Closed-End Fund',
  },
  {
    id: 'convertArb',
    optionLabel: 'Convertible Arbitrage',
  },
  {
    id: 'distress',
    optionLabel: 'Distressed Securities',
  },
  {
    id: 'dividendCap',
    optionLabel: 'Dividend Capture',
  },
  {
    id: 'emergingMarkets',
    optionLabel: 'Emerging Markets',
    children: [
      {
        id: 'emAsia',
        optionLabel: 'Asia',
      },
      {
        id: 'emeecis',
        optionLabel: 'Eastern Europe/CIS',
      },
      {
        id: 'emGlobal',
        optionLabel: 'Global',
      },
      {
        id: 'emLatinAm',
        optionLabel: 'Latin America',
      },
      {
        id: 'emMena',
        optionLabel: 'MENA',
      },
      {
        id: 'emOther',
        optionLabel: 'Sub-Saharan Africa',
      },
    ],
  },
  {
    id: 'equity',
    optionLabel: 'Equity',
    children: [
      {
        id: 'e130',
        optionLabel: 'Equity 130-30',
      },
      {
        id: 'dedShort',
        optionLabel: 'Equity Dedicated Short',
      },
      {
        id: 'eqLongOnly',
        optionLabel: 'Equity Long Only',
      },
      {
        id: 'eqLongBias',
        optionLabel: 'Equity Long Bias',
      },
      {
        id: 'eqLongShort',
        optionLabel: 'Equity Long/Short',
      },
      {
        id: 'eqMktNeut',
        optionLabel: 'Equity Market Neutral',
      },
      {
        id: 'eqShortBias',
        optionLabel: 'Equity Short Bias',
      },
    ],
  },
  {
    id: 'eventDriven',
    optionLabel: 'Event Driven',
  },
  {
    id: 'fixedIncome',
    optionLabel: 'Fixed Income',
    children: [
      {
        id: 'fiArb',
        optionLabel: 'Arbitrage',
      },
      {
        id: 'fiAssetBackSec',
        optionLabel: 'Asset Backed Securities',
      },
      {
        id: 'fiAssetBackLoan',
        optionLabel: 'Asset Backed Loans',
      },
      {
        id: 'fiCollDebt',
        optionLabel: 'Collateralized Debt',
      },
      {
        id: 'fiConvBnd',
        optionLabel: 'Convertible Bonds',
      },
      {
        id: 'fiMisc',
        optionLabel: 'Diversified',
      },
      {
        id: 'fiHighYld',
        optionLabel: 'High Yield',
      },
      {
        id: 'fiInsSec',
        optionLabel: 'Insurance-Linked',
      },
      {
        id: 'fiLoCred',
        optionLabel: 'Long-Only Credit',
      },
      {
        id: 'fiLsCred',
        optionLabel: 'Long/Short Credit',
      },
      {
        id: 'fiMgtBack',
        optionLabel: 'Mortgage Backed',
      },
      {
        id: 'fiUncons',
        optionLabel: 'Unconstrained',
      },
    ],
  },
  {
    id: 'fundFund',
    optionLabel: 'Fund of Funds',
  },
  {
    id: 'macroFund',
    optionLabel: 'Macro',
  },
  {
    id: 'mergArb',
    optionLabel: 'Merger Arbitrage',
  },
  {
    id: 'relValArb',
    optionLabel: 'Multistrategy',
  },
  {
    id: 'mkTiming',
    optionLabel: 'Mutual Funds/ETFs',
  },
  {
    id: 'noCategoryHf',
    optionLabel: 'No Category',
  },
  {
    id: 'optionStrategies',
    optionLabel: 'Option Strategies',
  },
  {
    id: 'reguld',
    optionLabel: 'PIPEs',
  },
  {
    id: 'replication',
    optionLabel: 'Replication',
  },
  {
    id: 'sector',
    optionLabel: 'Sector',
    children: [
      {
        id: 'sEnergy',
        optionLabel: 'Energy',
      },
      {
        id: 'sEnv',
        optionLabel: 'Environment',
      },
      {
        id: 'sEsg',
        optionLabel: 'ESG',
      },
      {
        id: 'sFarming',
        optionLabel: 'Farming',
      },
      {
        id: 'sFinance',
        optionLabel: 'Financial',
      },
      {
        id: 'sHealth',
        optionLabel: 'Health Care/Biotech',
      },
      {
        id: 'sMetals',
        optionLabel: 'Metals/Mining',
      },
      {
        id: 'sMisc',
        optionLabel: 'Miscellaneous',
      },
      {
        id: 'sNatural',
        optionLabel: 'Natural Resources',
      },
      {
        id: 'sRealest',
        optionLabel: 'Real Estate',
      },
      {
        id: 'sTech',
        optionLabel: 'Technology',
      },
    ],
  },
  {
    id: 'statArb',
    optionLabel: 'Statistical Arbitrage',
  },
  {
    id: 'tailRisk',
    optionLabel: 'Tail Risk',
  },
  {
    id: 'volTrade',
    optionLabel: 'Volatility Trading',
  },
];
