import { useContext, useEffect } from 'react';
import { useCategories, useCategoryStyleOptions } from '~/api/select';
import { SelectOptionsContext } from '~/api/selectContext';

export const hfStratOptions = [
  // { label: 'No Category', value: 'noCat', match: 'fNoCat' },
  { label: 'Activist', value: 'activist', match: 'fActivist' },
  { label: 'Algorithmic', value: 'algorithmic', match: 'fAlgorithm' },
  {
    label: 'Balanced (Stocks & Bonds)',
    value: 'balanced',
    match: 'fBalanced',
  },
  { label: 'Closed-end Funds', value: 'closedEnd', match: 'fClosedEnd' },
  { label: 'Convertible Arbitrage', value: 'cnvrtarb', match: 'fCnvrtArb' },
  { label: 'Distressed Securities', value: 'distress', match: 'fDistress' },
  { label: 'Dividend Capture', value: 'dividendCap', match: 'fDivCapt' },
  { label: 'Emerging Markets - Asia', value: 'emAsia', match: 'fEMAsia' },
  { label: 'Emerging Markets - MENA', value: 'emMena', match: 'fEMMENA' },
  {
    label: 'Emerging Markets - Sub Saharan',
    value: 'emOther',
    match: 'fEMOther',
  },
  { label: 'Equity 130-30', value: 'e130', match: 'fE130_30' },
  { label: 'Equity Dedicated Short', value: 'dedShort', match: 'fDedShort' },
  { label: 'Equity Long Only', value: 'longOnly', match: 'fMktLong' },
  { label: 'Equity Long-Bias', value: 'eqtyNonhg', match: 'fEqtyNonHg' },
  { label: 'Equity Long/Short', value: 'eqtyHedge', match: 'fEqtyHedge' },
  { label: 'Equity Market Neutral', value: 'eqtyMneut', match: 'fEqtyMNeut' },
  { label: 'Equity Short-Bias', value: 'shortSell', match: 'fShortSell' },
  { label: 'Event Driven', value: 'eventDriven', match: 'fEventDrvn' },
  { label: 'Fixed Income - Arbitrage', value: 'fiArb', match: 'fFiArb' },
  {
    label: 'Fixed Income - Asset Backed Securities',
    value: 'fiAssetBack',
    match: 'fAssetBack',
  },
  {
    label: 'Fixed Income - Asset-Backed Loans',
    value: 'fiAssetBackLoans',
    match: 'fAssBkLoan',
  },
  {
    label: 'Fixed Income - Collateralized Debt Obligations',
    value: 'fiCollDebt',
    match: 'fCollDebt',
  },
  {
    label: 'Fixed Income - Convertible Bonds',
    value: 'fiConvbnd',
    match: 'fFiConvBnd',
  },
  { label: 'Fixed Income - Diversified', value: 'fiMisc', match: 'fFiMisc' },
  {
    label: 'Fixed Income - High Yield',
    value: 'fiHighyld',
    match: 'fFiHighYld',
  },
  {
    label: 'Fixed Income - Insurance-Linked Securities',
    value: 'fiIls',
    match: 'fFIILS',
  },
  {
    label: 'Fixed Income - Long-Only Credit',
    value: 'fiLoCred',
    match: 'fFILOCred',
  },
  {
    label: 'Fixed Income - Long/Short Credit',
    value: 'fiLsCred',
    match: 'fFILSCred',
  },
  {
    label: 'Fixed Income - Mortgage Backed',
    value: 'fiMgtBack',
    match: 'fFiMtgBack',
  },
  {
    label: 'Fixed Income - Unconstrained',
    value: 'fiUnconstrained',
    match: 'fFIUnconst',
  },
  { label: 'Fund of Funds', value: 'fundFund', match: 'fFundFund' },
  { label: 'Macro', value: 'macroFnd', match: 'fMacroFnd' },
  { label: 'Merger Arbitrage', value: 'mergarb', match: 'fMergArb' },
  { label: 'Multi-Strategy', value: 'relvalarb', match: 'fRelValArb' },
  { label: 'Mutual Funds/ETFs', value: 'mkTiming', match: 'fMkTiming' },
  { label: 'Option Strategies', value: 'optnStrat', match: 'fOptnStrat' },
  { label: 'PIPEs', value: 'reguld', match: 'fRegulD' },
  { label: 'Replication', value: 'replication', match: 'fReplicatn' },
  { label: 'Sector - Energy', value: 'sEnergy', match: 'fSEnergy' },
  { label: 'Sector - Environment', value: 'sEnv', match: 'fSEnviro' },
  { label: 'Sector - ESG', value: 'sEsg', match: 'fSESG' },
  { label: 'Sector - Farming', value: 'sFarming', match: 'fSFarming' },
  { label: 'Sector - Financial', value: 'sFinance', match: 'fSFinance' },
  {
    label: 'Sector - Health Care/Biotech',
    value: 'sHealth',
    match: 'fSHealth',
  },
  { label: 'Sector - Metals/Mining', value: 'sMetals', match: 'fSMetals' },
  { label: 'Sector - Miscellaneous', value: 'sMisc', match: 'fSMisc' },
  {
    label: 'Sector - Natural Resources',
    value: 'sNatural',
    match: 'fSNatRes',
  },
  { label: 'Sector - Real Estate', value: 'sRealest', match: 'fSRealEst' },
  { label: 'Sector - Technology', value: 'stechnol', match: 'fSTechnol' },
  { label: 'Statistical Arbitrage', value: 'statarbtg', match: 'fStatArbtg' },
  { label: 'Tail Risk', value: 'tailRisk', match: 'fTailRisk' },
  { label: 'Volatility Trading', value: 'volatility', match: 'fVolTrad' },
];
export const useProgramComposition = () => {
  const { categoryStyles: categoryStyleOptions, categories: categoryOptions } =
    useContext(SelectOptionsContext);

  const getStylesForCategory = (category) => {
    let temp = [];
    for (let styleOptionId in categoryStyleOptions) {
      let styleOption = categoryStyleOptions[styleOptionId];
      if (styleOption?.code?.toLowerCase() === category?.toLowerCase()) {
        temp.push(styleOption);
      }
    }
    if (temp.length === 0) {
      return [{ value: 'N/A', label: 'N/A' }];
    }
    return temp;
  };

  const isLoading = () => {
    return !categoryOptions || !categoryStyleOptions;
  };

  return {
    categoryOptions,
    getStylesForCategory,
    isLoading,
  };
};
