import React from 'react';
import {
  FormTextInput,
  FormContainer,
  FormCheckbox,
  FormSelectInput,
} from '@common/form-inputs';
import { PROGRAM_TYPES } from '@core/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import { YES_NO_FLAG_OPTIONS } from '@common/form-inputs/constants';

export const ProgramInfoFundFlags = ({ data, control }) => {
  const { rowStyles, colStyle } = useLayoutForm();

  return (
    <FormContainer title="Fund Flags">
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormSelectInput
            name="dailyData"
            label="Daily Data"
            options={YES_NO_FLAG_OPTIONS}
            defaultValue={YES_NO_FLAG_OPTIONS.find(
              (x) => String(x.value) === data?.dailyData
            )}
            control={control}
          />

          <FormSelectInput
            name="composite"
            label="Composite"
            control={control}
            options={YES_NO_FLAG_OPTIONS}
            defaultValue={YES_NO_FLAG_OPTIONS.find(
              (x) => String(x.value) === data?.composite
            )}
          />
        </div>

        <div className={colStyle}>
          <FormSelectInput
            name="aifmd"
            label="AIFMD"
            control={control}
            options={YES_NO_FLAG_OPTIONS}
            defaultValue={YES_NO_FLAG_OPTIONS.find(
              (x) => String(x.value) === data?.aifmd
            )}
          />

          <FormSelectInput
            name="leadFund"
            label="Lead?"
            options={YES_NO_FLAG_OPTIONS}
            defaultValue={YES_NO_FLAG_OPTIONS.find(
              (x) => String(x.value) === data?.leadFund
            )}
            control={control}
          />
        </div>

        <div className={colStyle}>
          {data.type === PROGRAM_TYPES.HedgeFund && (
            <FormSelectInput
              name="managedAccount"
              label="Managed Account"
              options={YES_NO_FLAG_OPTIONS}
              defaultValue={YES_NO_FLAG_OPTIONS.find(
                (x) => String(x.value) === data?.managedAccount
              )}
              control={control}
            />
          )}

          <FormSelectInput
            name="noRank"
            label="Exclude Rankings"
            control={control}
            options={YES_NO_FLAG_OPTIONS}
            defaultValue={YES_NO_FLAG_OPTIONS.find(
              (x) => String(x.value) === data?.noRank
            )}
          />
        </div>

        <div className={colStyle}>
          <FormSelectInput
            name="exclPublisher"
            label="Exclude Publisher"
            control={control}
            options={YES_NO_FLAG_OPTIONS}
            defaultValue={YES_NO_FLAG_OPTIONS.find(
              (x) => String(x.value) === data?.exclPublisher
            )}
          />

          <FormSelectInput
            name="doNotContact"
            label="Do Not Contact"
            control={control}
            options={YES_NO_FLAG_OPTIONS}
            defaultValue={YES_NO_FLAG_OPTIONS.find(
              (x) => String(x.value) === data?.doNotContact
            )}
          />
        </div>
      </div>
    </FormContainer>
  );
};

ProgramInfoFundFlags.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
};
