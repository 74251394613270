import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFundsUnderManagementTable } from './hooks';
import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import { Table as BsgTable } from '@backstop/grid-view';

export const CompanyFundsUnderManagementTable = () => {
  const compId = useParams().id;
  const {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useFundsUnderManagementTable(compId);
  if (loading) {
    return <Loader />;
  }
  return (
    <div style={{ marginLeft: '0.25rem' }}>
      <GenericTable
        rows={data}
        cols={{
          date: { value: 'date', label: 'Date', columnLabel: 'Date' },
          confirmed: {
            value: 'confirmed',
            label: 'Confirmed?',
            columnLabel: 'Confirmed?',
          },
          fum: {
            value: 'fum',
            label: 'Funds Managed ($K)',
            columnLabel: 'Funds Managed ($K)',
          },
        }}
        cellComponent={FundsUnderManagementTableCell}
        totalElements={meta?.totalElements}
        pageSize={pageSize}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        showPagination={true}
        isLoading={loading}
      />
    </div>
  );
};

const FundsUnderManagementTableCell = (props) => {
  let compId = props.row.compId;
  let date = props.row.date;
  if (props.column.name === 'date') {
    return (
      <BsgTable.Cell>
        <Link to={`/company/${compId}/fum/${date}`}>{props.row.date}</Link>
      </BsgTable.Cell>
    );
  }
  if (props.column.name === 'confirmed') {
    return (
      <BsgTable.Cell>{props.row.confirmed === 1 ? 'Yes' : 'No'}</BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
