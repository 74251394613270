import { Button } from '@backstop/react-core';
import { FormTextInput } from '@common/form-inputs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useInvestPlatForm } from './hooks';

export const InvestablePlatformForm = () => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm();
  const programId = useParams().id;
  const platId = useParams().platId;
  const navigate = useNavigate();

  const { saveInvestPlat, data } = useInvestPlatForm(programId, platId);

  useEffect(() => {
    reset(data);
  }, [data]);

  const onSubmit = (data) => {
    data.progId = programId;
    saveInvestPlat(data);
    navigate(`/program/${programId}/investPlat`);
  };

  const fields = {
    name: <FormTextInput name="contactName" label="Name" control={control} />,
    phone: (
      <FormTextInput name="contactPhone" label="Phone" control={control} />
    ),
    email: (
      <FormTextInput name="contactEmail" label="Email" control={control} />
    ),
    provider: (
      <FormTextInput name="provider" label="Provider" control={control} />
    ),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.name}

      {fields.phone}

      {fields.email}

      {fields.provider}

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button type="submit" kind="primary" version="v3">
          Save
        </Button>
        <Button
          kind="secondary"
          version="v3"
          onClick={() => navigate(`/program/${programId}/investPlat`)}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
