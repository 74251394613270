import { Button } from '@backstop/react-core';
import React from 'react';
import { useParams } from 'react-router-dom';

export const ExportTrackRecord = () => {
  const progId = useParams().id;
  const downloadFile = () => {
    window.open(
      process.env.REACT_APP_BARCLAYHEDGE_API_URL +
        `/program/${progId}/trackRecord/download`,
      '_blank'
    );
  };
  return (
    <Button onClick={downloadFile} version="v3">
      Export Track Record Data
    </Button>
  );
};
