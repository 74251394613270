import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from './browser-storage';

const TOKEN = 'access-token';
const TOKEN_EXP = 'access-token-exp';

export const setToken = (value) => setLocalStorage(TOKEN, value);
export const removeToken = () => removeLocalStorage(TOKEN);
export const getToken = () => getLocalStorage(TOKEN);

export const setTokenExpTime = (value) => setLocalStorage(TOKEN_EXP, value);
export const removeTokenExpTime = () => removeLocalStorage(TOKEN_EXP);
export const getTokenExpTime = () => getLocalStorage(TOKEN_EXP);
