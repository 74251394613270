import React from 'react';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import {
  FormContainer,
  FormSelectInput,
  FormTextInput,
} from '@common/form-inputs';
import { requiredRule } from '@common/form-inputs/constants';
import { FormDatePicker } from '@common/form-inputs/form-date-picker';
import { MONTH_OPTIONS_WITH_BLANK } from '@core/constants';

export const ProgramAudit = ({ data, control }) => {
  const { rowStyles, colStyle } = useLayoutForm();

  const fields = {
    firm: (
      <FormTextInput
        control={control}
        name="auditFirm"
        label="Firm"
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    contact: (
      <FormTextInput control={control} name="auditContact" label="Contact" />
    ),
    phone: (
      <FormTextInput control={control} name="auditContactPhone" label="Phone" />
    ),
    email: (
      <FormTextInput control={control} name="auditContactEmail" label="Email" />
    ),
    lastAudit: (
      <FormDatePicker
        control={control}
        name="lastAudit"
        label="Last Audit Date"
        view="year"
        views={['year', 'decade']}
      />
    ),
    auditMonth: (
      <FormSelectInput
        control={control}
        name="auditMonth"
        label="Usual Audit Month"
        options={MONTH_OPTIONS_WITH_BLANK}
        defaultValue={MONTH_OPTIONS_WITH_BLANK.find(
          (x) => x.value === data.auditMonth
        )}
      />
    ),
  };

  return (
    <FormContainer title="Audit">
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.firm}
          {fields.lastAudit}
        </div>
        <div className={colStyle}>
          {fields.contact}
          {fields.auditMonth}
        </div>
        <div className={colStyle}>{fields.phone}</div>
        <div className={colStyle}>{fields.email}</div>
      </div>
    </FormContainer>
  );
};

ProgramAudit.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
