import { Button } from '@backstop/react-core';
import React from 'react';

export const FormSubmitCancelButton = ({
  cancelFunction,
  altButtonText,
  altButtonKind,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Button type="submit" kind={altButtonKind ?? 'primary'} version="v3">
        {altButtonText ?? 'Save'}
      </Button>
      <Button kind="secondary" version="v3" onClick={cancelFunction}>
        Cancel
      </Button>
    </div>
  );
};
