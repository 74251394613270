import React from 'react';
import { Modal } from '@backstop/react-core';
import { FundsUnderManagementForm } from './funds-under-management-form';

export const FundsUnderManagementFormModal = () => {
  return (
    <Modal version="v3" open={true} footerVisible={false}>
      <FundsUnderManagementForm />
    </Modal>
  );
};
