import { setInvestPlatDataState } from '@redux/actions/program-actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useBaseGet, useBasePatch, useBasePost } from '~/api/base';

export const useInvestPlatForm = (progId, platId) => {
  const { baseGet, data, ...rest } = useBaseGet();
  const { basePatch, data: patchData, ...patchRest } = useBasePatch();
  const { basePost, data: postData, ...postRest } = useBasePost();
  const dispatch = useDispatch();

  const getOneInvestPlat = () => {
    if (platId !== 'new') {
      baseGet(`/program/${progId}/investPlat/${platId}`);
    }
  };

  useEffect(() => {
    getOneInvestPlat();
  }, []);

  const updateOneInvestPlat = (data) => {
    basePatch(
      `/program/${progId}/investPlat/${data.id}`,
      { data: data },
      {},
      () => dispatch(setInvestPlatDataState('refresh'))
    );
  };

  const createOneInvestPlat = (data) => {
    basePost(`/program/${progId}/investPlat/`, { data: data }, {}, () =>
      dispatch(setInvestPlatDataState('refresh'))
    );
  };

  const saveInvestPlat = (data) => {
    if (data.id) {
      updateOneInvestPlat(data);
    } else {
      createOneInvestPlat(data);
    }
    dispatch(setInvestPlatDataState('refresh'));
  };

  return { data: data?.data, saveInvestPlat };
};
