import React from 'react';

export const FormContainerRequiredHeader = ({ title }) => {
  return (
    <div style={{ display: 'flex' }}>
      <h2 style={{ fontSize: '18px', fontWeight: '700', margin: '0' }}>
        {title}
      </h2>
      <span class="bsg-FormControl__required" style={{ marginTop: '0.3rem' }}>
        Required
      </span>
    </div>
  );
};
