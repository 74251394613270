import React from 'react';
import { CompanyDataCollectionContactForm } from './company-data-collection-contact-form';
import { CompanyContactsTable } from './table';
import { Outlet } from 'react-router-dom';

export const CompanyContacts = () => {
  return (
    <>
      <CompanyDataCollectionContactForm />
      <CompanyContactsTable />
      <Outlet />
    </>
  );
};
