import {
  FormContainer,
  FormContainerRequiredHeader,
} from '@common/form-inputs';
import { FormControlHybridInput } from '@common/form-inputs/form-control-hybrid-input';
import { betweenZeroAndHundred } from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import { requiredRule } from '@common/form-inputs/constants';
import React from 'react';
import PropTypes from 'prop-types';

export const ProgramGeographicAllocation = (props) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { data } = props;

  const fields = {
    amerPa: (
      <FormControlHybridInput
        name="amerPa"
        label="North America"
        value={data.amerPa}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    latamPa: (
      <FormControlHybridInput
        name="latamPa"
        label="Latin America"
        value={data.latamPa}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    eeurPa: (
      <FormControlHybridInput
        name="eeurPa"
        label="Eastern Europe"
        value={data.eeurPa}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    weurPa: (
      <FormControlHybridInput
        name="weurPa"
        label="Western Europe"
        value={data.weurPa}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pacifPa: (
      <FormControlHybridInput
        name="pacifPa"
        label="Pacific Rim"
        value={data.pacifPa}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    menaPa: (
      <FormControlHybridInput
        name="menaPa"
        label="Middle East / North Africa"
        value={data.menaPa}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    otherPa: (
      <FormControlHybridInput
        name="otherPa"
        label="Other"
        value={data.otherPa}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
  };

  return (
    <FormContainer>
      <FormContainerRequiredHeader title="Geographic Allocation" />
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.amerPa}
          {fields.latamPa}
          {fields.eeurPa}
        </div>
        <div className={colStyle}>
          {fields.weurPa}
          {fields.pacifPa}
        </div>
        <div className={colStyle}>
          {fields.menaPa}
          {fields.otherPa}
        </div>
      </div>
    </FormContainer>
  );
};

ProgramGeographicAllocation.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
