import PropTypes from 'prop-types';

export const ActiveStatusFormatter = ({ value, row }) => {
  const statusLookup = [
    { label: 'Archived : Unknown', value: 0 },
    { label: 'Archived : Liquidated', value: -1 },
    { label: 'Archived : Delisted', value: -2 },
    { label: 'Archived : Merged/Restructured', value: -3 },
    { label: 'Active', value: 1 },
    { label: 'Reviewed', value: 2 },
    { label: 'In Review', value: 3 },
    { label: 'MC Production', value: 4 },
    { label: 'In Progress', value: 5 },
  ];
  return statusLookup.find((x) => x.value === Number(value)).label;
};

ActiveStatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};
