import { useContext } from 'react';
import { SelectOptionsContext } from '~/api/selectContext';

export const useProgramCreateForm = () => {
  const openOptions = [
    { value: 'Open', label: 'Open' },
    { value: 'Closed', label: 'Closed' },
  ];

  const { dataUsage } = useContext(SelectOptionsContext);

  return {
    dataUsage,
    openOptions,
  };
};
