import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProgramData } from '../../hooks';
import { saveProgramData } from '~/api/program';
import { useForm } from 'react-hook-form';
import { Loader } from '@common/loader';
import { PROGRAM_TYPES } from '@core/constants';
import { ProgramGeographicAllocationContainer } from './program-geographic-allocation';
import { ProgramPortfolioCompositionContainer } from './program-portfolio-composition';
import { FormSaveButton } from '@common/form-inputs';
import {
  ErrorMessage,
  SuccessMessage,
  UnsavedChangesMessage,
} from '@common/generic-message';
import { Instruments } from './instruments/instruments';
import { Holdings } from './holdings/holdings';

export const ProgramComposition = () => {
  const programId = useParams().id;
  const { data } = useProgramData(programId);
  const { saveProgram, patchData, patchError } = saveProgramData();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
    setValue,
  } = useForm();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = (data) => {
    const tempProg = {
      ...data,
    };
    if (programId !== undefined) {
      tempProg.id = programId;
    }
    saveProgram(tempProg);
    reset(tempProg);
  };

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UnsavedChangesMessage trigger={isDirty} />
        <ErrorMessage trigger={patchError} message={patchError?.message} />
        <SuccessMessage trigger={patchData} />
        {data.type === PROGRAM_TYPES.HedgeFund ? (
          <ProgramGeographicAllocationContainer
            data={data}
            control={control}
            setValue={setValue}
            watch={watch}
          />
        ) : (
          <ProgramPortfolioCompositionContainer
            data={data}
            control={control}
            setValue={setValue}
            watch={watch}
          />
        )}
        <FormSaveButton />
      </form>
      <Instruments />
      <Holdings />
    </>
  );
};
