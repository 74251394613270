import axios from 'axios';
import { logout } from '@redux/actions/login-actions';

// In a real app, would likely call an error logging service.
export function handleError(error, dispatch, navigate) {
  if (axios.isCancel(error)) throw error;
  const response = error.response;

  if (!response) {
    handleNoResponse(error, dispatch, navigate);
  } else if (response.status >= 300 && response.status < 400) {
    return response;
  } else if (response.status >= 400 && response.status < 500) {
    handle4XX(error, dispatch, navigate);
  } else {
    navigate('/500');
  }
}

const handleNoResponse = (error, dispatch, navigate) => {
  if (error.status && error.status === 401) {
    dispatch(logout());
    throw error;
  } else navigate('/500');
};

const handle4XX = (error, dispatch, navigate) => {
  const response = error.response;
  if (response.status === 400) {
    throw { status: 400, message: response.data.errors };
  }
  if (response.status === 401) {
    dispatch(logout());
    throw { status: 401, message: 'Unauthorized' };
  }
  if (response.status === 404 || response.status === 403) {
    navigate('/404');
    return;
  }

  throw {
    status: action.status,
    message: action.message,
  };
};

export function getUrlWithPageParams(url, params) {
  let pageNum = params?.pageNumber;
  let pageSize = params?.pageSize;
  let searchQuery = params?.searchQuery;
  let searchType = params?.searchType;
  let filters = params?.filters;
  let sortDir = params?.sortDir;
  let sortCol = params?.sortCol;

  url = encodeURI(url);
  if (!pageNum) {
    pageNum = 1;
  }
  url += encodeURI(`?page[number]=${pageNum}`);

  if (!pageSize) {
    pageSize = 10;
  }
  url += encodeURI(`&page[size]=${pageSize}`);

  if (searchQuery) {
    url += encodeURI(`&query=${searchQuery}`);
  }
  if (searchType) {
    url += encodeURI(`&filter[type][eq]=${searchType}`);
  }
  if (filters) {
    url += encodeURI(`&${filters}`);
  }
  if (sortCol) {
    let sortString = sortDir === 'ASC' ? sortCol : '-' + sortCol;
    url += encodeURI(`&sort=${sortString}`);
  }
  return url;
}
