import React from 'react';
import { Button } from '@backstop/react-core';
import { useParams } from 'react-router-dom';

export const ExportDailyData = () => {
  const progId = useParams().id;
  const downloadFile = () => {
    window.open(
      process.env.REACT_APP_BARCLAYHEDGE_API_URL +
        `/program/${progId}/dailyData/download`,
      '_blank'
    );
  };
  return (
    <Button version="v3" onClick={downloadFile}>
      Export Daily Data
    </Button>
  );
};
