import React from 'react';
import { CompanyClientBaseTable } from './table';
import { Outlet } from 'react-router-dom';

export const CompanyClientBase = () => {
  return (
    <>
      <CompanyClientBaseTable />
      <Outlet />
    </>
  );
};
