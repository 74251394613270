import { useEffect } from 'react';
import api from '@api';
import { useDispatch, useSelector } from 'react-redux';
import { setProgramDataState } from '@redux/actions/program-actions';

export const useProgramData = (id) => {
  const { getOneProgram, data, loading, error } = api.program.useOneProgram();
  const dispatch = useDispatch();

  const programDataState = useSelector(
    (state) => state.program.programDataState
  );

  useEffect(() => {
    if (id) {
      getOneProgram(id);
    }
  }, [id]);

  useEffect(() => {
    if (programDataState === 'refresh') {
      getOneProgram(id);
    }
    dispatch(setProgramDataState('loaded'));
  }, [programDataState]);

  return { data, loading, error };
};
