import classnames from 'classnames';
import { useLayoutData } from '@core/layout';
import { WINDOW_SIZES } from '@core/constants';
import styles from './layout.module.scss';

export const useLayoutForm = () => {
  const { windowSize } = useLayoutData();

  const rowStyles = classnames(styles['row'], {
    [styles['row--collapsed']]: windowSize < WINDOW_SIZES.MD,
  });

  return { rowStyles, colStyle: styles['col'] };
};
