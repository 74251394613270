import PropTypes from 'prop-types';
import { dataUnavailable, toNumber } from '@core/formatters';

export const ThreeDigitDecimalFormatter = ({ value }) => {
  return dataUnavailable(toNumber(value, 3));
};

ThreeDigitDecimalFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
