import React from 'react';
import { CompanyFundsUnderManagementTable } from './table';
import { UploadManagedFunds } from './upload';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useFundsUnderManagementTable } from './table/hooks';
import { Button } from '@backstop/react-core';
import {
  getNextDate,
  jsDateToString,
  stringToJsDate,
} from '~/api/helperFunctions';
import { ExportFum } from './download';

export const CompanyFundsUnderManagement = () => {
  const compId = useParams().id;
  const navigate = useNavigate();
  const { data, currentPage } = useFundsUnderManagementTable(compId);

  let shouldShowCreate = () => {
    if (data !== undefined && data.length === 0) {
      return true;
    }
    if (data !== undefined && data?.[0]?.date) {
      let jsDate = stringToJsDate(data?.[0]?.date, 'YYYY-MM-DD', '-');
      let now = new Date();
      let attemptedDate = getNextDate(jsDate);

      let dateLimit = new Date(now.getFullYear(), now.getMonth(), 1);
      if (attemptedDate < dateLimit) {
        return true;
      }
    }
    return false;
  };

  let shouldShowDelete = () => {
    if (data !== undefined && currentPage === 1 && data?.length > 0) {
      let jsDate = stringToJsDate(data?.[0]?.date, 'YYYY-MM-DD', '-');
      let now = new Date();

      let dateLimit = new Date(now.getFullYear(), now.getMonth() - 2, 1);
      if (jsDate > dateLimit) {
        return true;
      }
      return false;
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <UploadManagedFunds />
        <ExportFum />
        {shouldShowCreate() ? (
          <Button
            version="v3"
            style={{ margin: '1rem' }}
            onClick={() =>
              navigate(`/company/${compId}/fum/new`, {
                state: {
                  data: {
                    date: data?.[0]?.date
                      ? jsDateToString(
                          getNextDate(
                            stringToJsDate(data?.[0]?.date, 'YYYY-MM-DD', '-')
                          ),
                          'YYYY-MM-DD'
                        )
                      : jsDateToString(
                          new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            1
                          ),
                          'YYYY-MM-DD'
                        ),
                  },
                },
              })
            }
          >
            Add New
          </Button>
        ) : null}
        {shouldShowDelete() ? (
          <Button
            version="v3"
            style={{ margin: '1rem' }}
            onClick={() => {
              navigate(
                `/company/${compId}/fum/${encodeURIComponent(
                  data?.[0]?.date
                )}/delete`
              );
            }}
          >
            Delete Last
          </Button>
        ) : null}
      </div>

      <Outlet />

      <CompanyFundsUnderManagementTable />
    </>
  );
};
