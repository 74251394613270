import React from 'react';
import {
  FormTextInput,
  FormSelectInput,
  FormContainer,
  FormCheckbox,
  FormTextInputDisabled,
} from '@common/form-inputs';
import {
  INVESTOR_OPTIONS,
  REGISTRATION_OPTIONS,
  RETURN_TYPE_OPTIONS,
  MONTH_OPTIONS_WITH_BLANK,
  PROGRAM_UCITS_OPTIONS,
  getSubTypes,
} from '@core/constants';
import { maxLengthRule } from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import { useProgramDetails } from './hooks';
import PropTypes from 'prop-types';
import { PROGRAM_SUB_TYPES } from '@core/constants';
import { PROGRAM_TYPES } from '@core/constants';
import { Loader } from '@common/loader';

export const ProgramDetailsGeneral = ({ control, data, watch }) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const {
    usOptions,
    localesOptions,
    subscriptionOptions,
    investorTypeOptions,
    exchangeListOptions,
    dayOptions,
    denominations,
    isLoadingSelect,
  } = useProgramDetails();
  const subTypes = getSubTypes(data);
  let hfOrMultiOrSingle =
    subTypes.includes(PROGRAM_SUB_TYPES.MULTI_ADVISOR) ||
    subTypes.includes(PROGRAM_SUB_TYPES.SINGLE_MANAGER) ||
    data.type === PROGRAM_TYPES.HedgeFund;
  const watchUs = watch('us');
  const watchK1 = watch('k1');

  if (isLoadingSelect()) {
    return <Loader />;
  }

  const fields = {
    returnsType: (
      <FormSelectInput
        name="retType"
        label="Returns Type"
        control={control}
        options={RETURN_TYPE_OPTIONS}
        defaultValue={RETURN_TYPE_OPTIONS.find((x) => x.value === data.retType)}
        fauxRequired={true}
      />
    ),
    targetedReturn: (
      <FormTextInput
        name="targetedReturn"
        label="Targeted Annual Return"
        control={control}
      />
    ),
    registration: (
      <FormSelectInput
        name="registration"
        label="Registration"
        control={control}
        options={REGISTRATION_OPTIONS}
        defaultValue={REGISTRATION_OPTIONS.find(
          (x) => x.value === data.registration
        )}
      />
    ),
    ucits: (
      <FormSelectInput
        name="ucitsLegalStructure"
        label="UCITS Legal Structure"
        control={control}
        options={PROGRAM_UCITS_OPTIONS}
        defaultValue={PROGRAM_UCITS_OPTIONS.find(
          (x) => x.value === data.ucitsLegalStructure
        )}
      />
    ),
    ulhf: (
      <FormTextInput
        name="numberUlhf"
        label="Number of Underlying Funds"
        control={control}
      />
    ),
    lockup: (
      <FormSelectInput
        name="lockup"
        control={control}
        label="Lockup Period"
        options={dayOptions}
        defaultValue={dayOptions.find(
          (x) => x.value.toString() === data.lockup?.toString()
        )}
        fauxRequired={true}
      />
    ),
    targetedVolatility: (
      <FormTextInput
        name="targetedVolatility"
        label="Targeted Annual Volatilty"
        control={control}
      />
    ),
    exchangeList: (
      <FormSelectInput
        name="exchangeListing"
        label="Exchange Listing"
        control={control}
        options={exchangeListOptions}
        defaultValue={exchangeListOptions.find(
          (x) => x.value === data.exchangeListing
        )}
      />
    ),
    investorsAccepted: (
      <FormSelectInput
        name="investorsAccepted"
        label="Eligible Investors"
        control={control}
        options={INVESTOR_OPTIONS}
        defaultValue={INVESTOR_OPTIONS.find(
          (x) => x.value === data.investorsAccepted
        )}
        fauxRequired={true}
      />
    ),
    capacity: (
      <FormTextInput
        name="capacity"
        label="Fund Capacity (K)"
        control={control}
      />
    ),
    denom: (
      <FormSelectInput
        name="denominat"
        label="Denomination"
        control={control}
        options={denominations}
        defaultValue={denominations.find((x) => x.value === data.denominat)}
      />
    ),
    us: (
      <FormSelectInput
        name="us"
        label="On Shore or Off Shore"
        control={control}
        options={usOptions}
        defaultValue={usOptions.find((x) => x.value === data.us)}
        fauxRequired={true}
      />
    ),
    k1: (
      <FormCheckbox
        name="k1"
        label="K1 Issued?"
        control={control}
        defaultValue={data.k1 == 1}
      />
    ),
    investorType: (
      <FormSelectInput
        name="investorType"
        label="US Investors Accepted"
        control={control}
        options={investorTypeOptions}
        defaultValue={investorTypeOptions.find(
          (x) => x.value === data.investorType
        )}
      />
    ),
    cik: (
      <FormTextInput
        name="cik"
        label="CIK"
        control={control}
        rules={{ ...maxLengthRule(32) }}
      />
    ),
    otherFees: (
      <FormTextInput
        name="otherFees"
        label="Other Fees"
        control={control}
        rules={{ ...maxLengthRule(64) }}
      />
    ),
    stateDom: (
      <FormSelectInput
        name="stateDom"
        label="State/Domicile"
        control={control}
        options={localesOptions}
        defaultValue={localesOptions.find((x) => x.value === data.stateDom)}
        fauxRequired={true}
      />
    ),
    k1Month: (
      <FormSelectInput
        name="k1Month"
        label="K1 Issue Month"
        options={MONTH_OPTIONS_WITH_BLANK}
        control={control}
        defaultValue={MONTH_OPTIONS_WITH_BLANK.find(
          (x) => x.value === data.k1Month
        )}
      />
    ),
    subscriptions: (
      <FormSelectInput
        name="subscriptions"
        control={control}
        label="Subscriptions"
        options={subscriptionOptions}
        defaultValue={subscriptionOptions.find(
          (x) => x.value === data.subscriptions
        )}
        fauxRequired={true}
      />
    ),
    isin: (
      <FormTextInput
        name="isin"
        label="ISIN/CUSIP"
        control={control}
        rules={{ ...maxLengthRule(12) }}
      />
    ),
    secNfa: (
      <FormCheckbox
        name="compRegisterFirm"
        control={control}
        label={data.type === 'CTA' ? 'NFA Registration' : 'SEC Registration'}
        defaultValue={data.compRegisterFirm === 1}
      />
    ),
  };

  return (
    <FormContainer title="Fund Details">
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.returnsType}

          {fields.targetedReturn}

          {fields.registration}

          {hfOrMultiOrSingle && watchUs?.value?.includes('UCIT')
            ? fields.ucits
            : null}

          {hfOrMultiOrSingle ? fields.ulhf : null}
        </div>
        <div className={colStyle}>
          {hfOrMultiOrSingle ? fields.lockup : null}

          {fields.targetedVolatility}

          {hfOrMultiOrSingle ? fields.exchangeList : null}

          {hfOrMultiOrSingle ? fields.investorsAccepted : null}

          {fields.capacity}
        </div>
        <div className={colStyle}>
          {fields.denom}

          {hfOrMultiOrSingle ? fields.us : null}

          {hfOrMultiOrSingle ? fields.k1 : null}

          {fields.investorType}

          {fields.cik}
        </div>
        <div className={colStyle}>
          {hfOrMultiOrSingle ? fields.otherFees : null}

          {fields.stateDom}

          {watchK1 === '1' || watchK1 === true ? fields.k1Month : null}

          {hfOrMultiOrSingle ? fields.subscriptions : null}

          {fields.isin}

          {fields.secNfa}
        </div>
      </div>
    </FormContainer>
  );
};

ProgramDetailsGeneral.propTypes = {
  data: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
};
