import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { DailyDataTable } from './table';
import { FormContainer, FormSelectInput } from '@common/form-inputs';
import { ExportDailyData } from './download';
import { Button } from '@backstop/react-core';
import { useForm } from 'react-hook-form';
import { useProgramData } from '../../hooks';
import { saveProgramData } from '~/api/program';
import { Loader } from '@common/loader';
import { RETURN_TYPE_OPTIONS } from '@core/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import { BasicTable } from '@common/basic-table';
import { requiredRule } from '@common/form-inputs/constants';
import {
  ErrorMessage,
  SuccessMessage,
  UnsavedChangesMessage,
} from '@common/generic-message';
import { UploadDailyData } from './upload/upload-daily-data';
import { useDailyDataTable } from './table/hooks';
import {
  getDayAfter,
  jsDateToString,
  stringToJsDate,
} from '~/api/helperFunctions';

export const ProgramDailyData = () => {
  const progId = useParams().id;
  const { data } = useProgramData(progId);
  const { rowStyles, colStyle } = useLayoutForm();
  const { saveProgram, patchData, patchError } = saveProgramData();
  const { data: tableData, currentPage } = useDailyDataTable(progId);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = (data) => {
    const tempProg = {
      ...data,
    };
    if (progId != undefined) {
      tempProg.id = progId;
    }
    saveProgram(tempProg);
    reset(tempProg);
  };

  const shouldShowCreate = () => {
    if (tableData !== undefined && currentPage === 1) {
      return true;
    }
    return false;
  };

  if (!data) {
    return <Loader />;
  }

  const fields = {
    retTypeDaily: (
      <FormSelectInput
        name="retTypeDaily"
        label="Returns Type"
        control={control}
        options={RETURN_TYPE_OPTIONS}
        rules={{ ...requiredRule }}
        required={true}
        defaultValue={RETURN_TYPE_OPTIONS.find(
          (x) => x.value === data.retTypeDaily
        )}
      />
    ),
  };

  const nextDateStr =
    tableData?.length > 0
      ? jsDateToString(
          getDayAfter(stringToJsDate(tableData?.[0]?.date, 'YYYY-MM-DD', '-')),
          'YYYY-MM-DD'
        )
      : undefined;

  return (
    <FormContainer>
      <UnsavedChangesMessage trigger={isDirty} />
      <ErrorMessage trigger={patchError} />
      <SuccessMessage trigger={patchData} />
      <div className={colStyle}>
        <BasicTable>
          <BasicTable.Row>
            <BasicTable.Header>Month To Date</BasicTable.Header>
            <BasicTable.Cell>{data?.dailyDataMtd || 'N/A'}</BasicTable.Cell>
          </BasicTable.Row>
        </BasicTable>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={rowStyles}>
            <div className={colStyle}>{fields.retTypeDaily}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <ExportDailyData />
            <UploadDailyData />
            {shouldShowCreate() ? (
              <Button
                version="v3"
                style={{ margin: '1rem' }}
                onClick={() =>
                  navigate(`/program/${progId}/dailyData/new`, {
                    state: {
                      data: {
                        date: nextDateStr,
                      },
                    },
                  })
                }
              >
                Add New
              </Button>
            ) : null}
            <Button type="submit" kind="primary" version="v3">
              Save
            </Button>
          </div>
        </form>
      </div>

      <DailyDataTable />
      <Outlet />
    </FormContainer>
  );
};
