import { combineReducers } from 'redux';
import userData from './user-data-reducer';
import layout from './layout-reducer';
import request from './request-reducer';
import cache from './cache-reducer';
import cacheLoading from './cache-loading-reducer';
import program from './program-reducer';
import company from './company-reducer';

const rootReducer = combineReducers({
  program,
  company,
  userData,
  layout,
  request,
  cache,
  cacheLoading,
});

export default rootReducer;
