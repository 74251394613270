import React from 'react';
import { useTrackRecordTable } from '../table/hooks';
import { useParams } from 'react-router-dom';
import { Loader } from '@common/loader';
import { BasicTable } from '@common/basic-table';
import { getDateObj } from '@core/dateHelper';
import { useProgramData } from '../../../hooks';

export const ProgramTrackRecordYtdChart = () => {
  const progId = useParams().id;

  const { data } = useTrackRecordTable(progId);
  const { data: progData } = useProgramData(progId);

  if (!data || !progData) {
    return <Loader />;
  }
  const lastDateYear = getDateObj(progData.dynamicEndDate).getFullYear();
  return (
    <BasicTable>
      <BasicTable.Row>
        <BasicTable.Header>YTD</BasicTable.Header>
        <BasicTable.Cell>{progData.dynamicYtdReturn}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>{lastDateYear - 1}</BasicTable.Header>
        <BasicTable.Cell>{progData.dynamic1YearReturn}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>{lastDateYear - 2}</BasicTable.Header>
        <BasicTable.Cell>{progData.dynamic2YearReturn}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>{lastDateYear - 3}</BasicTable.Header>
        <BasicTable.Cell>{progData.dynamic3YearReturn}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};
