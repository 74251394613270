import React, { useEffect, useState } from 'react';
import { Outlet, useParams, Link, useLocation } from 'react-router-dom';
import { useProgramData } from './hooks';
import styles from './program-tabs-container.module.scss';
import { Tabs, Tab, TabList, TabPanel } from '@backstop/react-core';
import { PROGRAM_SUB_TYPES_LABELS, getSubTypes } from '@core/constants';

export const ProgramTabsContainer = () => {
  const programId = useParams().id;
  const { pathname } = useLocation();

  const { data } = useProgramData(programId);
  const subTypes = getSubTypes(data);
  let labels = [];
  subTypes.forEach((sub) => {
    labels.push(
      PROGRAM_SUB_TYPES_LABELS.find((item) => item.value === sub).label
    );
  });
  const subTypeString = labels.join(', ');
  const showProgramName2 = data?.name2 && `(${data.name2})`;

  const calculateLocationIndex = () => {
    if (pathname.includes('progInfo')) {
      return 0;
    }
    if (pathname.includes('progDetails')) {
      return 1;
    }
    if (pathname.includes('admin')) {
      return 2;
    }
    if (pathname.includes('trackRecord')) {
      return 3;
    }
    if (pathname.includes('composition')) {
      return 4;
    }
    if (pathname.includes('notes')) {
      return 5;
    }
    if (pathname.includes('investPlat')) {
      return 6;
    }
    if (pathname.includes('dailyData')) {
      return 7;
    }
    return 0;
  };

  const [index, setIndex] = useState(calculateLocationIndex());

  useEffect(() => {
    setIndex(calculateLocationIndex());
  }, [pathname]);

  const tabsList = [
    { url: `/program/${programId}/progInfo`, text: 'Program Information' },
    { url: `/program/${programId}/progDetails`, text: 'Program Details' },
    { url: `/program/${programId}/admin`, text: 'Administrative' },
    { url: `/program/${programId}/trackRecord`, text: 'Track Record' },
    { url: `/program/${programId}/composition`, text: 'Trading' },
    { url: `/program/${programId}/notes`, text: 'Notes' },
    { url: `/program/${programId}/investPlat`, text: 'Investable Platforms' },
  ];

  if (Number(data?.dailyData) === 1) {
    tabsList.push({
      url: `/program/${programId}/dailyData`,
      text: 'Daily Data',
    });
  } else {
    //this is so there isn't a mismatch between <Tab> and <TabPanel> count. It doesn't render
    tabsList.push({ url: '', text: '' });
  }

  return (
    <div className={styles['body']}>
      <h1 className={styles['program-name']}>
        {data?.name} {showProgramName2}{' '}
        <span style={{ fontSize: '75%', filter: 'brightness(3)' }}>
          {subTypeString}
        </span>
      </h1>

      <Tabs
        className={`${styles[('tabs', 'scrollable-tab')]}`}
        version="v3"
        selectedIndex={index}
        onSelect={(index, last, event) => {
          setIndex(index);
        }}
      >
        <TabList>
          {tabsList.map((tab, index) => (
            <Link
              key={index}
              to={tab.url}
              onClick={() => {
                setIndex(index);
              }}
            >
              <Tab>{tab.text}</Tab>
            </Link>
          ))}
        </TabList>

        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
};
