import {
  FormContainer,
  FormContainerRequiredHeader,
  FormTextInput,
} from '@common/form-inputs';
import { FormControlHybridInput } from '@common/form-inputs/form-control-hybrid-input';
import { betweenZeroAndHundred } from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import React from 'react';
import PropTypes from 'prop-types';

export const ProgramPortfolioComposition = (props) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { data } = props;

  const fields = {
    pcCurrency: (
      <FormControlHybridInput
        name="pcCurrency"
        label="Currency"
        value={data.pcCurrency}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcEnergy: (
      <FormControlHybridInput
        name="pcEnergy"
        label="Energy"
        value={data.pcEnergy}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcGrains: (
      <FormControlHybridInput
        name="pcGrains"
        label="Grains"
        value={data.pcGrains}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcIntRate: (
      <FormControlHybridInput
        name="pcIntRate"
        label="Interest Rate"
        value={data.pcIntRate}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcMeats: (
      <FormControlHybridInput
        name="pcMeats"
        label="Meats"
        value={data.pcMeats}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcBasemet: (
      <FormControlHybridInput
        name="pcBasemet"
        label="Metals - Base"
        value={data.pcBasemet}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcPrecmet: (
      <FormControlHybridInput
        name="pcPrecmet"
        label="Metals - Precious"
        value={data.pcPrecmet}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcSofts: (
      <FormControlHybridInput
        name="pcSofts"
        label="Softs"
        value={data.pcSofts}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcStock: (
      <FormControlHybridInput
        name="pcStock"
        label="Stock Index Futures"
        value={data.pcStock}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcEquity: (
      <FormControlHybridInput
        name="pcEquity"
        label="Equities"
        value={data.pcEquity}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcSsf: (
      <FormControlHybridInput
        name="pcSsf"
        label="Single Stock Futures"
        value={data.pcSsf}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcVix: (
      <FormControlHybridInput
        name="pcVix"
        label="VIX"
        value={data.pcVix}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcOther: (
      <FormControlHybridInput
        name="pcOther"
        label="Others"
        value={data.pcOther}
        rules={{ ...betweenZeroAndHundred }}
        {...props}
      />
    ),
    pcOtherDesc: (
      <FormTextInput name="pcOtherSt" label="Other Description" {...props} />
    ),
  };

  return (
    <FormContainer>
      <FormContainerRequiredHeader title="CTA Portfolio Composition" />
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.pcCurrency}
          {fields.pcEnergy}
          {fields.pcGrains}
          {fields.pcIntRate}
          {fields.pcMeats}
          {fields.pcBasemet}
          {fields.pcPrecmet}
        </div>
        <div className={colStyle}>
          {fields.pcSofts}
          {fields.pcStock}
          {fields.pcEquity}
          {fields.pcSsf}
          {fields.pcVix}
          {fields.pcOther}
          {fields.pcOtherDesc}
        </div>
      </div>
    </FormContainer>
  );
};

ProgramPortfolioComposition.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
