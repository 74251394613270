import * as types from '../actions/action-types';
import initialState from './initial-state';

export default function programReducer(state = initialState.program, action) {
  switch (action.type) {
    case types.SET_TRACK_RECORD_DATA_REFRESH_STATE:
      return {
        ...state,
        trackRecordDataState: action.state,
      };
    case types.SET_INVEST_PLAT_DATA_REFRESH_STATE:
      return {
        ...state,
        investPlatDataState: action.state,
      };
    case types.SET_PROGRAM_DATA_REFRESH_STATE:
      return {
        ...state,
        programDataState: action.state,
      };
    case types.SET_DAILY_DATA_REFRESH_STATE:
      return {
        ...state,
        dailyDataState: action.state,
      };
    case types.SET_HOLDING_DATA_REFRESH_STATE:
      return {
        ...state,
        holdingDataState: action.state,
      };
    case types.SET_INSTRUMENT_DATA_REFRESH_STATE:
      return {
        ...state,
        instrumentDataState: action.state,
      };
    default:
      return state;
  }
}
