import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUploadTrackData } from './hooks';
import {
  Button,
  DragDropFileUpload,
  InlineMessageBlock,
  Modal,
} from '@backstop/react-core';
import '@common/style/hide-post-error.css';
import { useDispatch } from 'react-redux';
import { setTrackRecordDataState } from '@redux/actions/program-actions';

export const UploadTrackData = () => {
  const programId = useParams().id;
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [superModalOpen, setSuperModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(<></>);
  const [fatalErr, setFatalErr] = useState(false);

  const {
    uploadTrackRecordPatch,
    uploadTrackRecordPost,
    validateUploadFile,
    postData,
    postStatus,
  } = useUploadTrackData(programId);

  const commonMessage = (
    <>
      If replacing all track record information, click <strong>Replace</strong>.
      <br />
      If adding to track record historical information, click
      <strong> Update</strong>. <br />
      If you would like to modify this submission, click <strong>Cancel</strong>
      .
    </>
  );
  const handleWarnings = (warningData) => {
    let count = 0;
    let jsx = warningData.map((element, index) => {
      //if there ends up being a lot of errors, only show the first 5
      if (count > 4) {
        if (count === 100) {
          return;
        }
        count = 100;
        return (
          <>
            ....
            <br />
          </>
        );
      }
      let rawData = element[Object.keys(element)[0]];
      if (rawData.length > 0) {
        count++;
        return (
          <InlineMessageBlock key={index} kind="caution">
            {element[Object.keys(element)[0]]}
          </InlineMessageBlock>
        );
      }
    });
    return jsx.concat(commonMessage);
  };

  const handleOkay = () => {
    return Array.of(
      <InlineMessageBlock kind="success">Upload Complete!</InlineMessageBlock>
    ).concat(commonMessage);
  };

  useEffect(() => {
    if (postStatus === 400) {
      setFatalErr(true);
      setModalMessage(
        <>
          <InlineMessageBlock kind="error">{postData}</InlineMessageBlock>
          <>Please close this window and modify your submission</>
        </>
      );
    } else if (postStatus !== 202 && postData?.data) {
      setModalMessage(handleWarnings(postData.data));
    } else if (postStatus === 202) {
      setModalMessage(handleOkay());
    }
  }, [postStatus, postData]);

  const handleFileAdd = (file) => {
    setFile(file);
    validateUploadFile(programId, file);
    setModalOpen(true);
  };
  const submitFile = (shouldReplace) => {
    //don't bother submitting if there is a fatal error
    if (fatalErr) {
      return;
    }
    if (shouldReplace) {
      uploadTrackRecordPost(programId, file);
    } else {
      uploadTrackRecordPatch(programId, file);
    }
    dispatch(setTrackRecordDataState('upload'));
    setModalOpen(false);
  };

  const clickReplace = () => {
    setModalOpen(false);
    setSuperModalOpen(true);
  };

  const clickUpdate = () => {
    submitFile(false);
  };

  const clickCancel = () => {
    setFatalErr(false);
    setFile(null);
    setModalMessage(null);
    setModalOpen(false);
  };

  const clickCancelSuperModal = () => {
    setSuperModalOpen(false);
  };

  const clickSuperReplace = () => {
    setSuperModalOpen(false);
    submitFile(true);
  };

  return (
    <>
      <div style={{ marginTop: '1.2rem', marginRight: '1rem' }}>
        <DragDropFileUpload
          version="v3"
          postUrl={`#`}
          acceptedFiles=".csv"
          onComplete={handleFileAdd}
        />
      </div>
      <Modal
        version="v3"
        closeOnEscape={false}
        closeOnOverlayClick={false}
        open={superModalOpen}
        footer={[
          <Button
            version="v3"
            onClick={clickSuperReplace}
            kind="secondary"
            className="bsg-mr3"
          >
            Yes, Replace All.
          </Button>,
          <Button
            version="v3"
            onClick={clickCancelSuperModal}
            kind="cancel"
            className="bsg-mr3"
          >
            Cancel
          </Button>,
        ]}
      >
        Are you sure you want to overwrite <strong>ALL</strong> historical track
        record information?
      </Modal>
      <Modal
        version="v3"
        closeOnEscape={false}
        closeOnOverlayClick={false}
        open={modalOpen}
        footer={[
          !fatalErr && (
            <Button version="v3" onClick={clickReplace} className="bsg-mr3">
              Replace
            </Button>
          ),
          !fatalErr && (
            <Button
              version="v3"
              onClick={clickUpdate}
              kind="secondary"
              className="bsg-mr3"
            >
              Update
            </Button>
          ),
          <Button
            version="v3"
            onClick={clickCancel}
            kind="cancel"
            className="bsg-mr3"
          >
            Cancel
          </Button>,
        ]}
      >
        {modalMessage}
      </Modal>
    </>
  );
};
