import { setCompManagedFundsDataState } from '@redux/actions/company-actions';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useBaseDelete,
  useBaseGet,
  useBaseGetSecondary,
  useBasePatch,
  useBasePost,
} from '~/api/base';

export const useFundsUnderManagementForm = (dstamp, compId, qcDstamp) => {
  const { baseGet, data, ...rest } = useBaseGet();
  const { baseGetSecondary, data: data2, ...rest2 } = useBaseGetSecondary();
  const dispatch = useDispatch();

  const getOneManagedFund = () => {
    baseGet(`/company/${compId}/managedFund/${dstamp}`);
  };

  const getManagedFundQcData = useCallback(() => {
    baseGetSecondary(`/company/${compId}/managedFund/${qcDstamp}/QC`);
  }, []);

  useEffect(() => {
    if (dstamp !== 'new') {
      getOneManagedFund();
    }
    getManagedFundQcData();
  }, []);

  const { basePatch, data: patchData } = useBasePatch();

  const updateOneManagedFund = (data) => {
    basePatch(
      `/company/${compId}/managedFund/${encodeURIComponent(dstamp)}`,
      { data: data },
      undefined,
      () => dispatch(setCompManagedFundsDataState('refresh'))
    );
  };

  const { basePost, data: postData } = useBasePost();

  const createOneManagedFund = (data) => {
    data.compId = compId;
    basePost(`/company/${compId}/managedFund`, { data: data }, undefined, () =>
      dispatch(setCompManagedFundsDataState('refresh'))
    );
  };

  const { baseDelete } = useBaseDelete();

  const deleteOneManagedFund = () => {
    baseDelete(
      `/company/${compId}/managedFund/${encodeURIComponent(dstamp)}`,
      () => dispatch(setCompManagedFundsDataState('refresh'))
    );
  };

  return {
    data: data?.data,
    qcData: data2?.data,
    patchData: patchData?.data,
    postData: postData?.data,
    rest,
    updateOneManagedFund,
    createOneManagedFund,
    deleteOneManagedFund,
  };
};
