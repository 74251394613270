import { setDailyDataState } from '@redux/actions/program-actions';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useBaseGet,
  useBaseGetSecondary,
  useBasePatch,
  useBasePost,
} from '~/api/base';

export const useDailyDataForm = (dstamp, progId, qcDstamp) => {
  const { baseGet, data, ...rest } = useBaseGet();
  const { baseGetSecondary, data: data2, ...rest2 } = useBaseGetSecondary();
  const dispatch = useDispatch();

  const getOneDailyData = () => {
    baseGet(`/program/${progId}/dailyData/${dstamp}`);
  };

  const getDailyDataQcData = useCallback(() => {
    baseGetSecondary(`/program/${progId}/dailyData/${qcDstamp}/QC`);
  });

  useEffect(() => {
    if (dstamp !== 'new') {
      getOneDailyData();
    }
    getDailyDataQcData();
  }, []);

  const { basePatch, data: patchData } = useBasePatch();

  const updateOneDailyData = (data) => {
    basePatch(
      `/program/${progId}/dailyData/${encodeURIComponent(dstamp)}`,
      {
        data: data,
      },
      undefined,
      () => dispatch(setDailyDataState('refresh'))
    );
  };

  const { basePost, data: postData } = useBasePost();

  const createOneDailyData = (data) => {
    data.progId = progId;
    basePost(`/program/${progId}/dailyData`, { data: data }, undefined, () =>
      dispatch(setDailyDataState('refresh'))
    );
  };

  return {
    data: data?.data,
    qcData: data2?.data,
    patchData: patchData?.data,
    postData: postData?.data,
    rest,
    updateOneDailyData,
    createOneDailyData,
  };
};
