import { setTrackRecordDataState } from '@redux/actions/program-actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProgramTrackRecord } from '~/api/program';

export const useTrackRecordTable = (progId) => {
  const { getTrackRecords, data, meta, loading } =
    useProgramTrackRecord(progId);

  const dispatch = useDispatch();
  const trackRecordDataState = useSelector(
    (state) => state.program.trackRecordDataState
  );

  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);

  let requestConfig = {
    pageNumber: currentPage,
    pageSize: pageSize.value,
    sortCol: 'date',
    sortDir: 'DESC',
  };

  useEffect(() => {
    getTrackRecords(requestConfig);
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (trackRecordDataState === 'refresh') {
      getTrackRecords(requestConfig);
    }
    dispatch(setTrackRecordDataState('loaded'));
  }, [trackRecordDataState]);

  return {
    data,
    meta,
    loading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  };
};
