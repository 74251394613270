import { setCompContactDataState } from '@redux/actions/company-actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCompanyContacts } from '~/api/company';

export const useCompanyContactsTable = (compId) => {
  const { getCompanyContacts, getData, getMeta, getLoading } =
    useCompanyContacts(compId);
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);
  const compContactState = useSelector(
    (state) => state.company.compContactDataState
  );
  const dispatch = useDispatch();

  let searchParams = {
    pageNumber: currentPage,
    pageSize: pageSize.value,
    sortCol: 'sequence',
    sortDir: 'ASC',
  };

  useEffect(() => {
    getCompanyContacts(searchParams);
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (compContactState === 'refresh') {
      getCompanyContacts(searchParams);
    }
    dispatch(setCompContactDataState('loaded'));
  }, [compContactState]);

  return {
    getData,
    getMeta,
    getLoading,
    setCurrentPage,
    currentPage,
    pageSize,
    setPageSize,
  };
};
