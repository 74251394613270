// login
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_PASS = 'LOGIN_PASS';

// Cache
export const CACHE_SET = 'CACHE_SET';
export const CACHE_CLEAN = 'CACHE_CLEAN';

// Cache loading
export const CACHE_LOADING_SET = 'CACHE_LOADING_SET';
export const CACHE_LOADING_CLEAN = 'CACHE_LOADING_CLEAN';

// User data
export const USER_DATA_SET = 'USER_DATA_SET';
export const USER_DATA_LOADING_SET = 'USER_DATA_LOADING_SET';
export const USER_ROLES_SET = 'USER_ROLES_SET';
export const USER_DATA_CLEAN = 'USER_DATA_CLEAN';

// temp access table
export const TEMP_ACCESS_TABLE_REFRESH_STATUS =
  'TEMP_ACCESS_TABLE_REFRESH_STATUS';

// Layout
export const WINDOW_SIZE_SET = 'WINDOW_SIZE_SET';
export const SIDEBAR_COLLAPSED_SET = 'SIDEBAR_COLLAPSED_SET';
export const SIDEBAR_COLLAPSED_TOGGLE = 'SIDEBAR_COLLAPSED_TOGGLE';

//PROGRAM
export const SET_PROGRAM_DATA_REFRESH_STATE = 'SET_PROGRAM_DATA_REFRESH_STATE';
//track record
export const SET_TRACK_RECORD_DATA_REFRESH_STATE =
  'SET_TRACK_RECORD_DATA_REFRESH_STATE';
//invest platform
export const SET_INVEST_PLAT_DATA_REFRESH_STATE =
  'SET_INVEST_PLAT_DATA_REFRESH_STATE';
//daily data
export const SET_DAILY_DATA_REFRESH_STATE = 'SET_DAILY_DATA_REFRESH_STATE';
//instrument
export const SET_INSTRUMENT_DATA_REFRESH_STATE =
  'SET_INSTRUMENT_DATA_REFRESH_STATE';
//holding
export const SET_HOLDING_DATA_REFRESH_STATE = 'SET_HOLDING_DATA_REFRESH_STATE';

//COMPANY
//contacts
export const SET_COMP_CONTACT_DATA_REFRESH_STATE =
  'SET_COMP_CONTACT_DATA_REFRESH_STATE';

//client base
export const SET_COMP_CLIENT_BASE_REFRESH_STATE =
  'SET_COMP_CLIENT_BASE_REFRESH_STATE';

//notes
export const SET_COMP_NOTES_REFRESH_STATE = 'SET_COMP_NOTES_REFRESH_STATE';

//managed funds
export const SET_MANAGED_FUNDS_DATA_REFRESH_STATE =
  'SET_MANAGED_FUNDS_DATA_REFRESH_STATE';
