import { FormContainer, FormSelectInput } from '@common/form-inputs';
import { useLayoutForm } from '@core/layout/layout-form';
import { PUBLIC_PRIVATE_OPTIONS } from '@core/select-options';
import React from 'react';
import PropTypes from 'prop-types';
import { YES_NO_FLAG_OPTIONS } from '@common/form-inputs/constants';

export const ProgramMultipleAdvisor = ({ control, data }) => {
  const { rowStyles, colStyle } = useLayoutForm();

  const fields = {
    ctaFund: (
      <FormSelectInput
        name="ctaFund"
        label="Single-Advisor Fund"
        control={control}
        options={YES_NO_FLAG_OPTIONS}
        defaultValue={YES_NO_FLAG_OPTIONS.find(
          (x) => x.value === parseInt(data?.ctaFund)
        )}
      />
    ),
    multiadvisor: (
      <FormSelectInput
        name="multiadvisor"
        label="Multi-Advisor Fund"
        control={control}
        required={true}
        options={YES_NO_FLAG_OPTIONS}
        defaultValue={YES_NO_FLAG_OPTIONS.find(
          (x) => x.value === parseInt(data?.multiadvisor)
        )}
      />
    ),
    publicPrivate: (
      <FormSelectInput
        name="publicPrivate"
        label="Public/Private"
        control={control}
        options={PUBLIC_PRIVATE_OPTIONS}
        defaultValue={PUBLIC_PRIVATE_OPTIONS.find(
          (x) => x.value === parseInt(data?.publicPrivate)
        )}
      />
    ),
  };

  return (
    <FormContainer title="Multiple Advisor">
      <div className={rowStyles}>
        <div className={colStyle}>{fields.ctaFund}</div>
        <div className={colStyle}>{fields.multiadvisor}</div>
        <div className={colStyle}>{fields.publicPrivate}</div>
      </div>
    </FormContainer>
  );
};

ProgramMultipleAdvisor.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
