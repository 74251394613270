import { setDailyDataState } from '@redux/actions/program-actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProgramDailyData } from '~/api/program';

export const useDailyDataTable = (progId) => {
  const dispatch = useDispatch()
  const { getDailyData, data, meta, loading } = useProgramDailyData(progId);
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);

  const dailyDataState = useSelector((state) => state.program.dailyDataState);

  let requestConfig = {
    pageNumber: currentPage,
    pageSize: pageSize.value,
    sortCol: 'date',
    sortDir: 'DESC',
  };

  useEffect(() => {
    getDailyData(requestConfig);
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (dailyDataState === 'refresh') {
      getDailyData(requestConfig);
    }
    dispatch(setDailyDataState('loaded'));
  }, [dailyDataState]);

  return {
    data,
    meta,
    loading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  };
};
