import React from 'react';
import {
  FormTextInput,
  FormSelectInput,
  FormContainer,
} from '@common/form-inputs';
import { maxLengthRule, requiredRule } from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import { useProgramDetails } from './hooks';
import { Loader } from '@common/loader';

export const ProgramDetailsRedemptions = ({ control, data }) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { dayOptions, redemptionOptions, isLoadingSelect } =
    useProgramDetails();

  if (isLoadingSelect()) {
    return <Loader />;
  }
  return (
    <FormContainer title="Redemptions">
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormSelectInput
            name="advance"
            control={control}
            label="Advanced Notice For Redemptions"
            options={dayOptions}
            defaultValue={dayOptions.find((x) => x.value === data.advance)}
          />
        </div>
        <div className={colStyle}>
          <FormSelectInput
            name="redemptions"
            control={control}
            label="Redemptions"
            options={redemptionOptions}
            defaultValue={redemptionOptions.find(
              (x) => x.value === data.redemptions
            )}
            fauxRequired={true}
          />
        </div>
        <div className={colStyle}>
          <FormTextInput
            name="redemptionFees"
            label="Redemption Fees"
            rules={{ ...maxLengthRule(64) }}
            fauxRequired={true}
            control={control}
          />
        </div>
        <div className={colStyle}>
          <FormTextInput
            name="payoutPct"
            label="Redemption Proceeds %"
            rules={{ ...maxLengthRule(4) }}
            fauxRequired={true}
            control={control}
          />
        </div>
        <div className={colStyle}>
          <FormSelectInput
            name="payoutDays"
            control={control}
            label="Redemption Proceeds Days"
            options={dayOptions}
            defaultValue={dayOptions.find((x) => x.value === data.payoutDays)}
          />
        </div>
      </div>
    </FormContainer>
  );
};

ProgramDetailsRedemptions.propTypes = {
  data: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
};
