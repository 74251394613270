import React from 'react';
import { Message } from './message';

export const ErrorMessage = ({ trigger, message }) => {
  return (
    <Message
      kind="error"
      message={message ?? 'There was an error'}
      timerAmount={3000}
      trigger={trigger}
    />
  );
};
