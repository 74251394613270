import { FormContainer, FormTextInputDisabled } from '@common/form-inputs';
import { useLayoutForm } from '@core/layout/layout-form';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { hfStratOptions, useProgramComposition } from './hooks';
import { Loader } from '@common/loader';
import { Checkbox, Select } from '@backstop/react-core';
import { FormControl } from '@common/form-control';
import { YES_NO_FLAG_OPTIONS } from '@common/form-inputs/constants';
import { requiredRule } from '@common/form-inputs/constants';

export const ProgramHedgeFundStrategies = ({ data, setValue }) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { getStylesForCategory, categoryOptions, isLoading } =
    useProgramComposition();

  const [catStyleOptions, setCatStyleOptions] = useState();
  const [catStyleValue, setCatStyleValue] = useState({});
  const [primaryCatVal, setPrimaryCatVal] = useState({});
  const [secondaryCatVal, setSecondaryCatVal] = useState({});
  const [noCatCheckVal, setNoCatCheckVal] = useState(Number(data?.noCat) === 1);
  const [systemVal, setSystemVal] = useState(
    YES_NO_FLAG_OPTIONS.find((x) => x.value === Number(data?.systematic))
  );

  const updateApiKeys = () => {
    if (noCatCheckVal) {
      if (primaryCatVal?.value !== '') {
        setPrimaryCatVal({ label: '', value: '' });
      }
      if (secondaryCatVal?.value !== '') {
        setSecondaryCatVal({ label: '', value: '' });
      }
      setValue('noCat', 1);
      setValue('primaryCategory', 'fnocat');
    }
    //if we are noCat, we already set the vals to not match the options so this will just set all other fields to 0
    hfStratOptions.forEach((option) => {
      if (
        primaryCatVal?.value !== '' &&
        option.match.toLowerCase() === primaryCatVal?.value?.toLowerCase()
      ) {
        setValue(option.value, 1);
        setValue('primaryCat', option.match);
      } else if (
        secondaryCatVal?.value !== '' &&
        option.match.toLowerCase() === secondaryCatVal?.value?.toLowerCase()
      ) {
        setValue(option.value, 1);
        setValue('secondaryCat', option.match);
      } else {
        setValue(option.value, 0);
      }
    });
  };

  useEffect(() => {
    //when the options load, fill the select inputs
    setPrimaryCatVal(
      categoryOptions.find(
        (item) => item.value.toLowerCase() === data.primaryCat?.toLowerCase()
      )
    );
    setSecondaryCatVal(
      categoryOptions.find(
        (item) => item.value.toLowerCase() === data.secondaryCat?.toLowerCase()
      )
    );
  }, [categoryOptions]);

  useEffect(() => {
    //when the options load, fill the select input
    setCatStyleValue(
      catStyleOptions?.find((x) => x.value === data.categoryStyle)
    );
  }, [catStyleOptions]);

  useEffect(() => {
    //when primary cat changes, update style options
    setCatStyleOptions(
      getStylesForCategory(
        categoryOptions.find(
          (item) =>
            item?.value?.toLowerCase() === primaryCatVal?.value?.toLowerCase()
        )?.styleCode
      )
    );
    updateApiKeys();
  }, [primaryCatVal]);

  useEffect(() => {
    updateApiKeys();
  }, [secondaryCatVal]);

  useEffect(() => {
    //when cat style changes, update the form
    setValue('categoryStyle', catStyleValue?.value);
  }, [catStyleValue]);

  useEffect(() => {
    setValue('noCat', noCatCheckVal ? '1' : '0');
  }, [noCatCheckVal]);

  useEffect(() => {
    setValue('systematic', systemVal?.value);
  }, [systemVal]);

  if (isLoading()) {
    return <Loader />;
  }

  const fields = {
    systematic: (
      <FormControl label="Systematic">
        <Select
          options={YES_NO_FLAG_OPTIONS}
          version="v3"
          value={systemVal}
          onChange={setSystemVal}
          rules={{ ...requiredRule }}
          required={true}
        />
      </FormControl>
    ),
    noCat: (
      <FormControl label="No Category">
        <Checkbox
          version="v3"
          name="noCat"
          checked={noCatCheckVal}
          onChange={() => setNoCatCheckVal(!noCatCheckVal)}
          required={true}
        />
      </FormControl>
    ),
    primaryCat: (
      <FormControl label="Primary Category" required={true}>
        <Select
          options={categoryOptions}
          version="v3"
          value={primaryCatVal}
          onChange={setPrimaryCatVal}
          rules={{ ...requiredRule }}
          required={true}
        />
      </FormControl>
    ),
    disabledPrimaryCat: (
      <FormTextInputDisabled label="Primary Category" value="No Category" />
    ),
    secondaryCat: (
      <FormControl label="Secondary Category">
        <Select
          options={categoryOptions}
          version="v3"
          value={secondaryCatVal}
          onChange={setSecondaryCatVal}
          rules={{ ...requiredRule }}
          required={true}
        />
      </FormControl>
    ),
    disabledSecondaryCat: (
      <FormTextInputDisabled label="Secondary Category" value="No Category" />
    ),
    categoryStyle: (
      <FormControl label="Category Style">
        <Select
          options={catStyleOptions}
          version="v3"
          value={catStyleValue}
          onChange={setCatStyleValue}
          rules={{ ...requiredRule }}
          required={true}
        />
      </FormControl>
    ),
  };

  return (
    <FormContainer title="Hedge Fund Strategies">
      <div className={rowStyles}>
        <div className={colStyle}>{fields.systematic}</div>
        <div className={colStyle}>{fields.noCat}</div>
      </div>
      <div className={rowStyles}>
        <div className={colStyle}>
          {noCatCheckVal === true
            ? fields.disabledPrimaryCat
            : fields.primaryCat}
        </div>
        <div className={colStyle}>
          {noCatCheckVal === true
            ? fields.disabledSecondaryCat
            : fields.secondaryCat}
        </div>
        {catStyleOptions?.length > 1 && noCatCheckVal === false ? (
          <div className={colStyle}>{fields.categoryStyle}</div>
        ) : null}
      </div>
    </FormContainer>
  );
};

ProgramHedgeFundStrategies.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
