import React, { useState } from 'react';
import { InvestablePlatformTable } from './table';
import { Button } from '@backstop/react-core';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

export const InvestablePlatform = () => {
  const progId = useParams().id;
  const navigate = useNavigate();
  return (
    <>
      <Outlet />
      <InvestablePlatformTable />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          version="v3"
          onClick={() => navigate(`/program/${progId}/investPlat/new`)}
        >
          Create
        </Button>
      </div>
    </>
  );
};
