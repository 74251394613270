import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFundsUnderManagementForm } from './hooks';
import { useForm } from 'react-hook-form';
import {
  FormCheckbox,
  FormContainer,
  FormTextInput,
  FormTextInputDisabled,
} from '@common/form-inputs';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import { Loader } from '@common/loader';
import { maxLengthRule, requiredRule } from '@common/form-inputs/constants';
import { InlineMessageBlock } from '@backstop/react-core';

export const FundsUnderManagementForm = () => {
  const { dstamp, id } = useParams();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const {
    data,
    qcData,
    updateOneManagedFund,
    createOneManagedFund,
    deleteOneManagedFund,
  } = useFundsUnderManagementForm(dstamp, id, state?.data?.date || dstamp);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm();

  const watchFum = watch('fum');
  const [warnFum, setWarnFum] = useState(false);
  const [warnFumMessage, setWarnFumMessage] = useState('');

  useEffect(() => {
    const fumUpperLimit = qcData?.fumUpperWarning;
    const fumLowerLimit = qcData?.fumLowerWarning;

    if (watchFum > fumUpperLimit || watchFum < fumLowerLimit) {
      setWarnFum(true);
      setWarnFumMessage(
        `Large data discrepancy detected: Prior AUM ${qcData.fum.toLocaleString()}`
      );
    } else {
      setWarnFum(false);
      setWarnFumMessage('');
    }
  }, [watchFum, qcData]);

  const isDelete = pathname.includes('delete');

  const onSubmit = (data) => {
    if (dstamp === 'new') {
      createOneManagedFund(data);
    } else {
      if (isDelete) {
        deleteOneManagedFund();
      } else {
        updateOneManagedFund(data);
      }
    }
    navigate(`/company/${id}/fum`);
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  useEffect(() => {
    //if this is a new entry and we were provided last years data, populate the form with it
    if (state && dstamp === 'new') {
      reset(state.data);
    }
  }, []);

  if (!data && dstamp !== 'new') {
    return <Loader />;
  }

  const fields = {
    date: (
      <FormTextInputDisabled
        name="date"
        label="Date"
        required={true}
        value={data?.date || state.data.date}
      />
    ),
    confirmed: (
      <FormCheckbox
        name="confirmed"
        label="Confirmed?"
        required={true}
        defaultValue={data?.confirmed === '1'}
        control={control}
      />
    ),
    fum: (
      <FormTextInput
        name="fum"
        label="Funds Managed ($K)"
        control={control}
        required={true}
        rules={{ ...maxLengthRule(18), ...requiredRule }}
      />
    ),
  };

  let title = 'Edit Record';
  if (dstamp === 'new') {
    title = 'Create Record';
  }
  if (isDelete) {
    title = 'Delete Record';
  }

  return (
    <FormContainer title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.date}
        {fields.fum}
        {warnFum && (
          <InlineMessageBlock kind="caution" version="v3">
            {warnFumMessage}
          </InlineMessageBlock>
        )}
        {fields.confirmed}
        <FormSubmitCancelButton
          altButtonText={isDelete ? 'Delete' : undefined}
          altButtonKind={isDelete ? 'delete' : undefined}
          cancelFunction={() => navigate(`/company/${id}/fum`)}
        />
      </form>
    </FormContainer>
  );
};
