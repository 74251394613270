import {
  setProgramDataState,
  setTrackRecordDataState,
} from '@redux/actions/program-actions';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useBaseDelete,
  useBaseGet,
  useBaseGetSecondary,
  useBasePatch,
  useBasePost,
} from '~/api/base';

export const useTrackRecordForm = (dstamp, progId, qcDstamp) => {
  const { baseGet, data, ...rest } = useBaseGet();
  const { baseGetSecondary, data: data2, ...rest2 } = useBaseGetSecondary();
  const dispatch = useDispatch();

  const getOneTrackRecord = useCallback(() => {
    baseGet(`/program/${progId}/trackRecord/${dstamp}`);
  }, []);

  const getTrackRecordQcData = useCallback(() => {
    baseGetSecondary(`/program/${progId}/trackRecord/${qcDstamp}/QC`);
  }, []);

  useEffect(() => {
    if (dstamp !== 'new') {
      getOneTrackRecord();
    }
    getTrackRecordQcData();
  }, []);

  const { basePatch, data: patchData } = useBasePatch();

  const updateOneTrackRecord = (data) => {
    basePatch(
      `/program/${progId}/trackRecord/${encodeURIComponent(dstamp)}`,
      {
        data: data,
      },
      undefined,
      () => {
        dispatch(setTrackRecordDataState('refresh'));
        //refresh prog data to refresh YTD calc
        dispatch(setProgramDataState('refresh'));
      }
    );
  };

  const { basePost, data: postData } = useBasePost();

  const createOneTrackRecord = (data) => {
    data.progId = progId;
    basePost(
      `/program/${progId}/trackRecord`,
      { data: data },
      undefined,
      () => {
        dispatch(setTrackRecordDataState('refresh'));
        //refresh prog data to refresh YTD calc
        dispatch(setProgramDataState('refresh'));
      }
    );
  };

  const { baseDelete } = useBaseDelete();

  const deleteOneTrackRecord = () => {
    baseDelete(
      `/program/${progId}/trackRecord/${encodeURIComponent(dstamp)}`,
      () => {
        dispatch(setTrackRecordDataState('refresh'));
        //refresh prog data to refresh YTD calc
        dispatch(setProgramDataState('refresh'));
      }
    );
  };

  return {
    data: data?.data,
    qcData: data2?.data,
    patchData: patchData?.data,
    postData: postData?.data,
    rest,
    updateOneTrackRecord,
    createOneTrackRecord,
    deleteOneTrackRecord,
  };
};
