import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Login } from '@auth/login';
import { APIContextProvider } from '@api';
import { tokenStorage } from '@core/browser-storage';
import reportWebVitals from './reportWebVitals';
import configureStore from './redux/configure-store';
import { GuardedRoute } from '~/components/auth/guarded-route';
import { ComingSoonPage } from './components/coming-soon-page';
import {
  InternalServerError,
  PageNotFoundError,
} from './components/error-pages';
import { WindowSizeTracker } from './components/window-size-tracker';
import App from './app';
import './index.css';
import { ProgramsTable } from './components/program/table/programs-table';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faArrowLeft,
  faOutdent,
  faUser,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { ProgramTabsContainer } from './components/program/tabs';
import { ProgramInformation } from './components/program/tabs/tabs/program-information';
import { ProgramDetails } from './components/program/tabs/tabs/program-details';
import { CompaniesTable } from './components/company/table';
import { CompanyTabsContainer } from './components/company/tabs';
import { AdminHome } from './components/admin';
import { TempAccess } from './components/admin/temp-access';
import { ProtectedRoute } from '@auth/protected-route.js';
import { CreateTempAccessForm } from './components/admin/temp-access/form/create-temp-access-form';
import { RevokeAccessForm } from './components/admin/temp-access/form/revoke-access-form';
import { ProgramComposition } from './components/program/tabs/tabs/program-composition/program-composition';
import { CompanyInformation } from './components/company/tabs/tabs/company-information';
import { CompanyContacts } from './components/company/tabs/tabs/contacts';
import { CompanyNotes } from './components/company/tabs/tabs/company-notes';
import { CompanyClientBase } from './components/company/tabs/tabs/client-base';
import { CompanyFundsUnderManagement } from './components/company/tabs/tabs/funds-under-management';
import { CompanyFunds } from './components/company/tabs/tabs/company-funds';
import { ProgramAdministrative } from './components/program/tabs/tabs/program-administrative';
import { ProgramTrackRecord } from './components/program/tabs/tabs/program-track-record/program-track-record';
import { ProgramNotes } from './components/program/tabs/tabs/program-notes';
import {
  CompanyContactDeleteModal,
  CompanyContactModal,
} from './components/company/tabs/tabs/contacts/form';
import { InvestablePlatformModal } from './components/program/tabs/tabs/investable-platform/form';
import { InvestablePlatform } from './components/program/tabs/tabs/investable-platform';
import { TrackRecordFormModal } from './components/program/tabs/tabs/program-track-record/form';
import { CreateProgram } from './components/program/create';
import { CreateCompanyForm } from './components/company/create/create-company-form';
import { ClientBaseModal } from './components/company/tabs/tabs/client-base/form';
import { FundsUnderManagementFormModal } from './components/company/tabs/tabs/funds-under-management/form';
import { ProgramDailyData } from './components/program/tabs/tabs/program-daily-data/program-daily-data';
import { DailyDataFormModal } from './components/program/tabs/tabs/program-daily-data/form';
import { InstrumentsFormModal } from './components/program/tabs/tabs/program-composition/instruments/form';
import { HoldingsFormModal } from './components/program/tabs/tabs/program-composition/holdings/form';
import { ProgramChangeComp } from './components/program/tabs/tabs/program-information/change-comp';
import { ChangeStatusPopup } from './components/program/tabs/tabs/change-status-popup';
import { SelectOptionsContextProvider } from './api/selectContext';
import { InstrumentDeleteModal } from './components/program/tabs/tabs/program-composition/instruments/form/instruments-delete-modal';
import { HoldingDeleteModal } from './components/program/tabs/tabs/program-composition/holdings/form/holdings-delete-modal';

library.add(faArrowRight, faArrowLeft, faOutdent, faUser, faTrash);

export const store = configureStore({
  request: {
    expire: tokenStorage.getTokenExpTime(),
    pendingRequests: [],
  },
});

const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <APIContextProvider>
        <Login />
      </APIContextProvider>
    ),
  },
  {
    path: '/',
    element: (
      <APIContextProvider>
        <GuardedRoute>
          <SelectOptionsContextProvider>
            <App />
          </SelectOptionsContextProvider>
        </GuardedRoute>
      </APIContextProvider>
    ),
    errorElement: <InternalServerError />,
    children: [
      {
        index: true,
        element: <ComingSoonPage />,
      },
      {
        path: '/404',
        element: <PageNotFoundError />,
      },
      {
        path: '/500',
        element: <InternalServerError />,
      },
      {
        path: '/companies',
        element: <CompaniesTable />,
      },
      {
        path: '/programs',
        element: <ProgramsTable />,
      },
      {
        path: '/admin',
        element: (
          <ProtectedRoute>
            <AdminHome />
          </ProtectedRoute>
        ),
        children: [
          {
            path: '/admin/tempAccess',
            element: <TempAccess />,
          },
          {
            path: '/admin/tempAccess/create',
            element: <CreateTempAccessForm />,
          },
          {
            path: '/admin/tempAccess/revoke',
            element: <RevokeAccessForm />,
          },
          {
            path: '/admin/fullAccess',
            element: <ComingSoonPage />,
          },
        ],
      },
      {
        path: '/company/new',
        element: <CreateCompanyForm />,
      },
      {
        path: '/company/:id',
        element: <CompanyTabsContainer />,
        children: [
          {
            path: '/company/:id/compInfo',
            element: <CompanyInformation />,
          },
          {
            path: '/company/:id/contacts',
            element: <CompanyContacts />,
            children: [
              {
                path: '/company/:id/contacts/:contactId',
                element: <CompanyContactModal />,
                children: [
                  {
                    path: '/company/:id/contacts/:contactId/delete',
                    element: <CompanyContactDeleteModal />,
                  },
                ],
              },
            ],
          },
          {
            path: '/company/:id/notes',
            element: <CompanyNotes />,
          },
          {
            path: '/company/:id/clientBase',
            element: <CompanyClientBase />,
            children: [
              {
                path: '/company/:id/clientBase/:clientId',
                element: <ClientBaseModal />,
              },
              {
                path: '/company/:id/clientBase/:clientId/delete',
                element: <ClientBaseModal />,
              },
            ],
          },
          {
            path: '/company/:id/fum',
            element: <CompanyFundsUnderManagement />,
            children: [
              {
                path: '/company/:id/fum/:dstamp',
                element: <FundsUnderManagementFormModal />,
              },
              {
                path: '/company/:id/fum/:dstamp/delete',
                element: <FundsUnderManagementFormModal />,
              },
            ],
          },
          {
            path: '/company/:id/funds',
            element: <CompanyFunds />,
          },
        ],
      },
      {
        path: '/program/new',
        element: <CreateProgram />,
      },
      {
        path: '/program/:id',
        element: <ProgramTabsContainer />,
        children: [
          {
            path: '/program/:id/progInfo',
            element: <ProgramInformation />,
            children: [
              {
                path: '/program/:id/progInfo/changeComp',
                element: <ProgramChangeComp />,
              },
            ],
          },
          {
            path: '/program/:id/status',
            element: <ChangeStatusPopup />,
          },
          {
            path: '/program/:id/progDetails',
            element: <ProgramDetails />,
          },
          {
            path: '/program/:id/investPlat',
            element: <InvestablePlatform />,
            children: [
              {
                path: '/program/:id/investPlat/:platId',
                element: <InvestablePlatformModal />,
              },
            ],
          },
          {
            path: '/program/:id/admin',
            element: <ProgramAdministrative />,
          },
          {
            path: '/program/:id/trackRecord',
            element: <ProgramTrackRecord />,
            children: [
              {
                path: '/program/:id/trackRecord/:dstamp',
                element: <TrackRecordFormModal />,
              },
              {
                path: '/program/:id/trackRecord/:dstamp/delete',
                element: <TrackRecordFormModal />,
              },
            ],
          },
          {
            path: '/program/:id/dailyData',
            element: <ProgramDailyData />,
            children: [
              {
                path: '/program/:id/dailyData/:dstamp',
                element: <DailyDataFormModal />,
              },
            ],
          },
          {
            path: '/program/:id/composition',
            element: <ProgramComposition />,
            children: [
              {
                path: '/program/:id/composition/instrument/:instId',
                element: <InstrumentsFormModal />,
              },
              {
                path: '/program/:id/composition/instrument/:instId/delete',
                element: <InstrumentDeleteModal />,
              },
              {
                path: '/program/:id/composition/holding/:holdId',
                element: <HoldingsFormModal />,
              },
              {
                path: '/program/:id/composition/holding/:holdId/delete',
                element: <HoldingDeleteModal />,
              },
            ],
          },
          {
            path: '/program/:id/notes',
            element: <ProgramNotes />,
          },
        ],
      },
    ],
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <WindowSizeTracker />
      <RouterProvider router={router} />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
