import { setCompManagedFundsDataState } from '@redux/actions/company-actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlWithPageParams } from '~/api/api-utils';
import { useBaseGet } from '~/api/base';

export const useFundsUnderManagementTable = (compId) => {
  const { baseGet, data, loading } = useBaseGet();
  const dispatch = useDispatch();
  const dataState = useSelector(
    (state) => state.company.compManagedFundsDataState
  );
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);

  const getCompanyManagedFunds = (params) => {
    baseGet(getUrlWithPageParams(`/company/${compId}/managedFund/`, params));
  };

  useEffect(() => {
    if (dataState === 'refresh') {
      getCompanyManagedFunds({
        pageNumber: currentPage,
        pageSize: pageSize.value,
        sortDir: 'DESC',
        sortCol: 'date',
      });
    }
    dispatch(setCompManagedFundsDataState('loaded'));
  }, [dataState]);

  useEffect(() => {
    getCompanyManagedFunds({
      pageNumber: currentPage,
      pageSize: pageSize.value,
      sortDir: 'DESC',
      sortCol: 'date',
    });
  }, [pageSize, currentPage]);

  return {
    data: data?.data,
    meta: data?.meta,
    loading,
    setCurrentPage,
    currentPage,
    pageSize,
    setPageSize,
  };
};
