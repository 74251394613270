import { setHoldingDataState } from '@redux/actions/program-actions';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useBaseDelete,
  useBaseGet,
  useBasePatch,
  useBasePost,
} from '~/api/base';
import { useStrategyOptions } from '~/api/select';

export const useHoldingsForm = () => {
  const {
    basePatch,
    data: patchData,
    loading: loadingPatch,
    ...patchRest
  } = useBasePatch();
  const {
    basePost,
    data: postData,
    loading: loadingPost,
    ...postRest
  } = useBasePost();
  const {
    baseGet,
    data: getData,
    loading: loadingGet,
    ...getRest
  } = useBaseGet();
  const { baseDelete } = useBaseDelete();
  const {
    getStrategyOptions,
    strategyOptions,
    loading: loadingStrat,
  } = useStrategyOptions();
  const dispatch = useDispatch();

  const isLoading = () => {
    return loadingGet && loadingPatch && loadingPost && loadingStrat;
  };

  const getStrategyForType = (type) => {
    if (type === undefined) {
      return [];
    }
    return strategyOptions.filter((item) => {
      if (type === 'HedgeFund') {
        return item.hf === '1';
      }
      return item.cta === '1';
    });
  };

  const getOneHolding = (progId, holdId) => {
    baseGet(`/program/${progId}/holding/${holdId}`);
  };

  useEffect(() => {
    getStrategyOptions();
  }, []);

  const saveHolding = useCallback((progId, data) => {
    data.progId = progId;
    if (data.id) {
      basePatch(
        `/program/${progId}/holding/${data.id}`,
        { data: data },
        undefined,
        () => dispatch(setHoldingDataState('refresh'))
      );
    } else {
      basePost(`/program/${progId}/holding/`, { data: data }, undefined, () =>
        dispatch(setHoldingDataState('refresh'))
      );
    }
  }, []);

  const deleteHolding = (progId, holdingId) => {
    baseDelete(`/program/${progId}/holding/${holdingId}`, () => {
      dispatch(setHoldingDataState('refresh'));
    });
  };

  return {
    isLoading,
    saveHolding,
    getOneHolding,
    deleteHolding,
    getStrategyForType,
    getData: getData?.data,
    postData: postData?.data,
    postMeta: postData?.meta,
    patchData: patchData?.data,
    patchMeta: patchData?.meta,
    ...patchRest,
    ...postRest,
  };
};
