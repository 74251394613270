import { Button } from '@backstop/react-core';
import React from 'react';
import { useParams } from 'react-router-dom';

export const ExportFum = () => {
  const compId = useParams().id;

  const downloadFile = () => {
    window.open(
      process.env.REACT_APP_BARCLAYHEDGE_API_URL +
        `/company/${compId}/fum/download`,
      '_blank'
    );
  };

  return (
    <Button onClick={downloadFile} version="v3">
      Export Company FUM
    </Button>
  );
};
