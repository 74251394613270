import { Button } from '@backstop/react-core';
import React from 'react';

export const FormSaveButton = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button type="submit" kind="primary" version="v3">
        Save
      </Button>
    </div>
  );
};
