import React from 'react';
import { ProgramHoldingsTable } from './table';
import { FormContainer } from '@common/form-inputs';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@backstop/react-core';

export const Holdings = () => {
  const { id: progId } = useParams();
  const navigate = useNavigate();
  return (
    <FormContainer>
      <ProgramHoldingsTable />
      <Outlet />
      <Button
        version="v3"
        onClick={() => {
          navigate(`/program/${progId}/composition/holding/new`);
        }}
      >
        Create Holding
      </Button>
    </FormContainer>
  );
};
