import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, InlineMessageBlock } from '@backstop/react-core';

import api from '@api';
import { FormCheckbox, FormTextInput } from '@common/form-inputs';

import styles from './login-form.module.scss';

export const LoginForm = () => {
  const redirectLink = useParams().redirectUrl;
  const { login, loading, error } = api.auth.useLogin();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const getLoginHandler = () => {
    const handleLogin = handleSubmit((data) => login(data));

    return (e) => {
      if (loading) {
        e.preventDefault();
        return;
      } else {
        handleLogin(e);
      }
    };
  };

  const navigate = useNavigate();
  const loggedIn = api.auth.isLoggedIn();

  useEffect(() => {
    if (loggedIn) {
      if (redirectLink && redirectLink.length > 0) {
        navigate(`/${redirectLink}`);
        return;
      }
      navigate('/programs');
    }
  }, [loggedIn]);

  return (
    <form onSubmit={getLoginHandler()}>
      {error ? (
        <InlineMessageBlock kind="error">
          Login unsuccessful. Please make sure your email and password are
          correct.
        </InlineMessageBlock>
      ) : (
        ''
      )}

      <FormTextInput
        name="email"
        label="Email"
        placeholder="Enter Email"
        control={control}
        rules={{
          required: 'Email is required',
        }}
        disabled={loading}
        maxLength={48}
      />

      <FormTextInput
        name="password"
        label="Password"
        placeholder="Enter Password"
        control={control}
        type="password"
        rules={{
          required: 'Password is required',
        }}
        disabled={loading}
        maxLength={64}
      />

      <FormCheckbox
        name="termsOfUse"
        label=""
        control={control}
        checkboxLabel={
          <span>
            I agree to{' '}
            <a href="https://www.barclayhedge.com/terms-of-use" target="_blank">
              Terms of Use
            </a>
          </span>
        }
        defaultValue={false}
        rules={{
          required: 'Please tick this box if you want to proceed',
        }}
        disabled={loading}
      />

      <Button
        version="v3"
        type={loading ? 'button' : 'submit'}
        kind="primary"
        className={styles['login-button']}
        spinner={loading}
        disabled={loading}
        style={{ width: '100%' }}
      >
        <span className={styles['text']}>Login</span>
      </Button>
    </form>
  );
};
