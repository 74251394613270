import { Modal } from '@backstop/react-core';
import React from 'react';
import { InvestablePlatformForm } from './investable-platform-form';

export const InvestablePlatformModal = () => {
  return (
    <Modal version="v3" open={true} footerVisible={false}>
      <InvestablePlatformForm />
    </Modal>
  );
};
