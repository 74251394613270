import React from 'react';

import { useUserData, USER_ROLES } from '@core/user';

import { useLayoutData } from '@core/layout';
import { SidebarOption } from './sidebar-option/sidebar-option';

import search from 'assets/icons/search.png';
import dashboard from 'assets/icons/dashboard-simple.png';
import indices from 'assets/icons/indices.png';
import addressCard from 'assets/icons/address-card-solid.svg';

import logoLarge from 'assets/logos/logo-large-white.svg';
import logoSmall from 'assets/logos/logo-small-white.png';

import classnames from 'classnames';
import styles from './sidebar.module.scss';

const options = [
  {
    iconName: `${dashboard}`,
    title: 'Programs',
    href: '/programs',
    isOutsideLink: false,
  },
  {
    iconName: `${search}`,
    title: 'Companies',
    href: '/companies',
    isOutsideLink: false,
  },
  {
    iconName: `${indices}`,
    title: 'Create Company',
    href: '/company/new',
    isOutsideLink: false,
  },
  {
    iconName: `${addressCard}`,
    title: 'Users',
    href: '/admin/tempAccess',
    isOutsideLink: false,
    allowedRoles: [USER_ROLES.superUser, USER_ROLES.sales],
  },
];

export const Sidebar = () => {
  const { hasRole } = useUserData();
  const { sidebarCollapsed } = useLayoutData();

  const sidebarClasses = classnames([styles['body']], {
    [styles['body--is-collapsed']]: sidebarCollapsed,
  });

  const logoClasses = classnames([styles['logo-bar']], {
    [styles['logo-bar--is-collapsed']]: sidebarCollapsed,
  });

  const smallImageClasses = classnames(styles['logo-small'], {
    [styles['logo-small--is-hidden']]: !sidebarCollapsed,
  });

  const largeImageClasses = classnames(styles['logo-large'], {
    [styles['logo-large--is-hidden']]: sidebarCollapsed,
  });

  const shownOptions = options.filter(
    (option) => !option.allowedRoles || option.allowedRoles.some((someRole) => hasRole(someRole))
  );

  return (
    <aside className={sidebarClasses}>
      <div className={logoClasses}>
        <a href="https://www.barclayhedge.com/">
          <img
            loading="eager"
            src={logoSmall}
            alt="Barclay Hedge logo"
            className={smallImageClasses}
          />
          <img
            loading="eager"
            src={logoLarge}
            alt="Barclay Hedge logo"
            className={largeImageClasses}
          />
        </a>
      </div>
      {shownOptions.map((option) => (
        <SidebarOption
          option={option}
          key={option.title}
          isCollapsed={sidebarCollapsed}
        />
      ))}
    </aside>
  );
};
