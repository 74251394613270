import {
  FormCheckbox,
  FormContainer,
  FormContainerRequiredHeader,
} from '@common/form-inputs';
import { useLayoutForm } from '@core/layout/layout-form';
import React from 'react';
import PropTypes from 'prop-types';

export const ProgramCtaCategories = ({ control, data }) => {
  const { rowStyles, colStyle } = useLayoutForm();

  const fields = {
    tCrypto: (
      <FormCheckbox
        name="tCrypto"
        checkboxLabel="Technical - Digital Assets"
        control={control}
        defaultValue={data.tCrypto === '1'}
      />
    ),
    tDivers: (
      <FormCheckbox
        name="tDivers"
        checkboxLabel="Technical - Diversified"
        control={control}
        defaultValue={data.tDivers === '1'}
      />
    ),
    tFineMetal: (
      <FormCheckbox
        name="tFineMetal"
        checkboxLabel="Technical - Fin./Metals"
        control={control}
        defaultValue={data.tFineMetal === '1'}
      />
    ),
    tEnergy: (
      <FormCheckbox
        name="tEnergy"
        checkboxLabel="Technical - Energy"
        control={control}
        defaultValue={data.tEnergy === '1'}
      />
    ),
    tCurrency: (
      <FormCheckbox
        name="tCurrency"
        checkboxLabel="Technical - Currency"
        control={control}
        defaultValue={data.tCurrency === '1'}
      />
    ),
    tAgri: (
      <FormCheckbox
        name="tAgri"
        checkboxLabel="Technical - Agricultural"
        control={control}
        defaultValue={data.tAgri === '1'}
      />
    ),
    tIntRate: (
      <FormCheckbox
        name="tIntRate"
        checkboxLabel="Technical - Interest Rates"
        control={control}
        defaultValue={data.tIntRate === '1'}
      />
    ),
    tVolatility: (
      <FormCheckbox
        name="tVolatility"
        checkboxLabel="Technical - Volatility"
        control={control}
        defaultValue={data.tVolatility === '1'}
      />
    ),
    fCrypto: (
      <FormCheckbox
        name="fCrypto"
        checkboxLabel="Fundamental - Digital Assets"
        control={control}
        defaultValue={data.fCrypto === '1'}
      />
    ),
    fDivers: (
      <FormCheckbox
        name="fDivers"
        checkboxLabel="Fundamental - Diversified"
        control={control}
        defaultValue={data.fDivers === '1'}
      />
    ),
    fFinMetal: (
      <FormCheckbox
        name="fFinMetal"
        checkboxLabel="Fundamental - Fin./Metals"
        control={control}
        defaultValue={data.fFinMetal === '1'}
      />
    ),
    fEnergy: (
      <FormCheckbox
        name="fEnergy"
        checkboxLabel="Fundamental - Energy"
        control={control}
        defaultValue={data.fEnergy === '1'}
      />
    ),
    fCurrency: (
      <FormCheckbox
        name="fCurrency"
        checkboxLabel="Fundamental - Currency"
        control={control}
        defaultValue={data.fCurrency === '1'}
      />
    ),
    fAgri: (
      <FormCheckbox
        name="fAgri"
        checkboxLabel="Fundamental - Agriculture"
        control={control}
        defaultValue={data.fAgri === '1'}
      />
    ),
    fIntRate: (
      <FormCheckbox
        name="fIntRate"
        checkboxLabel="Fundamental - Interest Rates"
        control={control}
        defaultValue={data.fIntRate === '1'}
      />
    ),
    fVolatility: (
      <FormCheckbox
        name="fVolatility"
        checkboxLabel="Fundamental - Volatility"
        control={control}
        defaultValue={data.fVolatility === '1'}
      />
    ),
    riskPremia: (
      <FormCheckbox
        name="riskPremia"
        checkboxLabel="Risk Premia"
        control={control}
        defaultValue={data.riskPremia === '1'}
      />
    ),
    ai: (
      <FormCheckbox
        name="ai"
        checkboxLabel="Artificial Intelligence"
        control={control}
        defaultValue={data.ai === '1'}
      />
    ),
    arbitrage: (
      <FormCheckbox
        name="progArbitrage"
        checkboxLabel="Arbitrage"
        control={control}
        defaultValue={data.progArbitrage === '1'}
      />
    ),
    discretionary: (
      <FormCheckbox
        name="discret"
        checkboxLabel="Discretionary"
        control={control}
        defaultValue={data.discret === '1'}
      />
    ),
    optionStrat: (
      <FormCheckbox
        name="optnStrat"
        checkboxLabel="Option Strategies"
        control={control}
        defaultValue={data.optnStrat === '1'}
      />
    ),
    shortTerm: (
      <FormCheckbox
        name="shortTerm"
        checkboxLabel="Short Term Traders"
        control={control}
        defaultValue={data.shortTerm === '1'}
      />
    ),
    stockIndex: (
      <FormCheckbox
        name="stockIndex"
        checkboxLabel="Stock Index"
        control={control}
        defaultValue={data.stockIndex === '1'}
      />
    ),
    system: (
      <FormCheckbox
        name="system"
        checkboxLabel="Systematic"
        control={control}
        defaultValue={data.systematic === '1'}
      />
    ),
    noCat: (
      <FormCheckbox
        name="noCat"
        checkboxLabel="No Category"
        control={control}
        defaultValue={data.noCat === '1'}
      />
    ),
    propAcct: (
      <FormCheckbox
        name="propAcct"
        checkboxLabel="Proprietary Account"
        control={control}
        defaultValue={data.propAcct === '1'}
      />
    ),
  };

  return (
    <FormContainer>
      <FormContainerRequiredHeader title="CTA Categories" />
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.tCrypto}
          {fields.tDivers}
          {fields.tFineMetal}
          {fields.tEnergy}
          {fields.tCurrency}
          {fields.tAgri}
          {fields.tIntRate}
          {fields.tVolatility}
        </div>
        <div className={colStyle}>
          {fields.fCrypto}
          {fields.fDivers}
          {fields.fFinMetal}
          {fields.fEnergy}
          {fields.fCurrency}
          {fields.fAgri}
          {fields.fIntRate}
          {fields.fVolatility}
        </div>
        <div className={colStyle}>
          {fields.arbitrage}
          {fields.discretionary}
          {fields.optionStrat}
          {fields.shortTerm}
          {fields.stockIndex}
          {fields.system}
          {fields.propAcct}
          {fields.riskPremia}
          {fields.ai}
          {fields.noCat}
        </div>
      </div>
    </FormContainer>
  );
};

ProgramCtaCategories.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
