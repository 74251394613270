import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Checkbox } from '@backstop/react-core';
import { FormControl } from '@common/form-control';

export const FormCheckbox = ({
  name,
  label,
  tooltipText,
  control,
  defaultValue,
  rules,
  checkboxLabel,
  placeholder = 'Select one',
  formControlClassName,
  required,
  ...restProps
}) => {
  const isBoolean = (val) => 'boolean' === typeof val;

  const makeValue = (input) => {
    if (isBoolean(input)) {
      return input;
    }
    if (Number.isInteger(input)) {
      return input === 1 ? true : false;
    }
    if (typeof input === 'string') {
      return input === '1' ? true : false;
    }
  };

  return (
    <div style={{ marginBottom: '9px' }}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        shouldUnregister={true}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error },
        }) => {
          const errorList = error ? [error.message] : null;
          return (
            <FormControl
              label={label}
              errors={errorList}
              tooltipText={tooltipText}
              className={formControlClassName}
              required={required}
              {...restProps}
            >
              <Checkbox
                version="v3"
                name={name}
                label={checkboxLabel}
                onChange={onChange}
                onBlur={onBlur}
                checked={makeValue(value)}
                ref={ref}
                {...restProps}
              />
            </FormControl>
          );
        }}
      />
    </div>
  );
};

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  checkboxLabel: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  formControlClassName: PropTypes.string,
};
