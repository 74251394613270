import React, { useState } from 'react';
import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import { useCompaniesTable } from './hooks';
import { FormContainer } from '@common/form-inputs/form-container';
import { FormControl } from '@common/form-control';
import { Button, Select, TextInput } from '@backstop/react-core';
import styles from './companies-table.module.scss';

export const CompaniesTable = () => {
  const {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    setNameVal,
    setTypeVal,
    setStatusVal,
  } = useCompaniesTable();

  const [localNameVal, setLocalNameVal] = useState(null);
  const [localTypeVal, setLocalTypeVal] = useState(null);
  const [localStatusVal, setLocalStatusVal] = useState(null);

  const runSearch = () => {
    setNameVal(localNameVal);
    setTypeVal(localTypeVal);
    setStatusVal(localStatusVal);
  };

  const translateData = (data) => {
    return data?.map((obj) => ({ ...obj, compName: obj.name }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['body']}>
      <FormContainer>
        <FormControl label="Search Name">
          <TextInput
            version="v3"
            onChange={setLocalNameVal}
            placeholder="Search by company name"
            value={localNameVal}
          />
        </FormControl>
        <FormControl label="Search Type">
          <Select
            options={[
              { label: 'HedgeFund', value: 'HedgeFund' },
              { label: 'CTA', value: 'CTA' },
              {
                label: 'All',
                value: '',
              },
            ]}
            placeholder="All"
            version="v3"
            onChange={setLocalTypeVal}
            value={localTypeVal}
          />
        </FormControl>
        <FormControl label="Search Status">
          <Select
            options={[
              { label: 'In Progress', value: 'In Progress' },
              { label: 'In Review', value: 'In Review' },
              { label: 'Reviewed', value: 'Reviewed' },
              { label: 'Active', value: 'Active' },
              { label: 'Archived', value: 'Archived' },
              { label: 'All', value: '' },
            ]}
            placeholder="All"
            version="v3"
            onChange={setLocalStatusVal}
            value={localStatusVal}
          />
        </FormControl>
        <Button version="v3" onClick={runSearch}>
          Search
        </Button>
      </FormContainer>
      <GenericTable
        rows={translateData(data)}
        totalElements={meta?.totalElements}
        isLoading={loading}
        showPagination={true}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        cols={{
          compName: { value: 'compName', label: 'Name', columnLabel: 'Name' },
          compType: { value: 'type', label: 'Type', columnLabel: 'Type' },
          lastMod: {
            value: 'lastMod',
            label: 'Last Mod. Date',
            columnLabel: 'Last Mod. Date',
          },
          activeProgs: {
            value: 'activeProgs',
            label: 'Active Programs',
            columnLabel: 'Active Programs',
          },
          totalProgs: {
            value: 'totalProgs',
            label: 'Total Programs',
            columnLabel: 'Total Programs',
          },
        }}
      />
    </div>
  );
};
