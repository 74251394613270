import { useMemo, useState } from 'react';
import { alwaysVisible, visibleByDefault, COLUMNS } from '@core/columns';

export const useHiddenColumns = () => {
  const cols = Object.keys(COLUMNS).map((col) => ({
    name: COLUMNS[col].value,
    label: COLUMNS[col].columnLabel,
  }));
  const hiddenByDefault = useMemo(() => {
    const visibleCols = [...visibleByDefault, ...alwaysVisible];
    return cols
      .filter((col) => !visibleCols.includes(col.name))
      .map((col) => col.name);
  }, [cols, visibleByDefault, alwaysVisible]);

  const [hiddenColumns, setHiddenColumns] = useState(hiddenByDefault);

  return { hiddenColumns, setHiddenColumns };
};
