import React from 'react';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import { FormContainer, FormTextInput } from '@common/form-inputs';

export const ProgramCustodian = ({ control }) => {
  const { rowStyles, colStyle } = useLayoutForm();

  const fields = {
    firm: <FormTextInput control={control} name="custodianFirm" label="Firm" />,
    contact: (
      <FormTextInput
        control={control}
        name="custodianContact"
        label="Contact"
      />
    ),
    phone: (
      <FormTextInput
        control={control}
        name="custodianContactPhone"
        label="Phone"
      />
    ),
    email: (
      <FormTextInput
        control={control}
        name="custodianContactEmail"
        label="Email"
      />
    ),
  };

  return (
    <FormContainer title="Custodian">
      <div className={rowStyles}>
        <div className={colStyle}>{fields.firm}</div>
        <div className={colStyle}>{fields.contact}</div>
        <div className={colStyle}>{fields.email}</div>
        <div className={colStyle}>{fields.phone}</div>
      </div>
    </FormContainer>
  );
};

ProgramCustodian.propTypes = {
  control: PropTypes.object.isRequired,
};
