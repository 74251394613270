import { setDailyDataState } from '@redux/actions/program-actions';
import { useDispatch } from 'react-redux';
import { useBasePatch, useBasePost } from '~/api/base';

export const useUploadDailyData = () => {
  const { basePost, data: postData, status: postStatus } = useBasePost();
  const { basePatch, data: patchData } = useBasePatch();

  const dispatch = useDispatch();

  const uploadDailyDataPost = (progId, file) => {
    let formData = new FormData();
    formData.append('file', file);
    basePost(
      `/program/${progId}/dailyData/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      () => dispatch(setDailyDataState('refresh'))
    );
  };

  const uploadDailyDataPatch = (progId, file) => {
    let formData = new FormData();
    formData.append('file', file);
    basePatch(
      `/program/${progId}/dailyData/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      () => dispatch(setDailyDataState('refresh'))
    );
  };

  const validateUploadFile = (progId, file) => {
    let formData = new FormData();
    formData.append('file', file);
    basePost(`/program/${progId}/dailyData/validate`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  return {
    uploadDailyDataPost,
    uploadDailyDataPatch,
    validateUploadFile,
    postData,
    postStatus,
    patchData,
  };
};
