import { useCallback } from 'react';
import { getUrlWithPageParams } from './api-utils';
import { useBaseGet, useBasePatch, useBasePost } from './base';
import { convertSelectInputsToValues } from '@common/form-inputs/constants';
import { useDispatch } from 'react-redux';

/**
 * Get all companies given a set of optional params
 * @returns List of companies
 */
export const useAllCompanies = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getAllCompanies = useCallback((params) => {
    baseGet(getUrlWithPageParams('/company', params));
  }, []);

  return {
    getAllCompanies,
    data: data?.data,
    meta: data?.meta,
    ...rest,
  };
};

/**
 * Create/update a company
 * @returns post/patch data response
 */
export const saveCompanyData = () => {
  const {
    basePatch,
    data: patchData,
    error: patchError,
    ...patchRest
  } = useBasePatch();
  const { basePost, data: postData, ...postRest } = useBasePost();

  const saveCompany = useCallback((compData) => {
    let compId = compData.id;
    if (compId) {
      basePatch(`/company/${compId}`, { data: compData });
    } else {
      basePost(`/company/`, { data: compData });
    }
  }, []);

  return {
    saveCompany,
    postData: postData?.data,
    postStatus: postRest?.status,
    patchData: patchData?.data,
    postMeta: postData?.meta,
    patchMeta: patchData?.meta,
    patchError,
    patchRest,
    postRest,
    ...patchRest,
    ...postRest,
  };
};

/**
 * Get company contact data
 * @param {*} compId
 * @returns
 */
export const useCompanyContacts = (compId) => {
  const { baseGet, data: getData, loading: getLoading } = useBaseGet();

  const getCompanyContacts = useCallback((params) => {
    baseGet(getUrlWithPageParams(`/company/${compId}/contacts/`, params));
  }, []);

  const getOneContact = (contactId) => {
    baseGet(`/company/${compId}/contacts/${contactId}`);
  };

  return {
    getCompanyContacts,
    getOneContact,
    getData: getData?.data,
    getMeta: getData?.meta,
    getLoading,
  };
};

/**
 * Get company client base data
 * @param {*} compId
 * @returns
 */
export const useCompanyClientBase = (compId) => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getCompanyClientBase = useCallback((params) => {
    baseGet(getUrlWithPageParams(`/company/${compId}/clientBase/`, params));
  }, []);

  return {
    getCompanyClientBase,
    data: data?.data,
    meta: data?.meta,
    ...rest,
  };
};

/**
 * Get company history data
 * @param {*} compId
 * @returns
 */
export const useCompanyHistory = (compId) => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getHistory = useCallback((params) => {
    baseGet(getUrlWithPageParams(`/company/${compId}/history/`, params));
  }, []);

  return {
    getHistory,
    data: data?.data,
    meta: data?.meta,
    ...rest,
  };
};
