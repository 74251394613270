import React from 'react';
import { useForm } from 'react-hook-form';
import { PROGRAM_TYPES_ARRAY } from '@core/constants';
import { FormTextAreaInput, FormTextInput } from '@common/form-inputs';
import { FormSelectInput } from '@common/form-inputs/form-select-input';
import { Loader } from '@common/loader';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import { useLayoutForm } from '@core/layout/layout-form';
import {
  YES_NO_FLAG_OPTIONS,
  maxLengthRule,
  requiredRule,
} from '@common/form-inputs/constants';
import { useProgramCreateForm } from './hooks';
import { FormContainer } from '@common/form-inputs/form-container';
import { PROGRAM_DEFAULTS, saveProgramData } from '~/api/program';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '@common/generic-message';

export const CreateProgramForm = ({ companyData }) => {
  const { handleSubmit, control, watch } = useForm();
  const navigate = useNavigate();
  const { saveProgram, postData, postError } = saveProgramData();
  const { dataUsage, openOptions } = useProgramCreateForm();
  const { rowStyles, colStyle } = useLayoutForm();

  const onSubmit = (data) => {
    const tempProg = {
      ...PROGRAM_DEFAULTS,
      ...data,
      compId: companyData.id,
    };
    saveProgram(tempProg);
  };

  if (postData) {
    navigate(`/program/${postData.id}/progInfo`);
  }

  if (!dataUsage) {
    return <Loader />;
  }

  const watchType = watch('type');

  const fields = {
    name: (
      <FormTextInput
        name="name"
        label="Fund Name"
        rules={{ ...requiredRule }}
        control={control}
        required={true}
      />
    ),
    browseName: (
      <FormTextInput
        name="browseName"
        label="Browse Name"
        rules={{ ...requiredRule, ...maxLengthRule(38) }}
        control={control}
        required={true}
      />
    ),
    type: (
      <FormSelectInput
        name="type"
        label="Type"
        rules={{ ...requiredRule }}
        options={PROGRAM_TYPES_ARRAY}
        defaultValue={PROGRAM_TYPES_ARRAY.find(
          (x) => x.value === companyData.type
        )}
        control={control}
        required={true}
      />
    ),
    minAcct: (
      <FormTextInput
        name="minAccount"
        label="Minimum Account (K)"
        control={control}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    dailyData: (
      <FormSelectInput
        name="dailyData"
        label="Daily Data"
        control={control}
        rules={{ ...requiredRule }}
        options={YES_NO_FLAG_OPTIONS}
        defaultValue={YES_NO_FLAG_OPTIONS.find((x) => x.value === 0)}
        required={true}
      />
    ),
    lead: (
      <FormSelectInput
        name="leadFund"
        label="Lead?"
        control={control}
        options={YES_NO_FLAG_OPTIONS}
        defaultValue={YES_NO_FLAG_OPTIONS.find((x) => x.value === 0)}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    incentiveFee: (
      <FormTextInput
        name="incentiveFee"
        label="Incentive Fee"
        control={control}
        required={true}
        rules={{ ...requiredRule }}
      />
    ),
    mgtFee: (
      <FormTextInput
        name="mgtFee"
        label="Management Fee"
        control={control}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    tradingMethod: (
      <FormTextAreaInput
        name="tradingMethod"
        label="Trading Method"
        control={control}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    dataUsage: (
      <FormSelectInput
        name="dataUsage"
        label="Data Usage"
        control={control}
        options={dataUsage}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    lead: (
      <FormSelectInput
        name="leadFund"
        label="Is a Lead Fund?"
        options={YES_NO_FLAG_OPTIONS}
        defaultValue={YES_NO_FLAG_OPTIONS.find((x) => x.value === 0)}
        control={control}
      />
    ),
    flagship: (
      <FormSelectInput
        name="flagship"
        label="Is Firm's Flagship Fund?"
        options={YES_NO_FLAG_OPTIONS}
        defaultValue={YES_NO_FLAG_OPTIONS.find((x) => x.value === 0)}
        control={control}
      />
    ),
    open: (
      <FormSelectInput
        name="open"
        label="Open"
        control={control}
        options={openOptions}
        required={true}
        rules={{ ...requiredRule }}
      />
    ),
  };

  const type = watchType?.value || companyData.type;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ErrorMessage trigger={postError} message={postError?.message} />
      <FormContainer title="Create Program">
        <div className={rowStyles}>
          <div className={colStyle}>
            {fields.name}
            {fields.type}
            {fields.mgtFee}
            {type === 'CTA' ? fields.dailyData : null}
            {fields.lead}
            {fields.dataUsage}
          </div>
          <div className={colStyle}>
            {fields.browseName}
            {fields.open}
            {fields.incentiveFee}
            {type === 'CTA' ? fields.dataUsageDaily : null}
            {fields.flagship}
            {fields.minAcct}
            {fields.tradingMethod}
          </div>
        </div>
        <FormSubmitCancelButton
          cancelFunction={() => navigate(`/company/${companyData.id}/compInfo`)}
        />
      </FormContainer>
    </form>
  );
};
