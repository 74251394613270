import React from 'react';
import { Button, DragDropFileUpload, Modal } from '@backstop/react-core';
import PropTypes from 'prop-types';
import './upload.css';

export const UploadBoxAndModals = ({
  entityString,
  handleFileAdd,
  clickSuperReplace,
  clickCancelSuperModal,
  clickReplace,
  clickUpdate,
  clickCancel,
  modalMessage,
  fatalErr,
  modalOpen,
  superModalOpen,
}) => {
  return (
    <>
      <DragDropFileUpload
        style={{ backgroundColor: 'red' }}
        version="v3"
        postUrl={`#`}
        acceptedFiles=".csv"
        onComplete={handleFileAdd}
      />
      <Modal
        version="v3"
        closeOnEscape={false}
        closeOnOverlayClick={false}
        open={superModalOpen}
        footer={[
          <Button
            version="v3"
            onClick={clickSuperReplace}
            kind="secondary"
            className="bsg-mr3"
          >
            Yes, Replace All.
          </Button>,
          <Button
            version="v3"
            onClick={clickCancelSuperModal}
            kind="cancel"
            className="bsg-mr3"
          >
            Cancel
          </Button>,
        ]}
      >
        Are you sure you want to overwrite <strong>ALL</strong> historical{' '}
        {entityString} information?
      </Modal>
      <Modal
        version="v3"
        closeOnEscape={false}
        closeOnOverlayClick={false}
        open={modalOpen}
        footer={[
          !fatalErr && (
            <Button version="v3" onClick={clickReplace} className="bsg-mr3">
              Replace
            </Button>
          ),
          !fatalErr && (
            <Button
              version="v3"
              onClick={clickUpdate}
              kind="secondary"
              className="bsg-mr3"
            >
              Update
            </Button>
          ),
          <Button
            version="v3"
            onClick={clickCancel}
            kind="cancel"
            className="bsg-mr3"
          >
            Cancel
          </Button>,
        ]}
      >
        {modalMessage}
      </Modal>
    </>
  );
};

UploadBoxAndModals.propTypes = {
  entityString: PropTypes.any.isRequired,
  handleFileAdd: PropTypes.any.isRequired,
  clickSuperReplace: PropTypes.any.isRequired,
  clickCancelSuperModal: PropTypes.any.isRequired,
  clickReplace: PropTypes.any.isRequired,
  clickUpdate: PropTypes.any.isRequired,
  clickCancel: PropTypes.any.isRequired,
  modalMessage: PropTypes.any.isRequired,
  fatalErr: PropTypes.any.isRequired,
  modalOpen: PropTypes.any.isRequired,
  superModalOpen: PropTypes.any.isRequired,
};
