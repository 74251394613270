import { useEffect, useState } from 'react';
import { getUrlWithPageParams } from '~/api/api-utils';
import { useBaseGet } from '~/api/base';

export const useCompanyHistoryTable = (compId) => {
  const { baseGet, data, loading, ...rest } = useBaseGet();
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);

  const getHistory = (params) => {
    baseGet(getUrlWithPageParams(`/company/${compId}/history/`, params));
  };

  useEffect(() => {
    getHistory({ pageNumber: currentPage, pageSize: pageSize.value });
  }, [pageSize, currentPage]);

  return {
    data: data?.data,
    meta: data?.meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  };
};
