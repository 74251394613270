import * as types from './action-types';
import { userDataClean } from './user-data-actions';

export function loginFailure(data) {
  return {
    type: types.LOGIN_FAIL,
    data,
  };
}

export function loginSuccess(data) {
  return {
    type: types.LOGIN_PASS,
    data,
  };
}

export const logout = () => {
  return (dispatch) => {
    dispatch(userDataClean());
    dispatch({
      type: types.LOGOUT,
    });
  };
};
