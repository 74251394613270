import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCompanyClientBaseTable } from './hooks';
import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import { Table as BsgTable } from '@backstop/grid-view';
import { Button } from '@backstop/react-core';
import { FormContainer } from '@common/form-inputs';

export const CompanyClientBaseTable = () => {
  const compId = useParams().id;
  const navigate = useNavigate();
  const d = new Date();
  let year = d.getFullYear();
  const {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useCompanyClientBaseTable(compId);
  if (loading) {
    return <Loader />;
  }

  let hasLatestDate = data?.some((item) => item.date === year);
  return (
    <FormContainer>
      <GenericTable
        rows={data}
        cols={{
          date: { value: 'date', label: 'Year', columnLabel: 'Year' },
          institutional: {
            value: 'institutional',
            label: 'Institutional',
            columnLabel: 'Institutional',
          },
          highNetWorth: {
            value: 'highNetWorth',
            label: 'High Net Worth',
            columnLabel: 'High Net Worth',
          },
          familyOffice: {
            value: 'familyOffice',
            label: 'Family Office',
            columnLabel: 'Family Office',
          },
          fof: {
            value: 'fof',
            label: 'Fund of Funds',
            columnLabel: 'Fund of Funds',
          },
          proprietary: {
            value: 'proprietary',
            label: 'Proprietary',
            columnLabel: 'Proprietary',
          },
          other: { value: 'other', label: 'Other', columnLabel: 'Other' },
          total: { value: 'total', label: 'Total', columnLabel: 'Total' },
          action: { value: 'action', label: '', columnLabel: '' },
        }}
        cellComponent={ClientBaseTableCell}
        totalElements={meta?.totalElements}
        pageSize={pageSize}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        showPagination={true}
        isLoading={loading}
      />
      {!hasLatestDate && (
        <Button
          version="v3"
          onClick={() =>
            navigate(`/company/${compId}/clientBase/new`, {
              //send the last entry in the table to the form for populating data
              state: { data: data[data.length - 1] },
            })
          }
        >
          Create
        </Button>
      )}
    </FormContainer>
  );
};

const ClientBaseTableCell = (props) => {
  const navigate = useNavigate();
  if (props.column.name === 'total') {
    let propRow = props.row;
    let val =
      propRow.familyOffice +
      propRow.fof +
      propRow.highNetWorth +
      propRow.institutional +
      propRow.other +
      propRow.proprietary;
    return <BsgTable.Cell>{val}</BsgTable.Cell>;
  }
  if (props.column.name === 'action') {
    return (
      <BsgTable.Cell>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            version="v3"
            kind="text"
            onClick={() =>
              navigate(
                `/company/${props.row.compId}/clientBase/${props.row.date}`,
                { state: props.row }
              )
            }
          >
            Edit
          </Button>
          <Button
            version="v3"
            kind="text"
            onClick={() =>
              navigate(
                `/company/${props.row.compId}/clientBase/${props.row.date}/delete`,
                { state: props.row }
              )
            }
          >
            Delete
          </Button>
        </div>
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
