import React from 'react';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import {
  FormContainer,
  FormSelectInput,
  FormTextInput,
} from '@common/form-inputs';
import { useProgramAdministrative } from './hooks';
import { Loader } from '@common/loader';

export const ProgramTransferAgent = ({ data, control }) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { stateOptions, countryOptions, isLoadingSelect } =
    useProgramAdministrative();

  if (isLoadingSelect()) {
    return <Loader />;
  }

  const fields = {
    firm: <FormTextInput control={control} name="trAgentFirm" label="Firm" />,
    contact: (
      <FormTextInput control={control} name="trAgentContact" label="Contact" />
    ),
    phone: (
      <FormTextInput control={control} name="trAgentPhone" label="Phone" />
    ),
    fax: <FormTextInput control={control} name="trAgentFax" label="Fax" />,
    email: (
      <FormTextInput control={control} name="trAgentEmail" label="Email" />
    ),
    address1: (
      <FormTextInput control={control} name="trAgentAddress1" label="Address" />
    ),
    address2: (
      <FormTextInput
        control={control}
        label="&nbsp;"
        name="trAgentAddress2"
        placeholder="Address"
      />
    ),
    city: <FormTextInput control={control} name="trAgentCity" label="City" />,
    state: (
      <FormSelectInput
        name="trAgentState"
        label="State"
        control={control}
        options={stateOptions}
        defaultValue={stateOptions.find((x) => x.value === data.trAgentState)}
      />
    ),
    zip: <FormTextInput control={control} name="trAgentZip" label="Zip" />,
    country: (
      <FormSelectInput
        name="trAgentCountry"
        label="County"
        control={control}
        options={countryOptions}
        defaultValue={countryOptions.find(
          (x) => x.value === data.trAgentCountry
        )}
      />
    ),
  };

  return (
    <FormContainer title="Transfer Agent">
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.firm}
          {fields.address1}
          {fields.zip}
        </div>
        <div className={colStyle}>
          {fields.contact}
          {fields.address2}
          {fields.country}
        </div>
        <div className={colStyle}>
          {fields.email}
          {fields.city}
          {fields.fax}
        </div>
        <div className={colStyle}>
          {fields.phone}
          {fields.state}
        </div>
      </div>
    </FormContainer>
  );
};

ProgramTransferAgent.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
