import React from 'react';
import { FormTextInput } from '@common/form-inputs';
import { FormSelectInput } from '@common/form-inputs/form-select-input';
import { useProgramInformation } from './hooks';
import { FormContainer } from '@common/form-inputs/form-container';
import { maxLengthRule } from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Loader } from '@common/loader';

export const ProgramInfoContact = ({ control, data }) => {
  const programId = useParams().id;
  const { positionOptions, isLoadingSelect } = useProgramInformation(programId);
  const { rowStyles, colStyle } = useLayoutForm();

  if (isLoadingSelect()) {
    return <Loader />;
  }

  return (
    <FormContainer title="Contact">
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormTextInput
            name="contactName"
            label="Name"
            control={control}
            rules={{ ...maxLengthRule(64) }}
          />

          <FormTextInput
            name="contactPhone"
            label="Phone"
            control={control}
            rules={{ ...maxLengthRule(20) }}
          />
        </div>
        <div className={colStyle}>
          <FormTextInput
            name="contactEmail"
            label="Email"
            control={control}
            rules={{ ...maxLengthRule(64) }}
          />

          <FormSelectInput
            name="contactPosition"
            label="Position"
            control={control}
            options={positionOptions}
            defaultValue={positionOptions.find(
              (x) => x.value === data.contactPosition
            )}
          />
        </div>
        <div className={colStyle}>
          <FormTextInput
            name="contactFax"
            label="Fax"
            control={control}
            rules={{ ...maxLengthRule(64) }}
          />
        </div>
      </div>
    </FormContainer>
  );
};

ProgramInfoContact.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
};
