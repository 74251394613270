import { FormContainer } from '@common/form-inputs/form-container';
import { Loader } from '@common/loader';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useCompanyData } from '../../hooks';
import { FormTextInput } from '@common/form-inputs';
import { Button } from '@backstop/react-core';
import { useLayoutForm } from '@core/layout/layout-form';
import { saveCompanyData } from '~/api/company';
import {
  ErrorMessage,
  SuccessMessage,
  UnsavedChangesMessage,
} from '@common/generic-message';
import { FormControl } from '@common/form-control';

export const CompanyDataCollectionContactForm = () => {
  const compId = useParams().id;
  const { data } = useCompanyData(compId);
  const { saveCompany, patchData, patchError } = saveCompanyData();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm();

  const { rowStyles, colStyle } = useLayoutForm();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = (formData) => {
    let tempComp = {
      ...data,
      ...formData,
    };
    delete tempComp.adminNotes;
    saveCompany(tempComp);
    reset(tempComp);
  };

  if (!data) {
    return <Loader />;
  }

  const fields = {
    contactName: (
      <FormTextInput
        name="contactName"
        label="Name"
        fauxRequired={true}
        control={control}
      />
    ),
    contactPhone: (
      <FormTextInput name="contactPhone" label="Phone" control={control} />
    ),
    contactFax: (
      <FormTextInput name="contactFax" label="Fax" control={control} />
    ),
    contactEmail: (
      <FormTextInput name="contactEmail" label="Email" control={control} />
    ),
    contactUrl: <FormTextInput name="dataUrl" label="URL" control={control} />,
  };

  const openAdminCentralTab = () => {
    window.open(
      `https://www1.barclayhedge.com/cgi-bin/site/internal/admincentral/consoleLanding.cgi?id=${compId}&co=1`,
      '_blank',
      'noreferrer'
    );
    return;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UnsavedChangesMessage trigger={isDirty} />
      <ErrorMessage trigger={patchError} message={patchError?.message} />
      <SuccessMessage trigger={patchData} />
      <FormContainer title="Data Collection Contact">
        <div className={rowStyles}>
          <div className={colStyle}>
            {fields.contactName}
            {fields.contactPhone}
          </div>
          <div className={colStyle}>
            {fields.contactEmail}
            {fields.contactFax}
          </div>
          <div className={colStyle}>
            {fields.contactUrl}
            <FormControl>
              <Button
                version="v3"
                style={{ marginBottom: '0.25rem', marginTop: '1.5rem' }}
                onClick={() => openAdminCentralTab()}
              >
                Web
              </Button>
            </FormControl>
          </div>
        </div>
        <Button type={'submit'} kind={'primary'} version="v3">
          Save
        </Button>
      </FormContainer>
    </form>
  );
};
