import React from 'react';
import { ProgramGeographicAllocation } from './program-geographic-allocation';
import { ProgramAveragePortfolioExposure } from './program-average-portfolio-exposure';
import { ProgramHedgeFundStrategies } from './program-hedgefund-strategies';

export const ProgramGeographicAllocationContainer = (props) => {
  return (
    <>
      <ProgramGeographicAllocation {...props} />
      <ProgramAveragePortfolioExposure {...props} />
      <ProgramHedgeFundStrategies {...props} />
    </>
  );
};
