import { convertSelectInputsToValues } from '@common/form-inputs/constants';
import { useCallback } from 'react';
import { getUrlWithPageParams } from './api-utils';
import { useBaseGet, useBasePatch, useBasePost } from './base';
import { useDispatch } from 'react-redux';
import { setTrackRecordDataState } from '@redux/actions/program-actions';

/**
 * All the default values behind a program
 */
export const PROGRAM_DEFAULTS = {
  id: 0,
  advance: '',
  compId: 0,
  dataUsage: '',
  dataUsageDaily: '',
  eActive: 0,
  ai: 0,
  aifmd: 0,
  cik: '',
  isin: '',
  ucitsLegalStructure: '',
  active: 2,
  activeStatus: '',
  adminAddress1: '',
  adminAddress2: '',
  adminCity: '',
  adminCountry: '',
  adminState: '',
  adminContact: '',
  adminContactEmail: '',
  adminContactPhone: '',
  adminZip: '',
  adminFirm: '',
  adminNotes: '',
  auditFirm: '',
  auditContact: '',
  auditContactPhone: '',
  auditContactEmail: '',
  auditMonth: '',
  browseName: '',
  capacity: 0,
  code: '',
  composite: 0,
  contactEmail: '',
  contactFax: '',
  contactName: '',
  contactPhone: '',
  contactPosition: '',
  created: '',
  custodianContact: '',
  custodianContactEmail: '',
  custodianContactPhone: '',
  custodianFirm: '',
  dailyData: 0,
  denominat: '',
  denominatMum: '',
  doNotContact: 0,
  equalization: 0,
  equalizationMethod: '',
  exchangeListing: '',
  exclPublisher: 0,
  expenseCap: 0,
  flagship: 0,
  grossExpenseRatio: 0,
  hardLockup: 0,
  highwater: '',
  highwaterResetFrequency: '',
  hurdle: '',
  progId: 0,
  incentiveFee: 0,
  incentiveFeeFrequency: '',
  investStartup: 0,
  investorType: '',
  investorsAccepted: '',
  k1: 0,
  k1Month: '',
  lastAudit: '',
  lastMod: '',
  leadFund: 0,
  legalContact: '',
  legalContactPhone: '',
  legalContactEmail: '',
  legalFirm: '',
  leverage: 0,
  leverageType: '',
  lockup: '',
  mgtFee: 0,
  minAccount: 0,
  name: '',
  name2: '',
  netExpenseRatio: 0,
  noCat: 0,
  noRank: 0,
  numberUlhf: '',
  open: '',
  optnStrat: 0,
  otherFees: '',
  payoutDays: 0,
  payoutPct: 0,
  paysDividends: 0,
  primBench: '',
  primBenchId: 0,
  primeBroker: '',
  primeContact: '',
  primeContactEmail: '',
  primeContactPhone: '',
  redemptionFees: '',
  redemptions: '',
  registration: '',
  retType: '',
  retTypeDaily: '',
  riskPremia: 0,
  rorMumDisclosure: '',
  secBench: '',
  secBenchId: 0,
  sociallyResp: 0,
  stateDom: '',
  structuredProd: 0,
  subscriptions: '',
  subsequentInvestmentSize: 0,
  targetedReturn: '',
  targetedVolatility: '',
  ticker: '',
  trAgentAddress1: '',
  trAgentAddress2: '',
  trAgentCity: '',
  trAgentContact: '',
  trAgentCountry: '',
  trAgentEmail: '',
  trAgentFax: '',
  trAgentFirm: '',
  trAgentPhone: '',
  trAgentState: '',
  trAgentZip: '',
  tradingMethod: '',
  trkint: 0,
  type: '',
  ulhfAlias: 0,
  us: '',
  vamiNotCompound: 0,

  // Vars specific to cta prog
  progArbitrage: 0,
  ctaFund: 0,
  exempt: '',
  discretion: 0,
  discret: 0,
  fAgri: 0,
  fCrypto: 0,
  fCurrency: 0,
  fDivers: 0,
  fEnergy: 0,
  fFinMetal: 0,
  fIntRate: 0,
  fVolatility: 0,
  interbank: 0,
  meRatio: 0,
  minAccountFund: 0,
  multiadvisor: 0,
  options: 0,
  pcBasemet: -1,
  pcCurrency: -1,
  pcEnergy: -1,
  pcEquity: -1,
  pcFutures: -1,
  pcGrains: -1,
  pcIntRate: -1,
  pcMeats: -1,
  pcOther: -1,
  pcOtherSt: 0,
  pcPrecmet: -1,
  pcSofts: -1,
  pcSsf: -1,
  pcStock: -1,
  pcVix: -1,
  propAcct: 0,
  publicPrivate: '',
  rtYrMil: 0,
  shortTerm: 0,
  stockIndex: 0,
  strategyAssets: 0,
  system: 0,
  tAgri: 0,
  tCrypto: 0,
  tCurrency: 0,
  tDivers: 0,
  tEnergy: 0,
  tfLong: -1,
  tfMedium: -1,
  tfShort: -1,
  tFineMetal: 0,
  tIntRate: 0,
  tVolatility: 0,

  // fields only on non-cta prog
  aacStatus: 0,
  activist: 0,
  advisor: '',
  advisorContact: '',
  advisorContactEmail: '',
  advisorContactPhone: '',
  algorithmic: 0,
  amerPa: -1,
  avgLong: -1,
  avgNet: -1,
  avgShort: -1,
  balanced: 0,
  categoryStyle: '',
  closedEnd: 0,
  cnvrtarb: 0,
  dedShort: 0,
  distress: 0,
  dividendCap: 0,
  e130: 0,
  eeurPa: -1,
  emAsia: 0,
  emeecis: 0,
  emGlobal: 0,
  emLatinAm: 0,
  emMena: 0,
  emOther: 0,
  eqtyHedge: 0,
  eqtyMneut: 0,
  eqtyNonhg: 0,
  eventDriven: 0,
  fiArb: 0,
  fiAssetBack: 0,
  fiAssetBackLoans: 0,
  fiCollDebt: 0,
  fiConvbnd: 0,
  fiHighyld: 0,
  fiIls: 0,
  fiLoCred: 0,
  fiLsCred: 0,
  fiMisc: 0,
  fiMtgBack: 0,
  fiUnconstrained: 0,
  focusClass: '',
  fofInvestsMngAcct: 0,
  fofInvestsMngAcctPct: 0,
  fundFund: 0,
  geoFocus: '',
  idAcc: '',
  initialVami: 1000,
  latamPa: -1,
  longOnly: 0,
  macroFnd: 0,
  managedAcct: 0,
  menaPa: -1,
  mergarb: 0,
  mkTiming: 0,
  otherPa: -1,
  pacifPa: -1,
  primaryCat: '',
  registerFund: 0,
  reguld: 0,
  relativeValue: 0,
  relvalarb: 0,
  replication: 0,
  secondaryCat: '',
  selfClass: '',
  sEnergy: 0,
  sEnv: 0,
  sEsg: 0,
  sFarming: 0,
  sFinance: 0,
  sHealth: 0,
  shortSell: 0,
  smartBeta: 0,
  sMetals: 0,
  sMisc: 0,
  sNatural: 0,
  sRealest: 0,
  statarbtg: 0,
  stechnol: 0,
  systematic: 0,
  taticalAssetAlLocation: 0,
  tailRisk: 0,
  volatility: 0,
  weurPa: -1,
};

/**
 * Get one program
 * @returns
 */
export const useOneProgram = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getOneProgram = (progId) => {
    baseGet(`/program/${progId}/`);
  };

  return {
    getOneProgram,
    data: data?.data,
    meta: data?.meta,
    ...rest,
  };
};

/**
 * Get all programs with optional sorting/paging/filtering
 * @returns
 */
export const useAllPrograms = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getAllPrograms = useCallback((params) => {
    baseGet(getUrlWithPageParams('/program', params));
  }, []);

  return {
    getAllPrograms,
    data: data?.data,
    meta: data?.meta,
    ...rest,
  };
};

/**
 * Save/Update a program
 * @returns
 */
export const saveProgramData = () => {
  const {
    basePatch,
    data: patchData,
    error: patchError,
    ...patchRest
  } = useBasePatch();
  const {
    basePost,
    data: postData,
    error: postError,
    ...postRest
  } = useBasePost();

  const saveProgram = (progData) => {
    progData.adminNotes = undefined;
    let progId = progData.id;

    if (progId) {
      basePatch(`/program/${progId}`, {
        data: convertSelectInputsToValues(progData),
      });
    } else {
      basePost(`/program/`, { data: convertSelectInputsToValues(progData) });
    }
  };

  return {
    saveProgram,
    postData: postData?.data,
    postMeta: postData?.meta,
    patchData: patchData?.data,
    patchMeta: patchData?.meta,
    patchError,
    postError,
    ...patchRest,
    ...postRest,
  };
};

/**
 * Get program daily data
 * @param {*} progId
 * @returns
 */
export const useProgramDailyData = (progId) => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getDailyData = useCallback((params) => {
    baseGet(getUrlWithPageParams(`/program/${progId}/dailyData`, params));
  }, []);

  return {
    getDailyData,
    data: data?.data,
    meta: data?.meta,
    ...rest,
  };
};

/**
 * Get program investment platforms
 * @param {*} progId
 * @returns
 */
export const useProgramInvestmentPlatform = (progId) => {
  const { baseGet, data, loading, ...rest } = useBaseGet();

  const getInvestmentPlatforms = useCallback((params) => {
    baseGet(getUrlWithPageParams(`/program/${progId}/investPlat`, params));
  }, []);

  return {
    getInvestmentPlatforms,
    data: data?.data,
    meta: data?.meta,
    loading,
  };
};

/**
 * Get program track records
 * @param {*} progId
 * @returns
 */
export const useProgramTrackRecord = (progId) => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getTrackRecords = (params) => {
    baseGet(getUrlWithPageParams(`/program/${progId}/trackRecord`, params));
  };

  return {
    getTrackRecords,
    data: data?.data,
    meta: data?.meta,
    ...rest,
  };
};

export const useProgramTrackRecordUpload = () => {
  const { basePost, data: postData, status: postStatus } = useBasePost();
  const { basePatch, data: patchData } = useBasePatch();

  const dispatch = useDispatch();

  const uploadTrackRecordPost = (progId, file) => {
    let formData = new FormData();
    formData.append('file', file);
    basePost(
      `/program/${progId}/trackRecord/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      () => dispatch(setTrackRecordDataState('refresh'))
    );
  };

  const uploadTrackRecordPatch = (progId, file) => {
    let formData = new FormData();
    formData.append('file', file);
    basePatch(
      `/program/${progId}/trackRecord/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      () => dispatch(setTrackRecordDataState('refresh'))
    );
  };

  const validateUploadFile = (progId, file) => {
    let formData = new FormData();
    formData.append('file', file);
    basePost(`/program/${progId}/trackRecord/validate`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  return {
    uploadTrackRecordPatch,
    uploadTrackRecordPost,
    validateUploadFile,
    postStatus,
    postData,
    patchData,
  };
};

/**
 * Get program history
 * @param {*} progId
 * @returns
 */
export const useProgramHistory = (progId) => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getHistory = useCallback((params) => {
    baseGet(getUrlWithPageParams(`/program/${progId}/history`, params));
  }, []);

  return {
    getHistory,
    data: data?.data,
    meta: data?.meta,
    ...rest,
  };
};
