import React, { useEffect } from 'react';
import { FormTextInput, FormSelectInput } from '@common/form-inputs';
import PropTypes from 'prop-types';
import { useLayoutForm } from '@core/layout/layout-form';

export const FormControlHybridInput = ({
  name,
  label,
  control,
  watch,
  setValue,
  value,
  required,
  rules,
  removeOption,
}) => {
  const YES_NO_OTHER = [
    { label: 'N/A', value: -1 },
    { label: 'Yes', value: -2 },
    { label: 'Value', value: -3 },
  ];

  let SELECT_OPTIONS = removeOption
    ? YES_NO_OTHER.filter((x) => x.value !== removeOption)
    : YES_NO_OTHER;

  const { rowStyles, colStyle } = useLayoutForm();

  const valIsNA = (val) => {
    const numVal = Number(val);
    return numVal === -1 || numVal === -100 || numVal === -101;
  };

  if (valIsNA(value)) {
    value = -1;
  }

  let watchSelect = watch(`${name}Select`);
  let watchText = watch(`${name}Text`);

  useEffect(() => {
    setValue(`${name}Text`, value);
  }, []);

  useEffect(() => {
    if (valIsNA(watchSelect?.value) || watchSelect?.value === -2) {
      setValue(`${name}`, watchSelect?.value);
    }
    if (watchSelect?.value === -3) {
      if (watchText === undefined) {
        //it's being set to 'value', clear the value
        setValue(
          `${name}Text`,
          valIsNA(value) || Number(value) === -2 ? '' : value
        );
      }
    }
  }, [watchSelect]);

  useEffect(() => {
    if (watchText && (valIsNA(watchText) || watchText !== -2)) {
      setValue(`${name}`, watchText);
    }
  }, [watchText]);

  let requiredPadding = required ? { paddingTop: '17px' } : {};

  return (
    <>
      <div
        className="bsg-FormControl__labelWrapper"
        style={{ marginBottom: '-14px', marginTop: '12px' }}
      >
        <label className="bsg-FormControl__label">{label}</label>
      </div>
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormSelectInput
            name={`${name}Select`}
            control={control}
            required={required}
            options={SELECT_OPTIONS}
            defaultValue={
              SELECT_OPTIONS.find((x) => x.value === Number(value)) ||
              SELECT_OPTIONS.find((x) => x.value === -3)
            }
          />
        </div>
        <div className={colStyle} style={requiredPadding}>
          {watchSelect?.value === -3 && (
            <FormTextInput
              name={`${name}Text`}
              control={control}
              defaultValue={value}
              rules={rules}
            />
          )}
        </div>
      </div>
    </>
  );
};

FormControlHybridInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  rules: PropTypes.object,
  //RemoveOption: removes an option from the possible options
  removeOption: PropTypes.oneOf([-1, -2]),
};
