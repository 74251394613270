import PropTypes from 'prop-types';
import { dataUnavailable, toPercent, toDecimal } from '@core/formatters';

export const DecimalPercentageFormatter = ({ value }) => {
  if (value === '-101') {
    return 'N/A';
  }
  if (value == 'Yes' || value == 'No') {
    return value;
  }

  return dataUnavailable(toPercent(toDecimal(value)));
};

DecimalPercentageFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
