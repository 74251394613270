import {
  FormContainer,
  FormControlHybridInput,
  FormTextInput,
} from '@common/form-inputs';
import {
  unrestrictedDecimalPatternRule,
  maxValueRule,
  requiredRule,
} from '@common/form-inputs/constants';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useInstrumentsForm } from './hooks';
import { Loader } from '@common/loader';

export const InstrumentsForm = () => {
  const { id: progId, instId } = useParams();
  const { handleSubmit, control, reset, watch, setValue } = useForm();
  const navigate = useNavigate();
  const { saveInstrument, getOneInstrument, getData } = useInstrumentsForm();
  const title = instId === 'new' ? 'Create Instrument' : 'Edit Instrument';

  useEffect(() => {
    if (instId != 'new') {
      getOneInstrument(progId, instId);
    }
  }, []);

  useEffect(() => {
    reset(getData);
  }, [getData]);

  const onSubmit = (data) => {
    const tempInstrument = {
      ...data,
    };
    saveInstrument(progId, tempInstrument);
    navigate(`/program/${progId}/composition`);
  };

  if (instId != 'new' && !getData) {
    //don't render the form if it's loading for edit
    return <Loader />;
  }

  return (
    <FormContainer title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextInput
          name="name"
          label="Name"
          control={control}
          required={true}
          rules={{ ...requiredRule }}
        />

        <FormControlHybridInput
          name="value"
          label="Value"
          required={true}
          value={Number(getData?.value) || -2}
          rules={{
            ...unrestrictedDecimalPatternRule,
            ...maxValueRule(999.99),
            ...requiredRule,
          }}
          control={control}
          watch={watch}
          setValue={setValue}
        />
        <FormSubmitCancelButton
          cancelFunction={() => navigate(`/program/${progId}/composition`)}
        />
      </form>
    </FormContainer>
  );
};

InstrumentsForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};
