import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTrackRecordTable } from './hooks';
import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import { Table as BsgTable } from '@backstop/grid-view';
import { Button } from '@backstop/react-core';
import { FormContainer } from '@common/form-inputs';

export const TrackRecordTable = () => {
  const progId = useParams().id;

  const {
    data,
    meta,
    loading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = useTrackRecordTable(progId);

  if (loading) {
    return <Loader />;
  }

  return (
    <FormContainer>
      <GenericTable
        rows={data}
        cols={{
          date: { value: 'date', label: 'Date', columnLabel: 'Date' },
          ror: { value: 'ror', label: 'RoR', columnLabel: 'RoR' },
          rorConfirm: {
            value: 'rorConfirm',
            label: 'RoR Confirmed',
            columnLabel: 'RoR Confirmed',
          },
          aum: { value: 'fum', label: 'AUM (k)', columnLabel: 'AUM (k)' },
          vami: { value: 'vami', label: 'VAMI', columnLabel: 'VAMI' },
        }}
        cellComponent={TrackRecordTableCell}
        totalElements={meta?.totalElements}
        pageSize={pageSize}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        showPagination={true}
        isLoading={loading}
      />
    </FormContainer>
  );
};

const TrackRecordTableCell = (props) => {
  let progId = props.row.progId;
  let date = props.row.date;
  if (props.column.name === 'date') {
    return (
      <BsgTable.Cell>
        <Link to={`/program/${progId}/trackRecord/${date}`}>
          {props.row.date}
        </Link>
      </BsgTable.Cell>
    );
  }
  if (props.column.name === 'rorConfirm') {
    return (
      <BsgTable.Cell>{Number(props.value) === 0 ? 'No' : 'Yes'}</BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
