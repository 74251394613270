import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useApiContext } from '@api';
import {
  cacheClean,
  cacheLoadingClean,
  cacheSet,
} from '@redux/actions/cache-actions';

export const useBaseGet = (options) => {
  const { noAuth = false, cacheConfig } = options || {};
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [error, setError] = useState(null);
  const abortController = useRef();
  const { apiHttpClient, unauthorizedApiHttpClient } = useApiContext();

  const httpClient = noAuth ? unauthorizedApiHttpClient : apiHttpClient;

  const baseGet = useCallback((url, config = {}, postProccess) => {
    setLoading(true);
    setIsInitialLoad(false);
    setError(null);

    if (abortController.current) {
      abortController.current.abort();
    }

    const thisCallAbortController = new AbortController();
    abortController.current = thisCallAbortController;

    httpClient
      .get(url, {
        signal: abortController.current.signal,
        ...config,
      })
      .then((response) => {
        setData(response.data);
      })
      .then(() => {
        if (postProccess) {
          postProccess();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(error);
        }
      })
      .finally(() => {
        if (
          abortController.current &&
          thisCallAbortController !== abortController.current
        ) {
          setLoading(false);
          return;
        }

        if (thisCallAbortController === abortController.current) {
          abortController.current = null;
        }

        setLoading(false);
      });
  }, []);

  return {
    baseGet,
    data: data,
    loading,
    isInitialLoad,
    error,
  };
};

/**
 * This was set up poorly... useBaseGet doesn't allow two calls to be done in parallel
 * @param {*} options
 * @returns
 */
export const useBaseGetSecondary = (options) => {
  const { noAuth = false, cacheConfig } = options || {};
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [error, setError] = useState(null);
  const abortController = useRef();
  const { apiHttpClient, unauthorizedApiHttpClient } = useApiContext();

  const httpClient = noAuth ? unauthorizedApiHttpClient : apiHttpClient;

  const baseGetSecondary = useCallback((url, config = {}, postProccess) => {
    setLoading(true);
    setIsInitialLoad(false);
    setError(null);

    if (abortController.current) {
      abortController.current.abort();
    }

    const thisCallAbortController = new AbortController();
    abortController.current = thisCallAbortController;

    httpClient
      .get(url, {
        signal: abortController.current.signal,
        ...config,
      })
      .then((response) => {
        setData(response.data);
      })
      .then(() => {
        if (postProccess) {
          postProccess();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(error);
        }
      })
      .finally(() => {
        if (
          abortController.current &&
          thisCallAbortController !== abortController.current
        ) {
          setLoading(false);
          return;
        }

        if (thisCallAbortController === abortController.current) {
          abortController.current = null;
        }

        setLoading(false);
      });
  }, []);

  return {
    baseGetSecondary,
    data: data,
    loading,
    isInitialLoad,
    error,
  };
};

export const useBasePost = () => {
  const [data, setData] = useState();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortController = useRef();
  const { apiHttpClient } = useApiContext();

  const dispatch = useDispatch();

  const basePost = useCallback((url, data, config = {}, postProccess) => {
    setLoading(true);
    setError(null);
    setStatus(null);

    if (abortController.current) {
      abortController.current.abort();
    }

    const thisCallAbortController = new AbortController();
    abortController.current = thisCallAbortController;

    apiHttpClient
      .post(url, data, {
        signal: abortController.current.signal,
        ...config,
      })
      .then((response) => {
        setStatus(response.status);
        setData(response.data);
      })
      .then(() => {
        if (postProccess) {
          postProccess();
        }
      })
      .catch((error) => {
        setStatus(error.status);
        setData(error.message);
        if (!axios.isCancel(error)) setError(error);
      })
      .finally(() => {
        if (
          abortController.current &&
          thisCallAbortController !== abortController.current
        ) {
          setLoading(false);
          return;
        }

        if (thisCallAbortController === abortController.current) {
          abortController.current = null;
        }

        setLoading(false);
      });
  }, []);

  return { basePost, data, loading, error, status };
};

export const useBaseDelete = () => {
  const abortController = useRef();
  const { noAuth = false, cacheConfig } = {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState();
  const { apiHttpClient } = useApiContext();

  const httpClient = noAuth ? unauthorizedApiHttpClient : apiHttpClient;

  const baseDelete = useCallback((url, postProccess) => {
    setLoading(true);
    setError(null);

    if (abortController.current) {
      abortController.current.abort();
    }

    const thisCallAbortController = new AbortController();
    abortController.current = thisCallAbortController;

    httpClient
      .delete(url, {
        signal: abortController.current.signal,
      })
      .then((response) => {
        if (cacheConfig) {
          dispatch(cacheSet(cacheConfig.cacheKey, response.data));
        }
        setData(response.data);
      })
      .then(() => {
        if (postProccess) {
          postProccess();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          if (cacheConfig?.cacheKey) cacheClean(cacheConfig.cacheKey);
          setError(error);
        }
      })
      .finally(() => {
        if (
          abortController.current &&
          thisCallAbortController !== abortController.current
        ) {
          setLoading(false);
          return;
        }

        if (thisCallAbortController === abortController.current) {
          abortController.current = null;
        }

        if (cacheConfig) {
          dispatch(cacheLoadingClean(cacheConfig.cacheKey));
        }

        setLoading(false);
      });
  }, []);

  return {
    baseDelete,
    loading,
    data,
    error,
  };
};

export const useBasePut = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortController = useRef();
  const { apiHttpClient } = useApiContext();

  const basePut = useCallback((url, data, config = {}) => {
    setLoading(true);
    setError(null);

    if (abortController.current) {
      abortController.current.abort();
    }

    const thisCallAbortController = new AbortController();
    abortController.current = thisCallAbortController;

    apiHttpClient
      .put(url, data, {
        signal: abortController.current.signal,
        ...config,
      })
      .then((response) => setData(response.data))
      .catch((error) => {
        if (!axios.isCancel(error)) setError(error);
      })
      .finally(() => {
        if (
          abortController.current &&
          thisCallAbortController !== abortController.current
        ) {
          setLoading(false);
          return;
        }

        if (thisCallAbortController === abortController.current) {
          abortController.current = null;
        }

        setLoading(false);
      });
  }, []);

  return { basePut, data, loading, error };
};

export const useBasePatch = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortController = useRef();
  const { apiHttpClient } = useApiContext();

  const dispatch = useDispatch();

  const basePatch = useCallback((url, data, config = {}, postProccess) => {
    setLoading(true);
    setError(null);

    if (abortController.current) {
      abortController.current.abort();
    }

    const thisCallAbortController = new AbortController();
    abortController.current = thisCallAbortController;

    apiHttpClient
      .patch(url, data, {
        signal: abortController.current.signal,
        ...config,
      })
      .then((response) => {
        setData(response.data);
      })
      .then(() => {
        if (postProccess) {
          postProccess();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) setError(error);
      })
      .finally(() => {
        if (
          abortController.current &&
          thisCallAbortController !== abortController.current
        ) {
          setLoading(false);
          return;
        }

        if (thisCallAbortController === abortController.current) {
          abortController.current = null;
        }

        setLoading(false);
      });
  }, []);

  return { basePatch, data, loading, error };
};
