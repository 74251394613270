import { useCallback, useContext, useEffect, useState } from 'react';
import { useBasePost } from '~/api/base';
import { isEmpty } from '@core/validators';
import { SEARCH_FIELDS } from '@core/search-fields';
import { FILTER_TYPES, SORT_DIRECTIONS } from '@core/constants';
import { useProgramStatusOptions } from '~/api/select';
import { SelectOptionsContext } from '~/api/selectContext';

const getFilters = (params) => {
  let filters = {};

  Object.keys(SEARCH_FIELDS).forEach((fieldId) => {
    if (!params[fieldId]) return;

    if (!SEARCH_FIELDS[fieldId].getFilter) return;

    filters = {
      ...filters,
      ...SEARCH_FIELDS[fieldId].getFilter(params),
    };
  });

  return filters;
};

const getSortingString = (sortedCols) => {
  if (isEmpty(sortedCols)) return undefined;

  return sortedCols
    .map((col) =>
      col.direction === SORT_DIRECTIONS.ASC
        ? col.columnName
        : `-${col.columnName}`
    )
    .join(',');
};

export const getSearchParams = (params = {}) => {
  const pageNum = params.pageNumber || 1;
  const pageSize = params.pageSize || 10;

  const sortString = getSortingString(params.sortedCols);

  const filter = getFilters(params);

  if (params.dataUsage) {
    filter.dataUsage = {
      [FILTER_TYPES.equal]: params.dataUsage ?? 'All',
    };
  }

  filter.active = { [FILTER_TYPES.in]: params.active ?? '1' };
  filter.NAME = { JOIN: 'OR' };

  return {
    filter,
    page: { number: pageNum, size: pageSize },
    sort: sortString,
  };
};

export const useProgramsTable = () => {
  const endpoint = '/program/search';

  const [pageSize, setPageSize] = useState({ value: 20, label: '20' });
  const [currentPage, setCurrentPage] = useState(1);
  const [nameVal, setNameVal] = useState(null);
  const [typeVal, setTypeVal] = useState(null);
  const [dataUsageVal, setDataUsageVal] = useState(null);
  const [statusVal, setStatusVal] = useState(null);

  const { dataUsage: dataUsageOptions } = useContext(SelectOptionsContext);

  const { progStatusOptions, getProgramStatusOptions } =
    useProgramStatusOptions();

  useEffect(() => {
    getProgramStatusOptions();
  }, []);

  useEffect(() => {
    let requestData = {
      pageNumber: currentPage,
      pageSize: pageSize.value,
    };
    if (nameVal?.length > 0) {
      requestData.searchQuery = nameVal;
    }
    if (typeVal?.value?.length > 0) {
      requestData.programType = typeVal.value;
    }
    if (dataUsageVal?.value?.length > 0) {
      requestData.dataUsage = dataUsageVal.value;
    }
    if (statusVal?.value) {
      requestData.active = statusVal.value;
    }
    requestData.sortedCols = [{ direction: 'ASC', columnName: 'name' }];
    getPrograms(requestData);
  }, [pageSize, currentPage, typeVal, nameVal, dataUsageVal, statusVal]);

  const { basePost, data, ...rest } = useBasePost();

  const getPrograms = useCallback((params) => {
    const { formParams, pageParams, ...restParams } = params;
    const searchParams = {
      ...restParams,
      ...formParams,
      ...pageParams,
    };
    const body = getSearchParams(searchParams);
    basePost(endpoint, body);
  }, []);

  return {
    dataUsageOptions,
    progStatusOptions,
    getPrograms,
    data: data?.data,
    meta: data?.meta,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    setNameVal,
    setTypeVal,
    setDataUsageVal,
    setStatusVal,
  };
};
