import { useEffect, useState } from 'react';
import { useAllCompanies } from '~/api/company';

export const useCompaniesTable = () => {
  const { getAllCompanies, data, meta, loading } = useAllCompanies();
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);
  const [nameVal, setNameVal] = useState(null);
  const [typeVal, setTypeVal] = useState(null);
  const [statusVal, setStatusVal] = useState(null);

  useEffect(() => {
    let params = {
      pageNumber: currentPage,
      pageSize: pageSize.value,
    };
    if (nameVal?.length > 0) {
      params.searchQuery = nameVal;
    }
    if (typeVal?.value?.length > 0) {
      params.searchType = typeVal.value;
    }
    if (statusVal?.value?.length > 0) {
      params.filters = `filter[status][eq]=${statusVal.value}`;
    }
    params.sortCol = 'name';
    params.sortDir = 'ASC';
    getAllCompanies(params);
  }, [pageSize, currentPage, nameVal, typeVal, statusVal]);

  return {
    data,
    meta,
    loading,
    setCurrentPage,
    currentPage,
    pageSize,
    setPageSize,
    setNameVal,
    setTypeVal,
    setStatusVal,
  };
};
