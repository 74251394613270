import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHoldingsTable } from './hooks';
import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import { Table as BsgTable } from '@backstop/grid-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ProgramHoldingsTable = () => {
  const progId = useParams().id;

  const {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useHoldingsTable(progId);
  if (loading) {
    return <Loader />;
  }

  return (
    <GenericTable
      rows={data}
      cols={{
        name: {
          value: 'name',
          label: 'Holding (Fund Name)',
          columnLabel: 'Holding (Fund Name)',
        },
        value: {
          value: 'value',
          label: 'Avg Holdings (%)',
          columnLabel: 'Avg Holdings (%)',
        },
        strategy: {
          value: 'strategy',
          label: 'Strategy',
          columnLabel: 'Strategy',
        },
        delete: { value: 'delete', label: '', columnLabel: '' },
      }}
      totalElements={meta?.totalElements}
      cellComponent={HoldingTableCell}
      pageSize={pageSize}
      currentPage={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      showPagination={true}
      isLoading={loading}
    />
  );
};

const HoldingTableCell = (props) => {
  if (props.column.name === 'name') {
    return (
      <BsgTable.Cell>
        <Link
          to={`/program/${props.row.progId}/composition/holding/${props.row.id}`}
        >
          {props.row.name}
        </Link>
      </BsgTable.Cell>
    );
  }
  if (props.column.name === 'delete') {
    return (
      <BsgTable.Cell>
        <Link
          to={`/program/${props.row.progId}/composition/holding/${props.row.id}/delete`}
        >
          <FontAwesomeIcon icon={'fa-trash'} />
        </Link>
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
