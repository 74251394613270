import { useDispatch } from 'react-redux';
import { getUrlWithPageParams } from './api-utils';
import { useBaseDelete, useBaseGet, useBasePost } from './base';
import { setTempUserTableRefreshStatus } from '@redux/actions/user-data-actions';

export const getTempAccessList = () => {
  const { baseGet, data, ...rest } = useBaseGet();
  const dispatch = useDispatch();

  const getTempAccessList = (params) => {
    let baseUrl = getUrlWithPageParams(`/api/auth/user/tempAccess`, params);
    if (params.checked) {
      baseUrl = baseUrl + '&showExpired=true';
    }
    baseGet(baseUrl, {}, () =>
      dispatch(setTempUserTableRefreshStatus('loaded'))
    );
  };

  return {
    getTempAccessList,
    data: data?.data,
    meta: data?.meta,
    ...rest,
  };
};

export const createTempAccessEntry = () => {
  const { basePost, data, error, loading } = useBasePost();
  const dispatch = useDispatch();

  const createTempAccessEntry = (data) => {
    basePost(
      `/api/auth/user/tempAccess`,
      {
        ...data,
      },
      {},
      () => dispatch(setTempUserTableRefreshStatus('refresh'))
    );
  };

  return {
    createTempAccessEntry,
    data,
    error,
    loading,
  };
};

export const removeTempAccessEntry = () => {
  const { baseDelete, data, loading, error } = useBaseDelete();
  const dispatch = useDispatch();

  const deleteTempAccessEntry = (data) => {
    baseDelete(`/api/auth/user/tempAccess/` + data.id, () =>
      dispatch(setTempUserTableRefreshStatus('refresh'))
    );
  };
  return {
    deleteTempAccessEntry,
    data,
    error,
    loading,
  };
};

export const getFullAccessList = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getFullAccessList = () => {
    baseGet(`/api/auth/user/fullAccess`);
  };

  return {
    getFullAccessList,
    data: data?.data,
    ...rest,
  };
};
