import { useEffect, useState } from 'react';
import { useProgramHistory } from '~/api/program';

export const useProgramHistoryTable = (progId) => {
  const { getHistory, data, loading, meta } = useProgramHistory(progId);
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getHistory({ pageNumber: currentPage, pageSize: pageSize.value });
  }, [pageSize, currentPage]);

  return {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  };
};
