import { convertSelectInputsToValues } from '@common/form-inputs/constants';
import { setCompContactDataState } from '@redux/actions/company-actions';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useBaseDelete, useBasePatch, useBasePost } from '~/api/base';
import { useCompanyContacts } from '~/api/company';
import { SelectOptionsContext } from '~/api/selectContext';

export const useCompanyContactForm = (compId) => {
  const { basePost, data: postData, loading: postLoading } = useBasePost();
  const { basePatch, data: patchData, loading: patchLoad } = useBasePatch();
  const { baseDelete } = useBaseDelete();
  const { getOneContact, getData } = useCompanyContacts(compId);
  const dispatch = useDispatch();

  const sendContactToTopSequence = (contactId) => {
    basePatch(`/company/${compId}/contacts/${contactId}/sequence`, {}, {}, () =>
      dispatch(setCompContactDataState('refresh'))
    );
  };

  const createCompContact = (contactData) => {
    basePost(
      `/company/${compId}/contacts`,
      {
        data: convertSelectInputsToValues(contactData),
      },
      {},
      () => dispatch(setCompContactDataState('refresh'))
    );
  };

  const updateCompContact = (contactData) => {
    basePatch(
      `/company/${compId}/contacts/${contactData.id}`,
      {
        data: convertSelectInputsToValues(contactData),
      },
      {},
      () => dispatch(setCompContactDataState('refresh'))
    );
  };

  const deleteCompContact = (contactId) => {
    baseDelete(`/company/${compId}/contacts/${contactId}`, () =>
      dispatch(setCompContactDataState('refresh'))
    );
  };

  const {
    positionOptions,
    educationOptions,
    ageOptions: ageRangeOptions,
  } = useContext(SelectOptionsContext);

  const isLoadingSelect = () => {
    return !positionOptions || !educationOptions || !ageRangeOptions;
  };

  return {
    sendContactToTopSequence,
    deleteCompContact,
    updateCompContact,
    createCompContact,
    positionOptions,
    educationOptions,
    ageRangeOptions,
    isLoadingSelect,
    getOneContact,
    getData,
  };
};
