import React from 'react';
import { createContext } from 'react';
import {
  useActiveOptions,
  useAgeOptions,
  useBenchmarkOptions,
  useCategories,
  useCategoryHelpList,
  useCategoryStyleOptions,
  useCountryOptions,
  useDataUsage,
  useDayOptions,
  useDenominationMumOptions,
  useDenominationOptions,
  useEducationOptions,
  useExchangeListOptions,
  useFreeDatabaseSampleOptions,
  useFrequencyOptions,
  useInvestorTypeOptions,
  useLocalesOptions,
  usePositionOptions,
  usePrimaryCategoryConversion,
  useProfessionalFieldOptions,
  useRedemptions,
  useStateOptions,
  useStrategyOptions,
  useSubscriptionFrequencyOptions,
  useSubscriptions,
  useUsOptions,
} from './select';

export const SelectOptionsContext = createContext();

export const SelectOptionsContextProvider = ({ children }) => {
  const { activeOptions } = useActiveOptions();
  const { benchmarkOptions } = useBenchmarkOptions();
  const { categories } = useCategories();
  const { categoryHelpList } = useCategoryHelpList();
  const { categoryStyles } = useCategoryStyleOptions();
  const { countryOptions } = useCountryOptions();
  const { dataUsage } = useDataUsage();
  const { dayOptions } = useDayOptions();
  const { denominationOptions } = useDenominationOptions();
  const { denominationMumOptions } = useDenominationMumOptions();
  const { exchangeListOptions } = useExchangeListOptions();
  const { freeDatabaseSampleOptions } = useFreeDatabaseSampleOptions();
  const { frequencyOptions } = useFrequencyOptions();
  const { investorTypeOptions } = useInvestorTypeOptions();
  const { localesOptions } = useLocalesOptions();
  const { positionOptions } = usePositionOptions();
  const { ageOptions } = useAgeOptions();
  const { educationOptions } = useEducationOptions();
  const { primaryCategoryConversion } = usePrimaryCategoryConversion();
  const { professionalFieldOptions } = useProfessionalFieldOptions();
  const { redemptions } = useRedemptions();
  const { stateOptions } = useStateOptions();
  const { strategyOptions } = useStrategyOptions();
  const { subscriptions } = useSubscriptions();
  const { subscriptionFrequencyOptions } = useSubscriptionFrequencyOptions();
  const { usOptions } = useUsOptions();

  return (
    <SelectOptionsContext.Provider
      value={{
        activeOptions,
        ageOptions,
        benchmarkOptions,
        categories,
        categoryHelpList,
        categoryStyles,
        countryOptions,
        dataUsage,
        dayOptions,
        denominationOptions,
        denominationMumOptions,
        exchangeListOptions,
        freeDatabaseSampleOptions,
        frequencyOptions,
        investorTypeOptions,
        localesOptions,
        positionOptions,
        primaryCategoryConversion,
        professionalFieldOptions,
        educationOptions,
        redemptions,
        stateOptions,
        strategyOptions,
        subscriptions,
        subscriptionFrequencyOptions,
        usOptions,
      }}
    >
      {children}
    </SelectOptionsContext.Provider>
  );
};
