import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useInstrumentsTable } from './hooks';
import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import { Table as BsgTable } from '@backstop/grid-view';
import { Icon } from '@backstop/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ProgramInstrumentsTable = () => {
  const progId = useParams().id;
  const {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useInstrumentsTable(progId);
  if (loading) {
    return <Loader />;
  }
  return (
    <GenericTable
      rows={data}
      cols={{
        name: { value: 'name', label: 'Instrument', columnLabel: 'Instrument' },
        value: {
          value: 'value',
          label: 'Avg Holdings (%)',
          columnLabel: 'Avg Holdings (%)',
        },
        delete: { value: 'delete', label: '', columnLabel: '' },
      }}
      totalElements={meta?.totalElements}
      cellComponent={InstrumentTableCell}
      pageSize={pageSize}
      currentPage={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      showPagination={true}
      isLoading={loading}
    />
  );
};

const InstrumentTableCell = (props) => {
  if (props.column.name === 'name') {
    return (
      <BsgTable.Cell>
        <Link
          to={`/program/${props.row.progId}/composition/instrument/${props.row.id}`}
        >
          {props.row.name}
        </Link>
      </BsgTable.Cell>
    );
  }
  if (props.column.name === 'value') {
    let valText = Number(props.row.value);
    if (valText === -2) {
      valText = 'Yes';
    }
    if (valText === -1) {
      valText = 'N/A';
    }
    return <BsgTable.Cell>{valText}</BsgTable.Cell>;
  }
  if (props.column.name === 'delete') {
    return (
      <BsgTable.Cell>
        <Link
          to={`/program/${props.row.progId}/composition/instrument/${props.row.id}/delete`}
        >
          <FontAwesomeIcon icon={'fa-trash'} />
        </Link>
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
