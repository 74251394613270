import { useContext } from 'react';
import { SelectOptionsContext } from '~/api/selectContext';

export const useProgramDetails = () => {
  const {
    usOptions,
    localesOptions,
    subscriptions: subscriptionOptions,
    investorTypeOptions,
    exchangeListOptions,
    denominationOptions: denominations,
    redemptions: redemptionOptions,
    dayOptions,
  } = useContext(SelectOptionsContext);

  const isLoadingSelect = () => {
    return (
      !usOptions ||
      !localesOptions ||
      !subscriptionOptions ||
      !investorTypeOptions ||
      !exchangeListOptions ||
      !denominations ||
      !redemptionOptions ||
      !dayOptions
    );
  };

  return {
    isLoadingSelect,
    usOptions,
    localesOptions,
    subscriptionOptions,
    investorTypeOptions,
    exchangeListOptions,
    dayOptions,
    denominations,
    redemptionOptions,
  };
};
