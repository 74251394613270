import { USER_ROLES } from '@core/user';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({
  allowedRoles = [USER_ROLES.superUser, USER_ROLES.sales],
  redirectPath = '/',
  children,
}) => {
  const { roles } = useSelector((state) => state.userData);

  if (roles.some((userRole) => allowedRoles.includes(userRole))) {
    return children;
  }

  return <Navigate to={redirectPath} replace />;
};
