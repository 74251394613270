import React from 'react';
import { ProgramPortfolioComposition } from './program-portfolio-composition';
import { ProgramMultipleAdvisor } from './program-multiple-advisor';
import { ProgramTimeFrameAllocation } from './program-time-frame-allocation';
import { ProgramCtaTradingParameters } from './program-cta-trading-parameters';
import { ProgramCtaCategories } from './program-cta-categories';

export const ProgramPortfolioCompositionContainer = (props) => {
  return (
    <>
      <ProgramPortfolioComposition {...props} />
      <ProgramMultipleAdvisor {...props} />
      <ProgramTimeFrameAllocation {...props} />
      <ProgramCtaTradingParameters {...props} />
      <ProgramCtaCategories {...props} />
    </>
  );
};
