import { Modal } from '@backstop/react-core';
import React from 'react';
import { TrackRecordForm } from './track-record-form';

export const TrackRecordFormModal = () => {
  return (
    <Modal version="v3" open={true} footerVisible={false}>
      <TrackRecordForm />
    </Modal>
  );
};
