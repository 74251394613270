import React from 'react';
import { DatePicker } from '@backstop/react-core';
import { FormControl } from '@common/form-control';
import { Controller } from 'react-hook-form';

export const FormDatePicker = ({
  name,
  label,
  tooltipText,
  control,
  defaultValue,
  rules,
  view,
  views,
  placeholder = '',
  formControlClassName,
  required,
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={true}
      shouldFocus={true}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        const errorList = error ? [error.message] : null;
        return (
          <FormControl
            label={label}
            errors={errorList}
            tooltipText={tooltipText}
            className={formControlClassName}
            required={required}
          >
            <DatePicker
              version="v3"
              onChange={onChange}
              defaultView={view || 'month'}
              views={views || ['month', 'year', 'decade', 'century']}
              value={String(value || '')}
              required={required}
              {...restProps}
            />
          </FormControl>
        );
      }}
    />
  );
};
