import React, { useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { useProgramData } from '../../hooks';
import { saveProgramData } from '~/api/program';
import { useForm } from 'react-hook-form';
import { Loader } from '@common/loader';
import {
  FormCheckbox,
  FormContainer,
  FormSelectInput,
} from '@common/form-inputs';
import { useProgramTrackRecord } from './hooks';
import { PROGRAM_TYPES } from '@core/constants';
import { UploadTrackData } from './upload';
import { BasicTable } from '@common/basic-table';
import { TrackRecordTable } from './table';
import { useLayoutForm } from '@core/layout/layout-form';
import { ProgramTrackRecordYtdChart } from './chart';
import { Button } from '@backstop/react-core';
import { ExportTrackRecord } from './download';
import {
  ErrorMessage,
  SuccessMessage,
  UnsavedChangesMessage,
} from '@common/generic-message';
import { useTrackRecordTable } from './table/hooks';
import {
  getNextDate,
  jsDateToString,
  stringToJsDate,
} from '~/api/helperFunctions';
import { requiredRule } from '@common/form-inputs/constants';

export const ProgramTrackRecord = () => {
  const programId = useParams().id;
  const { data } = useProgramData(programId);
  const { rowStyles, colStyle } = useLayoutForm();
  const { saveProgram, patchData, patchError } = saveProgramData();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm();
  const navigate = useNavigate();

  const { denominationMumOptions, loading } = useProgramTrackRecord();
  const { data: tableData, currentPage } = useTrackRecordTable(programId);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = (data) => {
    const tempProg = {
      ...data,
    };
    if (programId != undefined) {
      tempProg.id = programId;
    }
    saveProgram(tempProg);
    reset(tempProg);
  };

  if (!data || loading) {
    return <Loader />;
  }

  let shouldShowCreate = () => {
    if (tableData !== undefined && currentPage === 1) {
      let jsDate = stringToJsDate(tableData?.[0]?.date, 'YYYY-MM-DD', '-');
      let now = new Date();
      let attemptedDate = getNextDate(jsDate);

      let dateLimit = new Date(now.getFullYear(), now.getMonth(), 1);
      if (attemptedDate < dateLimit) {
        return true;
      }
    }
    return false;
  };

  let shouldShowDelete = () => {
    if (tableData !== undefined && currentPage == 1) {
      let jsDate = stringToJsDate(tableData?.[0]?.date, 'YYYY-MM-DD', '-');
      let now = new Date();

      let dateLimit = new Date(now.getFullYear(), now.getMonth() - 2, 1);
      if (jsDate > dateLimit) {
        return true;
      }
    }
    return false;
  };

  const fields = {
    compound: (
      <FormCheckbox
        name="vamiNotCompound"
        label="Do Not Compound"
        control={control}
        defaultValue={data.vamiNotCompound === '1'}
      />
    ),
    denom: (
      <FormSelectInput
        name="denominatMum"
        label="Fund AUM Denomination"
        options={denominationMumOptions}
        defaultValue={denominationMumOptions.find(
          (x) => x.value === data.denominatMum
        )}
        rules={{ ...requiredRule }}
        required={true}
        control={control}
      />
    ),
    strat: (
      <FormCheckbox
        name="strategyAssets"
        label="Strategy Assets?"
        control={control}
        defaultValue={data.strategyAssets === '1'}
      />
    ),
  };

  return (
    <>
      <UnsavedChangesMessage trigger={isDirty} />
      <ErrorMessage trigger={patchError} />
      <SuccessMessage trigger={patchData} />
      <Outlet />
      <FormContainer>
        <div className={rowStyles}>
          <div className={colStyle}>
            <BasicTable>
              <BasicTable.Row>
                <BasicTable.Header>Start Date</BasicTable.Header>
                <BasicTable.Cell>{data.dynamicStartDate}</BasicTable.Cell>
              </BasicTable.Row>
              <BasicTable.Row>
                <BasicTable.Header>End Date</BasicTable.Header>
                <BasicTable.Cell>{data.dynamicEndDate}</BasicTable.Cell>
              </BasicTable.Row>
            </BasicTable>
          </div>
          <div className={colStyle}>
            <ProgramTrackRecordYtdChart />
          </div>

          <div className={colStyle}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={rowStyles}>
                <div className={colStyle}>{fields.compound}</div>
                <div className={colStyle}>{fields.denom}</div>
                <div className={colStyle}>
                  {data?.type === PROGRAM_TYPES.CTA && <>{fields.strat}</>}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="submit" kind="primary" version="v3">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
          }}
        >
          <UploadTrackData />
          <ExportTrackRecord />
          {shouldShowCreate() ? (
            <Button
              version="v3"
              style={{ margin: '1rem' }}
              onClick={() =>
                navigate(`/program/${programId}/trackRecord/new`, {
                  state: {
                    data: {
                      date: jsDateToString(
                        getNextDate(
                          stringToJsDate(
                            tableData?.[0]?.date,
                            'YYYY-MM-DD',
                            '-'
                          )
                        ),
                        'YYYY-MM-DD'
                      ),
                    },
                  },
                })
              }
            >
              Add New
            </Button>
          ) : null}
          {shouldShowDelete() ? (
            <Button
              version="v3"
              style={{ margin: '1rem' }}
              onClick={() => {
                navigate(
                  `/program/${programId}/trackRecord/${encodeURIComponent(
                    tableData?.[0]?.date
                  )}/delete`
                );
              }}
            >
              Delete Last
            </Button>
          ) : null}
        </div>
      </FormContainer>

      <TrackRecordTable />
    </>
  );
};
