import * as types from './action-types';

export function setCompContactDataState(state) {
  return {
    type: types.SET_COMP_CONTACT_DATA_REFRESH_STATE,
    state,
  };
}

export function setCompClientBaseDataState(state) {
  return {
    type: types.SET_COMP_CLIENT_BASE_REFRESH_STATE,
    state,
  };
}

export function setCompNotesDataState(state) {
  return {
    type: types.SET_COMP_NOTES_REFRESH_STATE,
    state,
  };
}

export function setCompManagedFundsDataState(state) {
  return {
    type: types.SET_MANAGED_FUNDS_DATA_REFRESH_STATE,
    state,
  };
}
