import { useProgramTrackRecordUpload } from '~/api/program';

export const useUploadTrackData = () => {
  const {
    uploadTrackRecordPatch,
    uploadTrackRecordPost,
    validateUploadFile,
    postData,
    postStatus,
  } = useProgramTrackRecordUpload();

  return {
    uploadTrackRecordPatch,
    uploadTrackRecordPost,
    validateUploadFile,
    postData,
    postStatus,
  };
};
