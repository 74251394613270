import {
  COMP_LOCATION_OPTIONS,
  CTA_STRATEGY_OPTIONS,
  FORM_INCLUSION_OPTIONS,
  GEO_FOCUS_OPTIONS,
  HEDGE_FUND_STRATEGY_OPTIONS,
  HIGH_WATER_MARK_OPTIONS,
  OPEN_CLOSED_OPTIONS,
  REPORTING_BASIS_OPTIONS,
  useDenominationOptions,
  useExchangeListOptions,
  useUsOptions,
} from '@core/select-options';
import { isEmpty } from '@core/validators';
import { PROGRAM_TYPE_LABELS_FULL } from '@core/constants';
import { getFlatList } from '@common/multiselect-dropdown';

const getMultiselectLabels = (ids, options) => {
  const flatList = getFlatList(options);
  const selectedOptions = ids.map((id) =>
    flatList.find((option) => option.id === id)
  );
  return selectedOptions.map((opt) => opt?.optionLabel);
};

const getLabel = (id, options) => {
  return options.find((option) => option.value === id)?.label;
};

export const FORMATTER_HOOKS = {
  all: function () {
    return {
      label: 'Remove Filters',
    };
  },

  basicFiltersInclusion: function (params) {
    return {
      label: this.label,
      value: getLabel(params[this.id], FORM_INCLUSION_OPTIONS),
    };
  },

  detailedFiltersInclusion: function (params) {
    return {
      label: this.label,
      value: getLabel(params[this.id], FORM_INCLUSION_OPTIONS),
    };
  },

  customFiltersInclusion: function (params) {
    return {
      label: this.label,
      value: getLabel(params[this.id], FORM_INCLUSION_OPTIONS),
    };
  },

  // Search by Type

  programType: function (params) {
    return {
      label: this.label,
      value: PROGRAM_TYPE_LABELS_FULL[params[this.id]],
    };
  },

  // Search by Name

  searchQuery: function (params) {
    return {
      label: this.label,
      value: params[this.id],
    };
  },

  // Basic Filters

  compState: function (params) {
    const labels = getMultiselectLabels(params[this.id], COMP_LOCATION_OPTIONS);
    return {
      label: this.label,
      value: labels.join(', '),
    };
  },

  us: function (params) {
    const { usOptions } = useUsOptions();
    const labels = getMultiselectLabels(params[this.id], usOptions);
    return {
      label: this.label,
      value: labels.join(', '),
    };
  },

  // Strategy Filters

  ctaStrategies: function (params) {
    const labels = getMultiselectLabels(params[this.id], CTA_STRATEGY_OPTIONS);
    const matchType = params.ctaInclusion === 'AND' ? 'Match All' : 'Match Any';

    return {
      label: `${this.label} (${matchType})`,
      value: labels.join(', '),
    };
  },

  excludeProprietaryTradingAccounts: function () {
    return {
      label: this.label,
      value: this.checkboxLabel,
    };
  },

  hfStrategies: function (params) {
    const labels = getMultiselectLabels(
      params[this.id],
      HEDGE_FUND_STRATEGY_OPTIONS
    );
    const matchType =
      params.hfInclusion === 'PRIMARY'
        ? 'Match Primary'
        : params.hfInclusion === 'AND'
        ? 'Match All'
        : 'Match Any';

    return {
      label: `${this.label} (${matchType})`,
      value: labels.join(', '),
    };
  },

  excludeManagedAccounts: function () {
    return {
      label: this.label,
      value: this.checkboxLabel,
    };
  },

  sec13f: function () {
    return {
      label: this.label,
      value: this.checkboxLabel,
    };
  },

  // Detailed Filters

  openClosed: function (params) {
    const label = getLabel(params[this.id], OPEN_CLOSED_OPTIONS);
    return {
      label: this.label,
      value: label,
    };
  },

  listedOnExchange: function (params) {
    const { exchangeListOptions } = useExchangeListOptions();
    const labels = getMultiselectLabels(params[this.id], exchangeListOptions);
    return {
      label: this.label,
      value: labels.join(', '),
    };
  },

  highwater: function (params) {
    const label = getLabel(params[this.id], HIGH_WATER_MARK_OPTIONS);
    return {
      label: this.label,
      value: label,
    };
  },

  denomination: function (params) {
    const { denominations } = useDenominationOptions();
    const labels = getMultiselectLabels(params[this.id], denominations);
    return {
      label: this.label,
      value: labels.join(', '),
    };
  },

  geoFocus: function (params) {
    const labels = getMultiselectLabels(params[this.id], GEO_FOCUS_OPTIONS);
    return {
      label: this.label,
      value: labels.join(', '),
    };
  },

  reportingBasis: function (params) {
    const labels = getMultiselectLabels(
      params[this.id],
      REPORTING_BASIS_OPTIONS
    );
    return {
      label: this.label,
      value: labels.join(', '),
    };
  },

  // Custom filters

  customFilters: (filter) => {
    const filter1Label = filter.filter1.fullLabel || filter.filter1.label;
    const comparisonMethodLabel = filter.comparisonMethod.label;
    const { value1, value2 } = filter;

    let label = '';

    if (filter.filter1.vs) {
      const filter2Label = filter.filter2.fullLabel || filter.filter2.label;

      label += `${filter1Label} vs ${filter2Label}`;
    } else {
      label += filter1Label;
    }

    let value = `${comparisonMethodLabel} `;

    if (!isEmpty(value2)) {
      value += `${value1} and ${value2}`;
    } else {
      value += value1;
    }

    return { label, value };
  },
};
