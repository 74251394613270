import { setProgramDataState } from '@redux/actions/program-actions';
import { useDispatch } from 'react-redux';
import { useBasePatch } from '~/api/base';

export const useProgramChangeComp = (progId) => {
  const { basePatch } = useBasePatch();
  const dispatch = useDispatch();

  const changeCompany = (compId) => {
    basePatch(
      `/program/${progId}/changeCompany`,
      {
        data: { compId: compId },
      },
      undefined,
      () => dispatch(setProgramDataState('refresh'))
    );
  };

  return { changeCompany };
};
