import React from 'react';
import { CompanyFundsTable } from './table';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@backstop/react-core';
import { useCompanyData } from '../../hooks';

export const CompanyFunds = () => {
  const navigate = useNavigate();
  const {id: companyId} = useParams();
  const { data } = useCompanyData(companyId);
  if (data) {
    data.compId = companyId;
  }

  return (
    <>
      <Button
        version="v3"
        kind="primary"
        onClick={() => navigate(`/program/new`, { state: data })}
      >
        Create Program+
      </Button>
      <CompanyFundsTable />
    </>
  );
};
