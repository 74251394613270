import { useCallback, useMemo, useState, useEffect } from 'react';
import { useBaseGet } from './base';

export const useProgramStatusOptions = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getProgramStatusOptions = (progId) => {
    baseGet(`/select/programStatus/${progId ? progId : ''}`);
  };

  const progStatusOptions = useMemo(() => {
    if (!data) return [];
    const options = data.data.map((item) => ({
      value: item.value,
      label: item.label || 'None',
    }));
    return options;
  }, [data]);

  return { getProgramStatusOptions, progStatusOptions, ...rest };
};

export const useCompanyStatusOptions = (compId) => {
  const { baseGet, data, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'compStatus',
    },
  });

  const getCompStatusOptions = useCallback(
    () => baseGet(`/select/companyStatus/${compId ? compId : ''}`),
    []
  );

  const compStatusOptions = useMemo(() => {
    if (!data) return [];
    const options = data.data.map((item) => ({
      value: item.value,
      label: item.label || 'None',
    }));

    return options;
  }, [data]);

  return { getCompStatusOptions, compStatusOptions, ...rest };
};

/**
 * Get awards sector options based on isBmfr, type, and date
 * @returns
 */
export const useAwardSectorOptions = () => {
  const { baseGet, data, loading, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'sectors',
    },
  });
  const getAwardSectorOptions = useCallback((isYearly, isBmfr, type, date) => {
    baseGet(
      `/select/sectorOptions?isYearly=${isYearly}&isBmfr=${isBmfr}&type=${type}&date=${date}`
    );
  }, []);
  return {
    getAwardSectorOptions,
    loading,
    sectors: data?.data || [],
    ...rest,
  };
};

// Common data modification function
const commonDataModification = (data) => {
  if (!data) return [];
  return data.map((item) => ({
    value: item.value,
    label: item.label || 'None',
  }));
};

/**
 * Higher-order hook to generate hooks for getting select options
 * @param {string} optionName - The name of the option
 * @param {string} requestActionType - The action type to dispatch on request
 * @param {string} successActionType - The action type to dispatch on success
 * @param {Function} modifyDataFunc - Function to modify the data
 * @param {string} url - The URL to fetch the data from
 * @returns {Function} - The generated hook
 */
const createUseSelectOptionsHook = (
  optionName,
  modifyDataFunc = commonDataModification,
  url
) => {
  return () => {
    const { baseGet, ...rest } = useBaseGet();
    const [value, setValue] = useState();

    useEffect(() => {
      if (rest?.data?.data && rest?.data?.data != value) {
        setValue(modifyDataFunc(rest?.data?.data));
      }
    }, [rest?.data?.data]);

    const fetchData = useCallback(() => {
      baseGet(url);
    }, [baseGet, url]);

    useEffect(() => {
      if (!value) {
        fetchData();
      }
    }, [fetchData, value]);

    return {
      [optionName]: value,
    };
  };
};

export const useActiveOptions = createUseSelectOptionsHook(
  'activeOptions',
  commonDataModification,
  '/select/activeOptions'
);

export const useBenchmarkOptions = createUseSelectOptionsHook(
  'benchmarkOptions',
  commonDataModification,
  '/select/benchmarks'
);

export const useCategories = createUseSelectOptionsHook(
  'categories',
  commonDataModification,
  '/select/categories'
);

export const useCategoryHelpList = createUseSelectOptionsHook(
  'categoryHelpList',
  commonDataModification,
  '/select/categoryHelp'
);

export const useCategoryStyleOptions = createUseSelectOptionsHook(
  'categoryStyles',
  commonDataModification,
  '/select/catStyles'
);

export const useCountryOptions = createUseSelectOptionsHook(
  'countryOptions',
  commonDataModification,
  '/select/countryList'
);

export const useDataUsage = createUseSelectOptionsHook(
  'dataUsage',
  (data) => {
    if (!data) return [];
    return data.map((item) => ({
      value: item.usage,
      label: item.usage || 'None',
    }));
  },
  '/select/dataUsages'
);

export const useDayOptions = createUseSelectOptionsHook(
  'dayOptions',
  commonDataModification,
  '/select/dayList'
);

export const useDenominationOptions = createUseSelectOptionsHook(
  'denominationOptions',
  (data) => {
    return data.map((item) => ({
      value: item.value,
      label: `${item.value} - ${item.label}`,
    }));
  },
  '/select/denomination'
);

export const useDenominationMumOptions = createUseSelectOptionsHook(
  'denominationMumOptions',
  commonDataModification,
  '/select/mumDenomination'
);

export const useExchangeListOptions = createUseSelectOptionsHook(
  'exchangeListOptions',
  (data) => {
    if (!data) return [];
    let options = data.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    options.sort((a, b) => {
      if (a.id === 'Not Listed' && b.id === 'Other') return 1;
      if (a.id === 'Other' && b.id === 'Not Listed') return -1;
      if (a.id === 'Not Listed' || a.id === 'Other') return 1;
      if (b.id === 'Not Listed' || b.id === 'Other') return -1;
      return 0;
    });

    return options;
  },
  '/select/exchangeList'
);

export const useFreeDatabaseSampleOptions = createUseSelectOptionsHook(
  'dbSampleOptions',
  commonDataModification,
  '/select/dbSample'
);

export const useFrequencyOptions = createUseSelectOptionsHook(
  'frequencyOptions',
  commonDataModification,
  '/select/frequency'
);

export const useInvestorTypeOptions = createUseSelectOptionsHook(
  'investorTypeOptions',
  commonDataModification,
  '/select/investorType'
);

export const useLocalesOptions = createUseSelectOptionsHook(
  'localesOptions',
  (data) => {
    if (!data) return [];
    const options = data.reduce((result, element) => {
      if (element.label) {
        result.push({
          value: element.value,
          label: element.label,
        });
      }
      return result;
    }, []);

    return options;
  },
  '/select/locales'
);

export const usePositionOptions = createUseSelectOptionsHook(
  'positionOptions',
  (data) => {
    if (!data) return [];
    return data.map((item) => ({
      value: item.name,
      label: item.name || 'None',
    }));
  },
  '/select/positions'
);

export const useAgeOptions = createUseSelectOptionsHook(
  'ageOptions',
  commonDataModification,
  '/select/ageRangeOptions'
);

export const useEducationOptions = createUseSelectOptionsHook(
  'educationOptions',
  commonDataModification,
  '/select/educationOptions'
);

export const usePrimaryCategoryConversion = createUseSelectOptionsHook(
  'primaryCategoryConversion',
  (data) => {
    return data?.data;
  },
  '/select/primaryCatConvert'
);

export const useProfessionalFieldOptions = createUseSelectOptionsHook(
  'professionalFieldOptions',
  commonDataModification,
  '/select/professional'
);

export const useRedemptions = createUseSelectOptionsHook(
  'redemptions',
  commonDataModification,
  '/select/redemptions'
);

export const useStateOptions = createUseSelectOptionsHook(
  'stateOptions',
  commonDataModification,
  '/select/stateList'
);

export const useStrategyOptions = createUseSelectOptionsHook(
  'strategyOptions',
  (data) => {
    if (!data) return [];
    return data.map((item) => ({
      value: item.strategy,
      label: item.strategy || 'None',
      cta: item.cta,
      hf: item.hf,
    }));
  },
  '/select/strategy'
);

export const useSubscriptions = createUseSelectOptionsHook(
  'subscriptions',
  commonDataModification,
  '/select/subscriptions'
);

export const useSubscriptionFrequencyOptions = createUseSelectOptionsHook(
  'subscriptionFrequencyOptions',
  commonDataModification,
  '/select/subFrequency'
);

export const useUsOptions = createUseSelectOptionsHook(
  'usOptions',
  (data) => {
    if (!data) return [];
    const options = data.reduce((result, element) => {
      if (element.label) {
        result.push({
          value: element.value,
          label: element.label,
        });
      }
      return result;
    }, []);

    options.sort((a, b) => {
      if (a.id === '') return 1;
      if (b.id === '') return -1;
      return 0;
    });

    return options;
  },
  '/select/us'
);
