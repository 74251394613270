import React, { useEffect, useState } from 'react';
import { InlineMessageBlock } from '@backstop/react-core';
import PropTypes from 'prop-types';

export const Message = ({ kind, message, trigger, timerAmount }) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (trigger) {
      setShowMessage(true);
      if (timerAmount) {
        setTimeout(() => {
          setShowMessage(false);
        }, timerAmount);
      }
    } else {
      setShowMessage(false);
    }
  }, [trigger]);

  return (
    showMessage && (
      <div style={{ position: 'sticky', top: '20px', zIndex: 21 }}>
        <InlineMessageBlock version="v3" kind={kind}>
          {message}
        </InlineMessageBlock>
      </div>
    )
  );
};

Message.propTypes = {
  kind: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  timerAmount: PropTypes.number,
};
