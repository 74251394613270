import { useEffect } from 'react';
import api from '@api';
import { useBasePost } from '~/api/base';
import { useDispatch, useSelector } from 'react-redux';
import { setProgramDataState } from '@redux/actions/program-actions';

export const useProgramNotes = (id) => {
  const { getOneProgram, data, loading, error } = api.program.useOneProgram();
  const dispatch = useDispatch();
  const programDataState = useSelector(
    (state) => state.program.programDataState
  );

  const { basePost } = useBasePost();

  const addNewAdminNote = (adminNote) => {
    basePost(
      `/program/${id}/adminNote`,
      { data: { adminNotes: adminNote } },
      {},
      () => dispatch(setProgramDataState('refresh'))
    );
  };

  useEffect(() => {
    getOneProgram(id);
  }, [id]);

  useEffect(() => {
    if (programDataState === 'refresh') {
      getOneProgram(id);
    }
    dispatch(setProgramDataState('loaded'));
  }, [programDataState]);

  return { data, loading, error, addNewAdminNote };
};
