import React from 'react';
import { Modal } from '@backstop/react-core';
import { ClientBaseForm } from './client-base-form';

export const ClientBaseModal = () => {
  return (
    <Modal version="v3" open={true} footerVisible={false}>
      <ClientBaseForm />
    </Modal>
  );
};
