import React from 'react';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import {
  FormContainer,
  FormSelectInput,
  FormTextInput,
} from '@common/form-inputs';
import { requiredRule } from '@common/form-inputs/constants';
import { useProgramAdministrative } from './hooks';
import { Loader } from '@common/loader';

export const ProgramAdministration = ({ data, control }) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { stateOptions, countryOptions, isLoadingSelect } =
    useProgramAdministrative();

  if (isLoadingSelect()) {
    return <Loader />;
  }

  const fields = {
    firm: (
      <FormTextInput
        control={control}
        name="adminFirm"
        label="Firm"
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    address1: (
      <FormTextInput control={control} name="adminAddress1" label="Address" />
    ),
    country: (
      <FormSelectInput
        name="adminCountry"
        label="Country"
        control={control}
        options={countryOptions}
        defaultValue={countryOptions.find((x) => x.value === data.adminCountry)}
      />
    ),
    contact: (
      <FormTextInput control={control} name="adminContact" label="Contact" />
    ),
    address2: (
      <FormTextInput
        control={control}
        label="&nbsp;"
        name="adminAddress2"
        placeholder="Address"
      />
    ),
    zip: <FormTextInput control={control} name="adminZip" label="Zip" />,
    phone: (
      <FormTextInput control={control} name="adminContactPhone" label="Phone" />
    ),
    city: <FormTextInput control={control} name="adminCity" label="City" />,
    email: (
      <FormTextInput control={control} name="adminContactEmail" label="Email" />
    ),
    state: (
      <FormSelectInput
        name="adminState"
        label="State"
        control={control}
        options={stateOptions}
        defaultValue={stateOptions.find((x) => x.value === data.adminState)}
      />
    ),
  };

  return (
    <FormContainer title="Administration">
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.firm}
          {fields.address1}
          {fields.address2}
        </div>
        <div className={colStyle}>
          {fields.contact}
          {fields.country}
          {fields.zip}
        </div>
        <div className={colStyle}>
          {fields.phone}
          {fields.city}
        </div>
        <div className={colStyle}>
          {fields.email}
          {fields.state}
        </div>
      </div>
    </FormContainer>
  );
};

ProgramAdministration.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
