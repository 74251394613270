import {
  FormContainer,
  FormSelectInput,
  FormTextInput,
} from '@common/form-inputs';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import React, { useEffect, useState } from 'react';
import { get, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useHoldingsForm } from './hooks';
import { useProgramData } from '~/components/program/tabs/hooks';
import { Loader } from '@common/loader';
import {
  unrestrictedDecimalPatternRule,
  maxValueRule,
  requiredRule,
} from '@common/form-inputs/constants';

export const HoldingsForm = () => {
  const { id: progId, holdId } = useParams();
  const { data } = useProgramData(progId);
  const { handleSubmit, control, reset, setValue } = useForm();
  const navigate = useNavigate();
  const { saveHolding, getOneHolding, getData, getStrategyForType, isLoading } =
    useHoldingsForm();
  const title = holdId === 'new' ? 'Create Holding' : 'Edit Holding';

  const [strategyVal, setStrategyVal] = useState({});

  useEffect(() => {
    if (holdId != 'new') {
      getOneHolding(progId, holdId);
    }
  }, []);

  useEffect(() => {
    reset(getData);
  }, [getData]);

  useEffect(() => {
    setStrategyVal(
      getStrategyForType(data?.type).find((x) => x.value == getData?.strategy)
    );
  }, [data]);

  const onStrategyChange = (val) => {
    setStrategyVal(val);
    setValue('strategy', val);
  };

  const onSubmit = (data) => {
    const tempHold = {
      ...data,
      strategy: strategyVal.value,
    };
    saveHolding(progId, tempHold);
    navigate(`/program/${progId}/composition`);
  };

  if (isLoading()) {
    return <Loader />;
  }

  return (
    <FormContainer title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTextInput
          name="name"
          label="Name"
          control={control}
          rules={{ ...requiredRule }}
          required={true}
        />

        <FormTextInput
          name="value"
          label="Value"
          control={control}
          required={true}
          rules={{
            ...unrestrictedDecimalPatternRule,
            ...maxValueRule(999.99),
            ...requiredRule,
          }}
        />

        <FormSelectInput
          name="strategy"
          label="Strategy"
          control={control}
          options={getStrategyForType(data?.type)}
          value={strategyVal}
          required={true}
          rules={{ ...requiredRule }}
          onChange={onStrategyChange}
        />

        <FormSubmitCancelButton
          cancelFunction={() => navigate(`/program/${progId}/composition`)}
        />
      </form>
    </FormContainer>
  );
};

HoldingsForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};
