import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ProfileActions } from './profile-actions';
import styles from './admin-toolbar.module.scss';
import classNames from 'classnames';

export const AdminToolbar = ({ onClick, isSidebarCollapsed }) => {
  const buttonClasses = classNames([styles['button']], {
    [styles['button--is-collapsed']]: !isSidebarCollapsed,
  });

  return (
    <div className={styles['body']}>
      <div className={styles['left-side']}>
        <button type="button" onClick={onClick} className={buttonClasses}>
          <FontAwesomeIcon icon="outdent" />
        </button>
      </div>
      <div className={styles['right-side']}>
        <ProfileActions />
      </div>
    </div>
  );
};

AdminToolbar.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
};
