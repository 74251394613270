import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import api from '@api';
import { logout } from '@redux/actions/login-actions';

export const GuardedRoute = ({ children }) => {
  const dispatch = useDispatch();
  let loginRoute = '/login';
  const loggedIn = api.auth.isLoggedIn();
  const expireTime = useSelector((state) => state.request.expire);
  const currentTime = Date.now();
  const expired = currentTime > expireTime;

  if (expired) {
    dispatch(logout());
  }

  return loggedIn && !expired ? children : <Navigate to={loginRoute} />;
};

GuardedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
