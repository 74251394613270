import React from 'react';
import PropTypes from 'prop-types';
import { FormContainer, FormTextInput } from '@common/form-inputs';
import { useLayoutForm } from '@core/layout/layout-form';

export const ProgramAdvisor = ({ control }) => {
  const { rowStyles, colStyle } = useLayoutForm();

  const fields = {
    firm: <FormTextInput control={control} name="advisor" label="Firm" />,
    contact: (
      <FormTextInput control={control} name="advisorContact" label="Contact" />
    ),
    phone: (
      <FormTextInput
        control={control}
        name="advisorContactPhone"
        label="Phone"
      />
    ),
    email: (
      <FormTextInput
        control={control}
        name="advisorContactEmail"
        label="Email"
      />
    ),
  };

  return (
    <FormContainer title="Advisor">
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.firm}
          {fields.contact}
        </div>
        <div className={colStyle}>
          {fields.phone}
          {fields.email}
        </div>
      </div>
    </FormContainer>
  );
};

ProgramAdvisor.propTypes = {
  control: PropTypes.object.isRequired,
};
