import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useProgramData } from '../../hooks';
import { useParams, useNavigate, Outlet, Link } from 'react-router-dom';
import { ProgramInfoContact } from './program-info-contact';
import { Loader } from '@common/loader';
import { saveProgramData } from '~/api/program';
import { useProgramInformation } from './hooks';
import { FormSaveButton } from '@common/form-inputs';
import { ProgramInfoGeneral } from './program-info-general';
import { ProgramInfoFundFlags } from './program-info-fund-flags';
import { ProgramInfoSecondary } from './program-info-secondary';
import { ProgramInfoBenchmarks } from './program-info-benchmarks';
import {
  ErrorMessage,
  SuccessMessage,
  UnsavedChangesMessage,
} from '@common/generic-message';
import { Button } from '@backstop/react-core';

export const ProgramInformation = () => {
  const programId = useParams().id;
  const { data } = useProgramData(programId);
  const { saveProgram, patchData, patchError } = saveProgramData();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm();
  const { isLoadingSelect } = useProgramInformation(programId);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = (data) => {
    //these fields end up in two places for 'legacy reasons'
    if (data.secondaryBenchmark) {
      data.secBench = data.secondaryBenchmark.label;
      data.secBenchId = data.secondaryBenchmark.value;
    }
    if (data.primaryBenchmark) {
      data.primBench = data.primaryBenchmark.label;
      data.primBenchId = data.primaryBenchmark.value;
    }
    const tempProg = {
      ...data,
    };
    if (programId != undefined) {
      tempProg.id = programId;
    }
    saveProgram(tempProg);
    reset(tempProg);
  };

  if (!data) {
    return <Loader />;
  }

  const openAdminCentralTab = () => {
    window.open(
      `https://www1.barclayhedge.com/cgi-bin/site/internal/admincentral/consoleLanding.cgi?id=${programId}`,
      '_blank',
      'noreferrer'
    );
    return;
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to={`/company/${data.compId}/compInfo`}>
          Go to company : {data.compName}
        </Link>
        <Button
          version="v3"
          style={{ margin: '10px' }}
          onClick={openAdminCentralTab}
        >
          Web
        </Button>
        <Button
          version="v3"
          onClick={() =>
            navigate(`/program/${programId}/progInfo/changeComp`, {
              state: {
                progType: data?.type,
              },
            })
          }
        >
          Change Company
        </Button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Outlet />
        <UnsavedChangesMessage trigger={isDirty} />
        <SuccessMessage trigger={patchData} />
        <ErrorMessage trigger={patchError} message={patchError?.message} />
        <ProgramInfoGeneral control={control} data={data} isDirty={isDirty} />
        <ProgramInfoFundFlags control={control} data={data} />
        <ProgramInfoSecondary control={control} data={data} />
        <ProgramInfoBenchmarks control={control} data={data} />
        <ProgramInfoContact control={control} data={data} />
        <FormSaveButton />
      </form>
    </>
  );
};
