import React, { useState } from 'react';
import '@common/style/hide-post-error.css';
import { useParams } from 'react-router-dom';
import { Button, DragDropFileUpload, Modal } from '@backstop/react-core';
import { useUploadManagedFundsData } from './hooks';
import { ErrorMessage } from '@common/generic-message';

export const UploadManagedFunds = () => {
  const compId = useParams().id;
  const [file, setFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { uploadManagedFunds, postError, patchError } =
    useUploadManagedFundsData();

  const handleFileAdd = (file) => {
    setFile(file);
    setModalOpen(true);
  };

  const handleClickConfirmUpdate = () => {
    uploadManagedFunds(compId, file, false);
    setModalOpen(false);
  };

  const handleClickConfirmReplace = () => {
    uploadManagedFunds(compId, file, true);
    setModalOpen(false);
  };

  const handleClickCancel = () => {
    setFile(null);
    setModalOpen(false);
  };

  return (
    <>
      <ErrorMessage trigger={patchError} message={patchError?.message} />
      <ErrorMessage trigger={postError} message={postError?.message} />
      <DragDropFileUpload
        version="v3"
        postUrl={'#'}
        acceptedFiles=".csv"
        onComplete={handleFileAdd}
      />

      <Modal
        version="v3"
        closeOnEscape={false}
        closeOnOverlayClick={false}
        open={modalOpen}
        footer={[
          <Button
            version="v3"
            onClick={handleClickConfirmUpdate}
            className="bsg-mr3"
            kind="secondary"
          >
            Update
          </Button>,
          <Button
            version="v3"
            onClick={handleClickConfirmReplace}
            className="bsg-mr3"
            kind="delete"
          >
            Replace
          </Button>,
          <Button
            version="v3"
            onClick={handleClickCancel}
            className="bsg-mr3"
            kind="cancel"
          >
            Cancel
          </Button>,
        ]}
      >
        If replacing all track record information, click{' '}
        <strong>Replace</strong>.
        <br />
        If adding to track record historical information, click
        <strong> Update</strong>. <br />
        If you would like to modify this submission, click{' '}
        <strong>Cancel</strong>.
      </Modal>
    </>
  );
};
