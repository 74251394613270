import React from 'react';
import PropTypes from 'prop-types';
import { FormControl as BsgFormControl } from '@backstop/react-core';

import styles from './form-control.module.scss';
import classNames from 'classnames';

export const FormControl = (props) => {
  const { children, className, fauxRequired, ...rest } = props;
  const classes = classNames(styles['label'], className);
  return (
    <BsgFormControl
      className={classes}
      extraComponent={fauxRequired ? <>Required</> : null}
      {...rest}
    >
      {children}
    </BsgFormControl>
  );
};

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
