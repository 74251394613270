import React, { useEffect, useState } from 'react';
import { CompanyHistoryTable } from './table';
import { useCompanyNotes } from './hooks';
import { FormControl, TextArea } from '@backstop/react-core';
import { FormSaveButton } from '@common/form-inputs';
import { useForm } from 'react-hook-form';
import { FormTextAreaInputDisabled } from '@common/form-inputs/form-text-area-input-disabled';

export const CompanyNotes = () => {
  const { handleSubmit, reset } = useForm();
  const [newNote, setNewNote] = useState('');
  const { data, loading, addNewAdminNote } = useCompanyNotes();

  const onSubmit = () => {
    addNewAdminNote(newNote);
    setNewNote('');
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl label="Add Note">
          <TextArea
            version="v3"
            name="noteToAppend"
            label="Add Note"
            onChange={setNewNote}
            value={newNote}
          />
        </FormControl>
        <FormTextAreaInputDisabled
          name="adminNotes"
          label="Admin Notes"
          value={data?.adminNotes}
        />
        <FormSaveButton />
      </form>
      <CompanyHistoryTable />
    </>
  );
};
