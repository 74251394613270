import { FormContainer } from '@common/form-inputs/form-container';
import React, { useState } from 'react';
import { useLayoutForm } from '@core/layout/layout-form';
import { FormTextInput } from '@common/form-inputs';
import { requiredRule } from '@common/form-inputs/constants';
import { useForm } from 'react-hook-form';
import { useTempAccessForm } from './hooks';
import { FormSelectInput } from '@common/form-inputs/form-select-input';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import { Navigate } from 'react-router-dom';

export const CreateTempAccessForm = () => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { handleSubmit, control } = useForm();
  const { createTempAccessEntry } = useTempAccessForm();
  const [redirectToTable, setRedirectToTable] = useState(false);

  if (redirectToTable) {
    return <Navigate to="/admin/tempAccess" />;
  }

  const onSubmit = (data) => {
    let submitData = {
      ...data,
      dbAccess: data.dbAccess.value,
    };
    createTempAccessEntry(submitData);
    setRedirectToTable(true);
  };

  const tempAccessOptions = [
    { label: 'FX', value: 'BARCLAY_DATA_FX_TEMP' },
    { label: 'CTA', value: 'BARCLAY_DATA_CTA_TEMP' },
    { label: 'Fund of Funds', value: 'BARCLAY_DATA_FOF_TEMP' },
    { label: 'Global', value: 'BARCLAY_DATA_GLOBAL_TEMP' },
    { label: 'Hedge Fund', value: 'BARCLAY_DATA_HF_TEMP' },
    { label: 'Hedge Fund No FoF', value: 'BARCLAY_DATA_HF_NO_FOFS_TEMP' },
    { label: 'UCITS', value: 'BARCLAY_DATA_UCITS_TEMP' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer title="Create Temporary Access">
        <div className={rowStyles}>
          <div className={colStyle}>
            <FormTextInput
              name="userId"
              label="User Email"
              rules={{ ...requiredRule }}
              control={control}
            />
            <FormSelectInput
              name="dbAccess"
              label="Access Level"
              options={tempAccessOptions}
              defaultValue={tempAccessOptions.find(
                (x) => x.value === 'BARCLAY_DATA_GLOBAL_TEMP'
              )}
              rules={{ ...requiredRule }}
              control={control}
            />
            <FormTextInput
              name="duration"
              label="Duration (Days)"
              defaultValue={6}
              rules={{ ...requiredRule }}
              control={control}
            />
          </div>
        </div>
        <FormSubmitCancelButton
          cancelFunction={() => setRedirectToTable(true)}
        />
      </FormContainer>
    </form>
  );
};
