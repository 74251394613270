import React, { useState } from 'react';
import { useProgramsTable } from './hooks';
import { Table } from '@common/table';
import { Loader } from '@common/loader';

import styles from './programs-table.module.scss';
import { COLUMNS } from '@core/columns';
import { Button, Select, TextInput } from '@backstop/react-core';
import { PROGRAM_TYPES_ARRAY } from '@core/constants';
import { FormContainer } from '@common/form-inputs/form-container';
import { FormControl } from '@common/form-control';

export const ProgramsTable = ({}) => {
  const {
    data,
    meta,
    loading,
    setPageSize,
    setCurrentPage,
    pageSize,
    currentPage,
    setNameVal,
    setTypeVal,
    setDataUsageVal,
    setStatusVal,
    dataUsageOptions,
    progStatusOptions,
  } = useProgramsTable();

  const [localNameVal, setLocalNameVal] = useState(null);
  const [localTypeVal, setLocalTypeVal] = useState(null);
  const [localDataUsageVal, setLocalDataUsageVal] = useState(null);
  const [localStatusVal, setLocalStatusVal] = useState(null);

  const runSearch = () => {
    setNameVal(localNameVal);
    setTypeVal(localTypeVal);
    setDataUsageVal(localDataUsageVal);
    setStatusVal(localStatusVal);
  };

  const convertStatusOptionsToCombineArchives = (options) => {
    //get all the archive options
    let archiveOptions = options.filter((o) => o.label.includes('Archived'));
    //filter them out of the full options
    options = options.filter((e) => !archiveOptions.includes(e));
    let archivedValuesArr = archiveOptions.map((a) => a.value);
    //add them back as 1 option
    options.push({
      label: 'Archived: All',
      value: archivedValuesArr.join(','),
    });
    options = [...options, ...archiveOptions];

    return options;
  };

  if (!dataUsageOptions) return <Loader />;

  return (
    <div className={styles['body']}>
      {loading && <Loader />}
      <FormContainer title="Search Filters">
        <FormControl label="Name">
          <TextInput
            version="v3"
            onChange={setLocalNameVal}
            placeholder="Search by fund or company name"
            value={localNameVal}
          />
        </FormControl>
        <FormControl label="Type">
          <Select
            options={[
              ...PROGRAM_TYPES_ARRAY,
              {
                label: 'All',
                value: '',
              },
            ]}
            placeholder="All"
            version="v3"
            onChange={setLocalTypeVal}
            value={localTypeVal}
          />
        </FormControl>
        <FormControl label="Data Usage">
          <Select
            options={[...dataUsageOptions, { label: 'Any', value: '' }]}
            placeholder="Any"
            version="v3"
            onChange={setLocalDataUsageVal}
            value={localDataUsageVal}
          />
        </FormControl>
        <FormControl label="Active Status">
          <Select
            options={convertStatusOptionsToCombineArchives(progStatusOptions)}
            placeholder="All"
            version="v3"
            onChange={setLocalStatusVal}
            value={localStatusVal}
          />
        </FormControl>
        <Button version="v3" onClick={runSearch}>
          Search
        </Button>
      </FormContainer>
      <Table
        allowSortingFor={'name'}
        rows={data}
        totalElements={meta?.totalElements}
        isLoading={loading}
        showPagination={true}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        allCols={COLUMNS}
      />
    </div>
  );
};
