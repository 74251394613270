import React from 'react';
import { FormSelectInput, FormContainer } from '@common/form-inputs';
import { useProgramInformation } from './hooks';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Loader } from '@common/loader';
export const ProgramInfoBenchmarks = ({ data, control }) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const programId = useParams().id;
  const { benchmarkOptions, isLoadingSelect } =
    useProgramInformation(programId);

  if (isLoadingSelect()) {
    return <Loader />;
  }
  return (
    <FormContainer title="Benchmarks">
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormSelectInput
            name="primaryBenchmark"
            label="Primary Benchmark"
            control={control}
            options={benchmarkOptions}
            defaultValue={benchmarkOptions.find(
              (x) => x.value === data.primBenchId
            )}
          />
        </div>
        <div className={colStyle}>
          <FormSelectInput
            name="secondaryBenchmark"
            label="Secondary Benchmark"
            control={control}
            options={benchmarkOptions}
            defaultValue={benchmarkOptions.find(
              (x) => x.value === data.secBenchId
            )}
          />
        </div>
      </div>
    </FormContainer>
  );
};

ProgramInfoBenchmarks.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
};
