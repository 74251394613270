import {
  FormCheckbox,
  FormContainer,
  FormTextInput,
  FormTextInputDisabled,
} from '@common/form-inputs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTrackRecordForm } from './hooks';
import { Loader } from '@common/loader';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import {
  decimalMaxRule,
  maxLengthRule,
  requiredRule,
  noNegative,
} from '@common/form-inputs/constants';
import { InlineMessageBlock } from '@backstop/react-core';

export const TrackRecordForm = () => {
  const { dstamp, id } = useParams();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const {
    data,
    qcData,
    updateOneTrackRecord,
    patchData,
    createOneTrackRecord,
    deleteOneTrackRecord,
  } = useTrackRecordForm(dstamp, id, state?.data?.date || dstamp);
  const { handleSubmit, control, reset, watch } = useForm();

  const watchRor = watch('ror');
  const [warnRor, setWarnRor] = useState(false);
  const [warnRorMessage, setWarnRorMessage] = useState('');

  const watchFum = watch('fum');
  const [warnFum, setWarnFum] = useState(false);
  const [warnFumMessage, setWarnFumMessage] = useState('');

  /**
   * Data limit warnings
   */
  useEffect(() => {
    const rorUpperLimit = qcData?.rorUpperWarning;
    const rorLowerLimit = qcData?.rorLowerWarning;
    const fumUpperLimit = qcData?.fumUpperWarning;
    const fumLowerLimit = qcData?.fumLowerWarning;

    if (watchRor > rorUpperLimit || watchRor < rorLowerLimit) {
      setWarnRor(true);
      setWarnRorMessage(
        `Large data discrepancy detected: Prior RoR ${qcData.ror}`
      );
    } else {
      setWarnRor(false);
      setWarnRorMessage('');
    }

    if (watchFum > fumUpperLimit || watchFum < fumLowerLimit) {
      setWarnFum(true);
      setWarnFumMessage(
        `Large data discrepancy detected: Prior AUM ${qcData.fum.toLocaleString()}`
      );
    } else {
      setWarnFum(false);
      setWarnFumMessage('');
    }
  }, [watchRor, watchFum, qcData]);

  const isDelete = pathname.includes('delete');

  const onSubmit = (data) => {
    if (dstamp === 'new') {
      createOneTrackRecord(data);
    } else {
      if (isDelete) {
        deleteOneTrackRecord();
      } else {
        updateOneTrackRecord(data);
      }
    }

    navigate(`/program/${id}/trackRecord`);
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  useEffect(() => {
    //if this is a new entry and we were provided last years data, populate the form with it
    if (state && dstamp === 'new') {
      reset(state.data);
    }
  }, []);

  if (!data && dstamp !== 'new') {
    return <Loader />;
  }

  const fields = {
    date: (
      <FormTextInputDisabled
        name="date"
        label="Date"
        value={data?.date || state.data.date}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    ror: (
      <FormTextInput
        name="ror"
        label="RoR"
        control={control}
        rules={{ ...decimalMaxRule, ...requiredRule }}
        required={true}
      />
    ),
    rorConfirmed: (
      <FormCheckbox
        name="rorConfirm"
        label="RoR Confirmed?"
        defaultValue={data?.rorConfirm === '1'}
        required={true}
        control={control}
      />
    ),
    aum: (
      <FormTextInput
        name="fum"
        label="AUM (K)"
        control={control}
        rules={{ ...maxLengthRule(18), ...requiredRule, ...noNegative }}
        required={true}
      />
    ),
  };

  let title = 'Edit Record';
  if (dstamp === 'new') {
    title = 'Create Record';
  }
  if (isDelete) {
    title = 'Delete Record';
  }

  return (
    <>
      <FormContainer title={title}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.date}
          {fields.ror}
          {warnRor && (
            <InlineMessageBlock kind="caution" version="v3">
              {warnRorMessage}
            </InlineMessageBlock>
          )}
          {fields.rorConfirmed}
          {fields.aum}
          {warnFum && (
            <InlineMessageBlock kind="caution" version="v3">
              {warnFumMessage}
            </InlineMessageBlock>
          )}
          <FormSubmitCancelButton
            altButtonText={isDelete ? 'Delete' : undefined}
            altButtonKind={isDelete ? 'delete' : undefined}
            cancelFunction={() => navigate(`/program/${id}/trackRecord`)}
          />
        </form>
      </FormContainer>
    </>
  );
};
