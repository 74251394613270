import React from 'react';
import { Message } from './message';

export const SuccessMessage = ({ trigger }) => {
  return (
    <Message
      kind="success"
      message="Save Successful"
      timerAmount={3000}
      trigger={trigger}
    />
  );
};
