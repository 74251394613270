import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl } from '@common/form-control';
import { Select } from '@backstop/react-core';

export const FormSelectInput = ({
  control,
  name,
  options,
  defaultValue,
  rules,
  label,
  tooltipText,
  formControlClassName,
  required,
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={true}
      shouldFocus={true}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        const errorList = error ? [error.message] : null;
        return (
          <FormControl
            label={label}
            required={required}
            errors={errorList}
            tooltipText={tooltipText}
            className={formControlClassName}
            {...restProps}
          >
            <Select
              version="v3"
              onBlur={onBlur}
              options={options}
              hasError={!!errorList}
              onChange={onChange}
              inputRef={ref}
              defaultValue={defaultValue}
              {...restProps}
            />
          </FormControl>
        );
      }}
    />
  );
};

export function generateKeyValueMirrorList(list) {
  let result = [];
  list.forEach((item) => {
    if (item === '') {
      //bsg select doesn't render the option if the label is empty, this prevents that
      result.push({ value: '', label: '', props: { helperText: 'Empty' } });
    } else {
      result.push({ value: item, label: item });
    }
  });
  return result;
}

FormSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  formControlClassName: PropTypes.string,
};
