import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextArea } from '@backstop/react-core';
import { FormControl } from '@common/form-control';

export const FormTextAreaInput = ({
  name,
  label,
  control,
  tooltipText,
  defaultValue,
  rules,
  placeholder = '',
  formControlClassName,
  required,
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={true}
      shouldFocus={true}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        const errorList = error ? [error.message] : null;
        return (
          <FormControl
            label={label}
            errors={errorList}
            tooltipText={tooltipText}
            className={formControlClassName}
            required={required}
          >
            <TextArea
              version="v3"
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              hasError={!!errorList}
              inputRef={ref}
              spellCheck={true}
              {...restProps}
            />
          </FormControl>
        );
      }}
    />
  );
};

FormTextAreaInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  formControlClassName: PropTypes.string,
};
