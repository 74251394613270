import React from 'react';
import styles from './tagline.module.scss';

export const Tagline = () => {
  return (
    <div className={styles['body']}>
      <div className={styles['title']}>Admin Console</div>
    </div>
  );
};
