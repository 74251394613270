import { Button, Modal } from '@backstop/react-core';
import React from 'react';
import { useCompanyContactForm } from './hooks';
import { useNavigate, useParams } from 'react-router-dom';

export const CompanyContactDeleteModal = () => {
  const compId = useParams().id;
  const contactId = useParams().contactId;
  const navigate = useNavigate();
  const { deleteCompContact } = useCompanyContactForm(compId);

  const confirmDelete = () => {
    deleteCompContact(contactId);
    navigate(`/company/${compId}/contacts`);
  };
  return (
    <Modal
      version="v3"
      open={true}
      footer={[
        <Button
          version="v3"
          onClick={confirmDelete}
          kind="secondary"
          className="bsg-mr3"
        >
          Yes
        </Button>,
        <Button
          version="v3"
          onClick={() => navigate(`/company/${compId}/contacts`)}
          kind="cancel"
          className="bsg-mr3"
        >
          Cancel
        </Button>,
      ]}
    >
      Are you sure you want to delete this contact?
    </Modal>
  );
};
