import { GenericTable } from '@common/table/generic-table';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCompanyFundsTable } from './hooks';
import { Table as BsgTable } from '@backstop/grid-view';
import { FormContainer } from '@common/form-inputs';

export const CompanyFundsTable = () => {
  const compId = useParams().id;
  const {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useCompanyFundsTable(compId);

  return (
    <div style={{ marginLeft: '0.25rem' }}>
      <GenericTable
        rows={data}
        cellComponent={CompanyFundsTableCell}
        totalElements={meta?.totalElements}
        isLoading={loading}
        showPagination={true}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        cols={{
          name: { value: 'name', label: 'Name', columnLabel: 'Name' },
          status: { value: 'active', label: 'Status', columnLabel: 'Status' },
        }}
      />
    </div>
  );
};

const CompanyFundsTableCell = (props) => {
  const name2 = props.row.name2 ? ` (${props.row.name2})` : '';
  if (props.column.name === 'name') {
    return (
      <BsgTable.Cell>
        <Link to={`/program/${props.row.id}/progInfo`}>
          {props.row.name + name2}
        </Link>
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
