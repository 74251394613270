import React from 'react';
import {
  FormSelectInput,
  FormContainer,
  FormCheckbox,
} from '@common/form-inputs';
import {
  PROGRAM_EQUALIZATION_METHOD_OPTIONS,
  PROGRAM_SUB_TYPES,
  getSubTypes,
} from '@core/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';

export const ProgramDetailsMafSafFlags = ({ control, data }) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const subTypes = getSubTypes(data);
  return (
    <FormContainer title="MAF/SAF Flags">
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormCheckbox
            name="equalization"
            checkboxLabel="Uses Equalization?"
            control={control}
            defaultValue={data.equalization === '1'}
          />

          <FormSelectInput
            name="equalizationMethod"
            label="Equalization Method"
            control={control}
            options={PROGRAM_EQUALIZATION_METHOD_OPTIONS}
            defaultValue={PROGRAM_EQUALIZATION_METHOD_OPTIONS.find(
              (x) => x.value === data.equalizationMethod
            )}
          />
        </div>
        <div className={colStyle}>
          <FormCheckbox
            name="sociallyResp"
            checkboxLabel="Socially Responsible Investing?"
            control={control}
            defaultValue={data.sociallyResp === '1'}
          />
        </div>
        {(subTypes.includes(PROGRAM_SUB_TYPES.MULTI_ADVISOR) ||
          subTypes.includes(PROGRAM_SUB_TYPES.FUND_OF_FUNDS)) && (
          <div className={colStyle}>
            <FormCheckbox
              name="investStartup"
              checkboxLabel="Invests in Startups?"
              control={control}
              defaultValue={data.investStartup === '1'}
            />
          </div>
        )}
        {(subTypes.includes(PROGRAM_SUB_TYPES.FUND_OF_FUNDS) ||
          subTypes.includes(PROGRAM_SUB_TYPES.MULTI_ADVISOR) ||
          subTypes.includes(PROGRAM_SUB_TYPES.SINGLE_MANAGER)) && (
          <div className={colStyle}>
            <FormCheckbox
              name="structuredProd"
              checkboxLabel="Structured Products?"
              control={control}
              defaultValue={data.structuredProd === '1'}
            />
          </div>
        )}
      </div>
    </FormContainer>
  );
};

ProgramDetailsMafSafFlags.propTypes = {
  data: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
};
