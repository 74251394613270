import { setCompManagedFundsDataState } from '@redux/actions/company-actions';
import { useDispatch } from 'react-redux';
import { useBasePatch, useBasePost } from '~/api/base';

export const useUploadManagedFundsData = () => {
  const { basePost, data: postData, error: postError } = useBasePost();
  const { basePatch, error: patchError } = useBasePatch();

  const dispatch = useDispatch();

  const uploadManagedFunds = (compId, file, shouldReplace) => {
    let formData = new FormData();
    formData.append('file', file);
    if (shouldReplace) {
      basePost(
        `/company/${compId}/managedFund/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        () => dispatch(setCompManagedFundsDataState('refresh'))
      );
    } else {
      basePatch(
        `/company/${compId}/managedFund/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        () => dispatch(setCompManagedFundsDataState('refresh'))
      );
    }
  };
  return { uploadManagedFunds, postError, patchError };
};
