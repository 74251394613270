import * as types from './action-types';

export function userDataSet(data) {
  return {
    type: types.USER_DATA_SET,
    data,
  };
}

export function userDataLoadingSet(loading) {
  return {
    type: types.USER_DATA_LOADING_SET,
    loading,
  };
}

export function userRolesSet(roles) {
  return {
    type: types.USER_ROLES_SET,
    roles,
  };
}

export function userDataClean() {
  return {
    type: types.USER_DATA_CLEAN,
  };
}

export function setTempUserTableRefreshStatus(status) {
  return {
    type: types.TEMP_ACCESS_TABLE_REFRESH_STATUS,
    status,
  };
}
