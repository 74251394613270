import { FormSaveButton } from '@common/form-inputs';
import { Loader } from '@common/loader';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { saveProgramData } from '~/api/program';
import { useProgramData } from '../../hooks';
import { ProgramDetails40Act } from './program-details-40-act';
import { ProgramDetailsGeneral } from './program-details-general';
import { ProgramDetailsLeverage } from './program-details-leverage';
import { ProgramDetailsMafSafFlags } from './program-details-maf-saf-flags';
import { ProgramDetailsRedemptions } from './program-details-redemptions';
import { useProgramDetails } from './hooks';
import { PROGRAM_SUB_TYPES, PROGRAM_TYPES, getSubTypes } from '@core/constants';
import {
  ErrorMessage,
  SuccessMessage,
  UnsavedChangesMessage,
} from '@common/generic-message';

export const ProgramDetails = () => {
  const programId = useParams().id;
  const { data } = useProgramData(programId);
  const { saveProgram, patchData, patchError } = saveProgramData();
  const { isLoadingSelect } = useProgramDetails();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
    setValue,
  } = useForm();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = (data) => {
    const tempProg = {
      ...data,
    };
    if (programId != undefined) {
      tempProg.id = programId;
    }
    saveProgram(tempProg);
    reset(tempProg);
  };

  if (!data) {
    return <Loader />;
  }

  const subTypes = getSubTypes(data);
  let hfOrMultiOrSingle =
    subTypes.includes(PROGRAM_SUB_TYPES.MULTI_ADVISOR) ||
    subTypes.includes(PROGRAM_SUB_TYPES.SINGLE_MANAGER) ||
    data.type === PROGRAM_TYPES.HedgeFund;

  const watchUs = watch('us');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UnsavedChangesMessage trigger={isDirty} />
      <ErrorMessage trigger={patchError} />
      <SuccessMessage trigger={patchData} />
      <ProgramDetailsGeneral watch={watch} control={control} data={data} />
      {hfOrMultiOrSingle && (
        <>
          <ProgramDetailsLeverage
            control={control}
            data={data}
            watch={watch}
            setValue={setValue}
          />
          <ProgramDetailsRedemptions control={control} data={data} />
        </>
      )}

      <ProgramDetailsMafSafFlags control={control} data={data} />
      {(watchUs?.value?.includes('40 Act') || data?.us?.includes('40 Act')) && (
        <ProgramDetails40Act control={control} data={data} />
      )}

      <FormSaveButton />
    </form>
  );
};
