import React, { useEffect } from 'react';
import {
  FormCheckbox,
  FormTextInput,
  FormTextInputDisabled,
} from '@common/form-inputs';
import { FormSelectInput } from '@common/form-inputs/form-select-input';
import { COMPANY_TYPES_ARRAY, COMPANY_TYPES } from '@core/constants';
import { FormTextAreaInput } from '@common/form-inputs/form-text-area-input';
import { FormDatePicker } from '@common/form-inputs/form-date-picker';
import { Button } from '@backstop/react-core';
import {
  convertSelectInputsToValues,
  maxLengthRule,
  requiredRule,
} from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import { FormContainer } from '@common/form-inputs/form-container';
import { Loader } from '@common/loader';
import { useCompanyInformation } from './hooks';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useCompanyData } from '../../hooks';
import { saveCompanyData } from '~/api/company';
import {
  ErrorMessage,
  SuccessMessage,
  UnsavedChangesMessage,
} from '@common/generic-message';

export const CompanyInformationForm = () => {
  const compId = useParams().id;
  const { rowStyles, colStyle } = useLayoutForm();
  const navigate = useNavigate();
  const { data } = useCompanyData(compId);
  const { saveCompany, patchData, patchError, postData, postStatus } =
    saveCompanyData();
  const {
    isLoadingSelects,
    stateOptions,
    countryOptions,
    denominationMumOptions,
    compStatusOptions,
  } = useCompanyInformation(compId);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm();

  const watchCountry = watch('country', data?.country);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  useEffect(() => {
    if (postData && postStatus === 201) {
      navigate(`/company/${postData.id}/compInfo`);
    }
  }, [postData, postStatus]);

  const onSubmit = (data) => {
    delete data.adminNotes;
    data = convertSelectInputsToValues(data);
    saveCompany(data);
    reset(data);
  };

  if (isLoadingSelects() || (compId !== undefined && !data)) {
    return <Loader />;
  }

  const watchType = watch('type');

  const fields = {
    name: (
      <FormTextInput
        name="name"
        label="Name"
        control={control}
        required={true}
        rules={{ ...maxLengthRule(128), ...requiredRule }}
      />
    ),
    type: data?.type ? (
      <FormTextInputDisabled
        name="type"
        label="Type"
        value={COMPANY_TYPES_ARRAY.find((x) => x.value === data?.type).label}
      />
    ) : (
      <FormSelectInput
        name="type"
        control={control}
        label="Type"
        options={COMPANY_TYPES_ARRAY}
        defaultValue={COMPANY_TYPES_ARRAY.find((x) => x.value === data?.type)}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    status: (
      <FormSelectInput
        name="status"
        control={control}
        label="Status"
        options={compStatusOptions}
        required={true}
        defaultValue={compStatusOptions?.find((x) => x.value === data?.status)}
        rules={{ ...requiredRule }}
      />
    ),
    address1: (
      <FormTextInput
        name="address1"
        label="Address"
        rules={{ ...maxLengthRule(128), ...requiredRule }}
        required={true}
        control={control}
      />
    ),
    address2: (
      <FormTextInput
        name="address2"
        label="Address 2"
        rules={{ ...maxLengthRule(128) }}
        required={false}
        control={control}
      />
    ),
    country: (
      <FormSelectInput
        name="country"
        label="Country"
        control={control}
        options={countryOptions}
        rules={{ ...requiredRule }}
        required={true}
        defaultValue={countryOptions.find((x) => x.value === data?.country)}
      />
    ),
    state:
      watchCountry?.value === 'USA' || watchCountry === 'USA' ? (
        <FormSelectInput
          name="state"
          label="State"
          control={control}
          options={stateOptions}
          defaultValue={stateOptions.find((x) => x.value === data?.state)}
        />
      ) : null,
    city: (
      <FormTextInput
        name="city"
        label="City"
        control={control}
        required={true}
        rules={{ ...maxLengthRule(64), ...requiredRule }}
      />
    ),
    zip: (
      <FormTextInput
        name="zip"
        label="Zip"
        control={control}
        rules={{ ...maxLengthRule(10) }}
      />
    ),
    phone1: (
      <FormTextInput
        name="phone1"
        label="Phone"
        control={control}
        rules={{ ...maxLengthRule(20) }}
      />
    ),
    fax: (
      <FormTextInput
        name="fax"
        label="Fax"
        control={control}
        rules={{ ...maxLengthRule(20) }}
      />
    ),
    email: (
      <FormTextInput
        name="email"
        label="Email"
        control={control}
        required={true}
        rules={{ ...maxLengthRule(45), ...requiredRule }}
      />
    ),
    registeredFirm: (
      <FormCheckbox
        name="registerFirm"
        label="Registered Firm?"
        control={control}
        defaultValue={data?.registerFirm === '1'}
      />
    ),
    secNumber: (
      <FormTextInput
        name="secNumber"
        label={
          watchType?.value === 'CTA'
            ? 'NFA Registered Firm - Number'
            : 'SEC Registered Firm - Number'
        }
        control={control}
        rules={{ ...maxLengthRule(15) }}
      />
    ),
    cik: (
      <FormTextInput
        name="cik"
        label="CIK"
        control={control}
        rules={{ ...maxLengthRule(32) }}
      />
    ),
    minorityOwned: (
      <FormCheckbox
        name="minorityOwned"
        label="Minority Owned?"
        control={control}
        defaultValue={data?.minorityOwned === '1'}
      />
    ),
    femaleOwned: (
      <FormCheckbox
        name="femaleOwned"
        label="Women Owned?"
        control={control}
        defaultValue={data?.femaleOwned === '1'}
      />
    ),
    princ: (
      <FormTextInput
        name="princ"
        label="Principals"
        control={control}
        rules={{ ...maxLengthRule(255) }}
      />
    ),
    princBio: (
      <FormTextAreaInput
        name="princBio"
        label="Principal's Bio"
        control={control}
      />
    ),
    startDate: (
      <FormDatePicker
        name="startDate"
        label="Start Date"
        control={control}
        view="year"
        required={true}
        rules={{ ...requiredRule }}
        views={['year', 'decade']}
      />
    ),
    numEmployees: (
      <FormTextInput
        name="numEmployees"
        label="# of Employees"
        control={control}
      />
    ),
    aumDenom: (
      <FormSelectInput
        name="denominat"
        label="AUM Denomination"
        control={control}
        options={denominationMumOptions}
        rules={{ ...requiredRule }}
        required={true}
        defaultValue={denominationMumOptions.find(
          (x) => x.value === data?.denominat
        )}
      />
    ),
    aboutUs: (
      <FormTextAreaInput name="aboutUs" label="About Us" control={control} />
    ),
    url: <FormTextInput name="url" label="URL" control={control} />,
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UnsavedChangesMessage trigger={isDirty} />
      <ErrorMessage trigger={patchError} message={patchError?.message} />
      <SuccessMessage trigger={patchData} />
      <FormContainer>
        <div className={rowStyles}>
          <div className={colStyle}>
            {fields.name}
            {fields.status}
            {fields.address1}
            {fields.address2}
            {fields.state}
            {fields.zip}
            {fields.phone1}
            {fields.aumDenom}
            {fields.cik}
            {fields.numEmployees}
            {fields.minorityOwned}
            {fields.femaleOwned}
          </div>
          <div className={colStyle}>
            {fields.type}
            {fields.startDate}
            {fields.country}
            {fields.city}
            {fields.fax}
            {fields.email}
            {fields.url}
            {fields.registeredFirm}
            {fields.secNumber}
            {fields.princ}
            {fields.princBio}
          </div>
        </div>
        <div className={rowStyles}>
          <div className={colStyle}>{fields.aboutUs}</div>
        </div>

        <Button type={'submit'} kind={'primary'} version="v3">
          Save
        </Button>
      </FormContainer>
    </form>
  );
};
