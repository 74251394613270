import { setInstrumentDataState } from '@redux/actions/program-actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  useBaseDelete,
  useBaseGet,
  useBasePatch,
  useBasePost,
} from '~/api/base';

export const useInstrumentsForm = () => {
  const { basePatch, data: patchData, ...patchRest } = useBasePatch();
  const { basePost, data: postData, ...postRest } = useBasePost();
  const { baseGet, data: getData, ...getRest } = useBaseGet();
  const { baseDelete } = useBaseDelete();
  const dispatch = useDispatch();

  const getOneInstrument = (progId, instId) => {
    baseGet(`/program/${progId}/instrument/${instId}`);
  };

  const saveInstrument = useCallback((progId, data) => {
    data.progId = progId;
    if (data.id) {
      basePatch(
        `/program/${progId}/instrument/${data.id}`,
        { data: data },
        undefined,
        () => dispatch(setInstrumentDataState('refresh'))
      );
    } else {
      basePost(
        `/program/${progId}/instrument/`,
        { data: data },
        undefined,
        () => dispatch(setInstrumentDataState('refresh'))
      );
    }
  }, []);

  const deleteInstrument = (progId, instId) => {
    baseDelete(`/program/${progId}/instrument/${instId}`, () => {
      dispatch(setInstrumentDataState('refresh'));
    });
  };

  return {
    saveInstrument,
    getOneInstrument,
    deleteInstrument,
    getData: getData?.data,
    postData: postData?.data,
    postMeta: postData?.meta,
    patchData: patchData?.data,
    patchMeta: patchData?.meta,
    ...patchRest,
    ...postRest,
  };
};
