import PropTypes from 'prop-types';
import { dataUnavailable, toDecimal } from '@core/formatters';

export const DecimalFormatter = ({ value }) => {
  return dataUnavailable(toDecimal(value));
};

DecimalFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
