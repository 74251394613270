import { setInstrumentDataState } from '@redux/actions/program-actions';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlWithPageParams } from '~/api/api-utils';
import { useBaseGet } from '~/api/base';

export const useInstrumentsTable = (progId) => {
  const { baseGet, data, ...rest } = useBaseGet();
  const dispatch = useDispatch();

  const instrumentDataState = useSelector(
    (state) => state.program.instrumentDataState
  );

  const getInstruments = useCallback((params) => {
    baseGet(getUrlWithPageParams(`/program/${progId}/instrument`, params));
  }, []);

  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getInstruments({ pageNumber: currentPage, pageSize: pageSize.value });
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (instrumentDataState === 'refresh') {
      getInstruments({ pageNumber: currentPage, pageSize: pageSize.value });
    }
    dispatch(setInstrumentDataState('loaded'));
  }, [instrumentDataState]);

  return {
    data: data?.data,
    setCurrentPage,
    currentPage,
    pageSize,
    setPageSize,
  };
};
