import React, { useEffect } from 'react';
import { useCompanyContactForm } from './hooks';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  FormContainer,
  FormSelectInput,
  FormTextAreaInput,
  FormTextInput,
} from '@common/form-inputs';
import { useForm } from 'react-hook-form';
import { useLayoutForm } from '@core/layout/layout-form';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import { Loader } from '@common/loader';
import {
  YES_NO_FLAG_OPTIONS,
  requiredRule,
} from '@common/form-inputs/constants';

export const CompanyContactForm = () => {
  const compId = useParams().id;
  const contactId = useParams().contactId;
  const { rowStyles, colStyle } = useLayoutForm();
  const navigate = useNavigate();

  const { handleSubmit, control, reset } = useForm();

  const {
    createCompContact,
    updateCompContact,
    positionOptions,
    educationOptions,
    ageRangeOptions,
    isLoadingSelect,
    getData,
    getOneContact,
  } = useCompanyContactForm(compId);

  useEffect(() => {
    reset(getData);
  }, [getData]);

  useEffect(() => {
    if (contactId !== 'new') {
      getOneContact(contactId);
    }
  }, []);

  const submitForm = (data) => {
    data.compId = compId;
    if (data.id) {
      updateCompContact(data);
    } else {
      createCompContact(data);
    }
    navigate(`/company/${compId}/contacts`);
  };

  if (isLoadingSelect() || (contactId !== 'new' && !getData)) {
    return <Loader />;
  }

  const fields = {
    name: (
      <FormTextInput
        required={true}
        rules={{ ...requiredRule }}
        name="name"
        label="Name"
        control={control}
      />
    ),
    position: (
      <FormSelectInput
        name="position"
        label="Position"
        control={control}
        options={positionOptions}
        defaultValue={positionOptions.find(
          (x) => x.value === getData?.position
        )}
      />
    ),
    phone: <FormTextInput name="phone" label="Phone" control={control} />,
    fax: <FormTextInput name="fax" label="Fax" control={control} />,
    email: <FormTextInput name="email" label="Email" control={control} />,
    bio: <FormTextAreaInput name="bio" label="Bio" control={control} />,
    age: (
      <FormSelectInput
        name="age"
        label="Age"
        control={control}
        options={ageRangeOptions}
        defaultValue={ageRangeOptions.find((x) => x.value === getData?.age)}
      />
    ),
    education: (
      <FormSelectInput
        name="education"
        label="Education"
        control={control}
        options={educationOptions}
        defaultValue={educationOptions.find(
          (x) => x.value === getData?.education
        )}
      />
    ),
    isPublic: (
      <FormSelectInput
        name="isPublic"
        label="Exclude"
        control={control}
        options={YES_NO_FLAG_OPTIONS}
        defaultValue={YES_NO_FLAG_OPTIONS.find(
          (x) => x.value === Number(getData?.isPublic)
        )}
      />
    ),
  };
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Outlet />
      <FormContainer title="Company Contact">
        <div className={rowStyles}>
          <div className={colStyle}>
            {fields.name}
            {fields.phone}
          </div>
          <div className={colStyle}>
            {fields.email}
            {fields.fax}
          </div>
        </div>
        <div className={rowStyles}>
          <div className={colStyle}>
            {fields.age}
            {fields.bio}
          </div>
          <div className={colStyle}>
            {fields.education}
            {fields.position}
            {fields.isPublic}
          </div>
        </div>
        <FormSubmitCancelButton
          cancelFunction={() => navigate(`/company/${compId}/contacts`)}
        />
      </FormContainer>
    </form>
  );
};
