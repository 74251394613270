import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInstrumentsForm } from './hooks';
import { Button, Modal } from '@backstop/react-core';

export const InstrumentDeleteModal = () => {
  const { id: progId, instId } = useParams();

  const navigate = useNavigate();

  const { deleteInstrument } = useInstrumentsForm();

  const confirmDelete = () => {
    deleteInstrument(progId, instId);
    navigate(`/program/${progId}/composition`);
  };

  return (
    <Modal
      version="v3"
      open={true}
      footer={[
        <Button
          version="v3"
          onClick={confirmDelete}
          kind="secondary"
          className="bsg-mr3"
        >
          Yes
        </Button>,
        <Button
          version="v3"
          onClick={() => navigate(`/program/${progId}/composition`)}
          kind="cancel"
          className="bsg-mr3"
        >
          Cancel
        </Button>,
      ]}
    >
      Are you sure you want to delete this instrument?
    </Modal>
  );
};
