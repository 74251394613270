import React from 'react';
import styles from './form-container.module.scss';

export const FormContainer = ({ children, title }) => {
  return (
    <div className={styles['body']}>
      {title ? (
        <div className={styles['header']}>
          <h2 className={styles['heading']}>{title}</h2>
        </div>
      ) : null}

      {children}
    </div>
  );
};
