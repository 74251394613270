import { Tab, TabList, TabPanel, Tabs } from '@backstop/react-core';
import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import styles from './admin.module.scss';

export const AdminHome = () => {
  const [index, setIndex] = useState(0);

  const tabLinks = [
    { url: `/admin/tempAccess`, text: 'Manage Temporary Access' },
  ];

  return (
    <div className={styles['body']}>
      <Tabs className={styles['tabs']} version="v3" selectedIndex={index}>
        <TabList>
          {tabLinks.map((tab, index) => (
            <Link
              key={index}
              to={tab.url}
              onClick={() => {
                setIndex(index);
              }}
            >
              <Tab>{tab.text}</Tab>
            </Link>
          ))}
        </TabList>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
};
