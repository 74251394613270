import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  TableHeaderRow,
  TableColumnVisibility,
  DragDropProvider,
  Table as BsgTable,
  DataTypeProvider,
} from '@backstop/grid-view';
import { GridLoader } from '@backstop/react-core';
import { CustomTableHeader } from './custom-table-header';

import { defaultSortingState } from '@core/columns';
import { DATA_FORMATTERS } from '@core/data-formatters';

import { Paginator } from './paginator';
import { useHiddenColumns } from './hooks';

import styles from './table.module.scss';

export const Table = (props) => {
  const {
    rows,
    cellComponent: Cell,
    totalElements,
    isLoading,
    showPagination,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    allCols,
  } = props;

  const cols = Object.keys(allCols).map((col) => ({
    name: allCols[col].value,
    label: allCols[col].columnLabel,
  }));

  const { hiddenColumns } = useHiddenColumns();

  return (
    <div className={styles['body']}>
      <div>
        {isLoading ? (
          <GridLoader show={true} />
        ) : (
          <Grid rows={rows} columns={cols} gutterWidth={200}>
            {Cell ? (
              <BsgTable cellComponent={(props) => <Cell {...props} />} />
            ) : (
              <BsgTable
                cellComponent={(props) => <BsgTable.Cell {...props} />}
              />
            )}

            <DragDropProvider
              columnComponent={({ column }) => (
                <Tags.Tag color={'light-blue'} label={column.label} />
              )}
            />

            <TableColumnVisibility
              defaultHiddenColumnNames={hiddenColumns}
              hiddenColumnNames={hiddenColumns}
            />

            {DATA_FORMATTERS &&
              DATA_FORMATTERS.map((formatter) => (
                <DataTypeProvider key={formatter.id} {...formatter} />
              ))}

            <TableHeaderRow
              contentComponent={(props) => (
                <CustomTableHeader
                  sortedCols={defaultSortingState}
                  setColumnSorting={() => {}}
                  allowSortingFor={[]}
                  {...props}
                />
              )}
            />
          </Grid>
        )}
      </div>

      {showPagination && (
        <Paginator
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalElements={totalElements}
          isTableLoading={isLoading}
        />
      )}
    </div>
  );
};

Table.defaultProps = {
  rows: [],
  cols: [],
  visibleByDefault: [],
  alwaysVisible: [],
};

Table.propTypes = {
  rows: PropTypes.array,
  cols: PropTypes.array,
  totalElements: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,

  allowSortingFor: PropTypes.arrayOf(PropTypes.string),
  showPagination: PropTypes.bool,
  pageSize: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  setPageSize: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
};
