const months = {
  1: 'January',
  2: 'Feburary',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export function getMonth(monthNum) {
  return months[monthNum];
}

export function getMonthFromThreeLetters(threeLetterMonthString) {
  return Object.keys(months).find(
    (key) => months[key].substring(0, 3) === threeLetterMonthString
  );
}

//will use the format to place MM,DD,YYYY into a string
export function jsDateToString(date, format) {
  let replace = format.toUpperCase();
  replace = replace.replace('YYYY', date.getFullYear());
  let m = date.getMonth() + 1;
  if (m < 10) {
    m = '0' + m;
  }
  replace = replace.replace('MM', m);
  let d = date.getDate();
  if (d < 10) {
    d = '0' + d;
  }
  replace = replace.replace('DD', d);
  return replace;
}

//requires format to know what order day-month are in
export function stringToJsDate(strDate, format, delim) {
  let splitDate = strDate.split(delim);
  let splitFormat = format.split(delim);
  let newDate = [];
  newDate[getPartOfDate(splitFormat[0])] = splitDate[0];
  newDate[getPartOfDate(splitFormat[1])] = splitDate[1];
  newDate[getPartOfDate(splitFormat[2])] = splitDate[2];
  return new Date(newDate[0], newDate[1] - 1, newDate[2]);
}

//helper for above func
function getPartOfDate(strFormat) {
  if (strFormat.includes('Y')) {
    return 0;
  }
  if (strFormat.includes('M')) {
    return 1;
  }
  if (strFormat.includes('D')) {
    return 2;
  }
  return null;
}

//takes in js Date and formats it to how the api wants it
//will always set return value DAY to 1
//returns STRING YYYY-MM-DD
export function formatDate(date) {
  return jsDateToString(date, 'YYYY-MM-DD');
}

//this uses items[0].date for getting the dates
//get the date from the list of items that is the LATE-est, closer to inf
export function getLatestDate(items) {
  let lastDate = new Date(1900, 0, 1);
  if (items !== undefined && items.length > 0) {
    for (const [, val] of Object.entries(items)) {
      if (val !== undefined) {
        //calculate start date
        let splitDate = val.date.split('-');
        let compareDate = new Date(
          splitDate[0],
          splitDate[1] - 1,
          splitDate[2]
        );
        if (compareDate > lastDate) {
          lastDate = compareDate;
        }
      }
    }
  } else {
    return undefined;
  }
  return lastDate;
}

//get the date from the list of items that is the EARLY-est, closer to -inf
export function getEarliestDate(items) {
  let earlyDate = new Date();
  if (items) {
    for (const [, val] of Object.entries(items)) {
      if (val !== undefined) {
        //calculate start date
        let splitDate = val.date.split('-');
        let compareDate = new Date(splitDate[0], splitDate[1] - 1, 1);
        if (compareDate < earlyDate) {
          earlyDate = compareDate;
        }
      }
    }
  } else {
    return undefined;
  }
  return earlyDate;
}

//return a date 1 month after the given date | will also set day to 1
export function getNextDate(currentDate) {
  //js dates are 0 indexed so this is Dec
  if (currentDate.getMonth() === 11) {
    return new Date(currentDate.getFullYear() + 1, 0, 1);
  } else {
    return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  }
}

//return a date 1 month after given date | will set day to 1
export function getPrevDate(currentDate) {
  if (currentDate.getMonth() === 0) {
    return new Date(currentDate.getFullYear() - 1, 11, 1);
  } else {
    return new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  }
}

//assumes delim is '-' : this will need to be updated if we ever use a deff delim
export function getNextDateFromDateString(currDateAsStr, dateFormat) {
  let dateObj = stringToJsDate(currDateAsStr, dateFormat, '-');
  let nextDate = getNextDate(dateObj);
  return jsDateToString(nextDate, dateFormat);
}

export function getDayAfter(currentDay) {
  let next = new Date(currentDay);
  next.setDate(next.getDate() + 1);
  return next;
}

export function getDayAfterFromString(currDayString, dateFormat, delim) {
  let dateObj = stringToJsDate(currDayString, dateFormat, delim);
  let nextDay = getDayAfter(dateObj);
  return jsDateToString(nextDay, dateFormat);
}

export function hasSuperAdminRole(roles) {
  if (!roles) {
    return false;
  }
  if (roles.indexOf('ROLE_SUPER') !== -1) {
    return true;
  }
  return false;
}

export function translateSelectInputs(formData, selectArr) {
  selectArr.forEach((selectName) => {
    if (formData[selectName]) {
      formData[selectName] = formData[selectName].value;
    }
  });
  return formData;
}
