import { useContext, useEffect } from 'react';
import { useCountryOptions, useStateOptions } from '~/api/select';
import {
  getMonth,
  getMonthFromThreeLetters,
  stringToJsDate,
} from '~/api/helperFunctions';
import { SelectOptionsContext } from '~/api/selectContext';

export const useProgramAdministrative = () => {
  /**
   * Last audit date is saved in the db as MMM-YYYY, this is a helper to convert from that form to a string the date picker can use
   * @param {*} input
   * @returns
   */
  const convertAuditDateToDatePicker = (input) => {
    if (input === 'None') {
      return input;
    }
    let [tempMonth, tempYear] = input.split('-');
    let monthNumber = Number(getMonthFromThreeLetters(tempMonth));
    return `${tempYear}-${monthNumber}-01`;
  };

  /**
   * Same as convertAuditDateToDatePicker, but in reverse
   * @param {*} input
   */
  const convertAuditDateFromDatePicker = (input) => {
    if (input === 'None') {
      return input;
    }
    let tempDate = stringToJsDate(input, 'YYYY-MM-DD', '-');
    let tempMonth = getMonth(Number(tempDate.getMonth()) + 1).substring(0, 3);
    return `${tempMonth}-${tempDate.getFullYear()}`;
  };

  const { stateOptions, countryOptions } = useContext(SelectOptionsContext);

  const isLoadingSelect = () => {
    return !stateOptions || !countryOptions;
  };

  return {
    isLoadingSelect,
    stateOptions,
    countryOptions,
    convertAuditDateFromDatePicker,
    convertAuditDateToDatePicker,
  };
};
