import { useContext, useEffect } from 'react';
import { useCompanyStatusOptions } from '~/api/select';
import { SelectOptionsContext } from '~/api/selectContext';

export const useCompanyInformation = (compId) => {
  const { stateOptions, countryOptions, denominationMumOptions } =
    useContext(SelectOptionsContext);

  const {
    compStatusOptions,
    getCompStatusOptions,
    loading: loadStatus,
  } = useCompanyStatusOptions(compId);

  useEffect(() => {
    getCompStatusOptions();
  }, []);

  const isLoadingSelects = () => {
    return (
      loadStatus || !stateOptions || !countryOptions || !denominationMumOptions
    );
  };

  return {
    isLoadingSelects,
    stateOptions,
    countryOptions,
    denominationMumOptions,
    compStatusOptions,
  };
};
