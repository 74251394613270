import {
  FormContainer,
  FormSelectInput,
  FormTextInput,
  FormControlHybridInput,
} from '@common/form-inputs';
import { requiredRule } from '@common/form-inputs/constants';
import { EXEMPT_STATUS_OPTIONS } from '@core/select-options';
import { useLayoutForm } from '@core/layout/layout-form';
import React from 'react';
import PropTypes from 'prop-types';

export const ProgramCtaTradingParameters = (props) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { control, data } = props;

  const fields = {
    meRatio: (
      <FormControlHybridInput
        name="meRatio"
        label="Margin/Equity"
        value={data.meRatio}
        removeOption={-2}
        rules={{ ...requiredRule }}
        required={true}
        {...props}
      />
    ),
    rtYrMil: (
      <FormControlHybridInput
        name="rtYrMil"
        label="RT/YR/$M"
        value={data.rtYrMil}
        rules={{ ...requiredRule }}
        required={true}
        {...props}
      />
    ),
    discretion: (
      <FormControlHybridInput
        name="discretion"
        label="Discretion"
        value={data.discretion}
        rules={{ ...requiredRule }}
        required={true}
        {...props}
      />
    ),
    options: (
      <FormControlHybridInput
        name="options"
        label="Options"
        value={data.options}
        rules={{ ...requiredRule }}
        required={true}
        {...props}
      />
    ),
    exempt: (
      <FormSelectInput
        name="exempt"
        label="Exempt Status"
        control={control}
        options={EXEMPT_STATUS_OPTIONS}
        defaultValue={EXEMPT_STATUS_OPTIONS.find(
          (x) => x.value === data.exempt
        )}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    interbank: (
      <FormControlHybridInput
        name="interbank"
        label="Interbank"
        value={data.interbank}
        required={true}
        rules={{ ...requiredRule }}
        {...props}
      />
    ),
  };
  return (
    <FormContainer title="Trading Parameters">
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.meRatio}
          {fields.rtYrMil}
          {fields.discretion}
          {fields.options}
        </div>
        <div className={colStyle}>
          {fields.exempt}
          {fields.interbank}
        </div>
      </div>
    </FormContainer>
  );
};

ProgramCtaTradingParameters.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
