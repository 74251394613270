import { useEffect, useState } from 'react';
import { useAllPrograms } from '~/api/program';

export const useCompanyFundsTable = (compId) => {
  const { getAllPrograms, data, meta, loading } = useAllPrograms();
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getAllPrograms({
      pageNumber: currentPage,
      pageSize: pageSize.value,
      filters: `filter[compId][eq]=${compId}`,
    });
  }, [pageSize, currentPage]);

  return {
    data,
    meta,
    loading,
    setCurrentPage,
    currentPage,
    pageSize,
    setPageSize,
  };
};
