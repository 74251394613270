import api from '@api';
import { setTempUserTableRefreshStatus } from '@redux/actions/user-data-actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useTempAccessTable = () => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);
  const { getTempAccessList, data, loading, meta } =
    api.admin.getTempAccessList();

  const [checkVal, setCheckVal] = useState(false);
  const tempAccessRefreshState = useSelector(
    (state) => state.userData.tempAccessTable
  );

  useEffect(() => {
    getTempAccessList({
      pageNumber: currentPage,
      pageSize: pageSize.value,
      checked: checkVal,
    });
  }, [pageSize, currentPage, checkVal]);

  useEffect(() => {
    if (tempAccessRefreshState === 'refresh') {
      getTempAccessList({
        pageNumber: currentPage,
        pageSize: pageSize.value,
        checked: checkVal,
      });
    }
    dispatch(setTempUserTableRefreshStatus('loaded'));
  }, [tempAccessRefreshState]);

  return {
    data,
    loading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    meta,
    setCheckVal,
  };
};
