import {
  FormContainer,
  FormTextInput,
  FormTextInputDisabled,
} from '@common/form-inputs';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCompanyClientBaseForm } from './hooks';
import { Loader } from '@common/loader';
import { FormControl } from '@common/form-control';
import { InlineMessageBlock, TextInput } from '@backstop/react-core';

export const ClientBaseForm = () => {
  const { clientId, id } = useParams();
  const navigate = useNavigate();
  const [totalVal, setTotalVal] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const { state, pathname } = useLocation();

  let year = new Date().getFullYear();
  //if we have a prev entry, use that to set the year
  if (state.data?.date) {
    year = state.data.date + 1;
  }

  const {
    data,
    updateOneClientBase,
    createOneClientBase,
    deleteOneClientBase,
  } = useCompanyClientBaseForm(id, clientId);

  const { handleSubmit, control, reset, watch } = useForm();

  const isDelete = pathname.includes('delete');

  let fieldNames = [
    'institutional',
    'highNetWorth',
    'familyOffice',
    'fof',
    'proprietary',
    'other',
  ];

  const watchInputs = watch(fieldNames);

  useEffect(() => {
    //if this is a new entry and we were provided last years data, populate the form with it
    if (state.data && clientId === 'new') {
      state.data.date = year;
      reset(state.data);
    }
  }, []);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  useEffect(() => {
    setTotalVal(
      watchInputs.map((val) => parseInt(val) || 0).reduce((a, b) => a + b)
    );
  }, [watchInputs]);

  useEffect(() => {
    setShowWarning(parseInt(totalVal) !== 100 ? true : false);
  }, [totalVal]);

  if (!data && clientId !== 'new') {
    return <Loader />;
  }

  const onSubmit = (data) => {
    if (parseInt(totalVal) !== 100) {
      return;
    }
    if (!data.date) {
      data.date = year;
    }
    //just in case a user leaves an input blank, let's set it to 0 for them
    fieldNames.forEach((field) => {
      if (data[field] === '') {
        data[field] = 0;
      }
    });
    data.compId = id;
    if (clientId === 'new') {
      createOneClientBase(data);
    } else if (isDelete) {
      deleteOneClientBase();
    } else {
      updateOneClientBase(data);
    }
    navigate(`/company/${id}/clientBase`);
  };

  const fields = {
    date: (
      <FormTextInputDisabled
        name="date"
        control={control}
        label="Year"
        required={true}
        defaultValue={year}
      />
    ),
    institutional: (
      <FormTextInput
        name="institutional"
        label="Institutional"
        control={control}
        defaultValue={0}
      />
    ),
    highNetWorth: (
      <FormTextInput
        name="highNetWorth"
        label="High Net Worth"
        control={control}
        defaultValue={0}
      />
    ),
    familyOffice: (
      <FormTextInput
        name="familyOffice"
        label="Family Office"
        control={control}
        defaultValue={0}
      />
    ),
    fof: (
      <FormTextInput
        name="fof"
        label="Fund of Funds"
        control={control}
        defaultValue={0}
      />
    ),
    prop: (
      <FormTextInput
        name="proprietary"
        label="Proprietary"
        control={control}
        defaultValue={0}
      />
    ),
    other: (
      <FormTextInput
        name="other"
        label="Other"
        control={control}
        defaultValue={0}
      />
    ),
    total: (
      <FormControl label="Total" disabled={true} required={true}>
        <TextInput version="v3" disabled={true} value={totalVal} />
      </FormControl>
    ),
  };

  let title = 'Edit Client Base';
  if (clientId === 'new') {
    title = 'Create Client Base';
  }
  if (isDelete) {
    title = 'Delete Client Base';
  }

  return (
    <FormContainer title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.date}
        {fields.institutional}
        {fields.highNetWorth}
        {fields.familyOffice}
        {fields.fof}
        {fields.prop}
        {fields.other}
        {fields.total}
        {showWarning && (
          <InlineMessageBlock kind="error">
            Client Base constituents do not total 100%
          </InlineMessageBlock>
        )}
        <FormSubmitCancelButton
          altButtonKind={isDelete ? 'delete' : undefined}
          altButtonText={isDelete ? 'Delete' : undefined}
          cancelFunction={() => navigate(`/company/${id}/clientBase`)}
        />
      </form>
    </FormContainer>
  );
};
