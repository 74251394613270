import { useContext, useEffect } from 'react';
import { useProgramStatusOptions } from '~/api/select';
import { SelectOptionsContext } from '~/api/selectContext';

export const useProgramInformation = (progId) => {
  const {
    frequencyOptions,
    denominationOptions: denominations,
    dataUsage: dataUsageOptions,
    benchmarkOptions,
    positionOptions,
    usOptions,
    categories: categoryOptions,
  } = useContext(SelectOptionsContext);

  const {
    getProgramStatusOptions,
    progStatusOptions,
    loading: loadingStatus,
  } = useProgramStatusOptions();

  useEffect(() => {
    getProgramStatusOptions(progId);
  }, []);

  const isLoadingSelect = () => {
    return (
      loadingStatus ||
      !frequencyOptions ||
      !denominations ||
      !dataUsageOptions ||
      !categoryOptions ||
      !benchmarkOptions ||
      !positionOptions ||
      !usOptions ||
      !progStatusOptions
    );
  };

  return {
    isLoadingSelect,
    frequencyOptions,
    denominations,
    dataUsageOptions,
    categoryOptions,
    benchmarkOptions,
    positionOptions,
    usOptions,
    progStatusOptions,
  };
};
