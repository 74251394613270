import initialState from './initial-state';
import * as types from '../actions/action-types';

export default function userDataReducer(state = initialState.userData, action) {
  switch (action.type) {
    case types.USER_DATA_SET:
      return {
        ...state,
        ...action.data,
      };

    case types.USER_DATA_LOADING_SET:
      return {
        ...state,
        loading: action.loading,
      };

    case types.USER_ROLES_SET:
      return {
        ...state,
        roles: action.roles,
      };

    case types.USER_DATA_CLEAN:
      return {
        ...initialState.userData,
      };

    case types.TEMP_ACCESS_TABLE_REFRESH_STATUS:
      return {
        ...state,
        tempAccessTable: action.status,
      };

    default:
      return state;
  }
}
