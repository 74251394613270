import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@common/form-control';
import { TextArea } from '@backstop/react-core';

/**
 * A component to only allow reading of data
 * @param {string} value - the value to display
 * @param {string} label - the label to display with the disabled input
 */
export const FormTextAreaInputDisabled = ({ value, label }) => {
  return (
    <FormControl label={label} disabled={true}>
      <TextArea version="v3" disabled={true} value={value} />
    </FormControl>
  );
};

FormTextAreaInputDisabled.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
