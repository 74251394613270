import React from 'react';
import {
  FormTextInput,
  FormSelectInput,
  FormContainer,
} from '@common/form-inputs';
import { PROGRAM_TYPES, getSubTypes, PROGRAM_SUB_TYPES } from '@core/constants';
import {
  YES_NO_FLAG_OPTIONS,
  maxLengthRule,
  requiredRule,
} from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import { RETURN_TYPE_OPTIONS } from '@core/constants';
import PropTypes from 'prop-types';

export const ProgramInfoSecondary = ({ control, data }) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const subTypes = getSubTypes(data);
  const openOptions = [
    { value: 'Open', label: 'Open' },
    { value: 'Closed', label: 'Closed' },
  ];
  return (
    <FormContainer title="Secondary Fund Info">
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormSelectInput
            name="open"
            label="Open"
            control={control}
            options={openOptions}
            required={true}
            defaultValue={openOptions.find((x) => x.value === data.open)}
          />

          {subTypes.includes(PROGRAM_SUB_TYPES.FUTURES) && (
            <FormSelectInput
              name="retType"
              control={control}
              label="Returns Type"
              options={RETURN_TYPE_OPTIONS}
              defaultValue={RETURN_TYPE_OPTIONS.find(
                (x) => x.value === data.retType
              )}
            />
          )}
        </div>
        {subTypes.includes(PROGRAM_SUB_TYPES.FUTURES) && (
          <div className={colStyle}>
            <FormTextInput
              name="targetedReturn"
              label="Targeted Annual Return"
              rules={{ ...maxLengthRule(128) }}
              control={control}
            />

            <FormTextInput
              name="targetedVolatility"
              label="Targeted Annual Volatility"
              rules={{ ...maxLengthRule(128) }}
              control={control}
            />
          </div>
        )}

        {data.type === PROGRAM_TYPES.HedgeFund && (
          <div className={colStyle}>
            <FormSelectInput
              name="fundFund"
              label="Is this a Fund of Funds?"
              options={YES_NO_FLAG_OPTIONS}
              defaultValue={YES_NO_FLAG_OPTIONS.find(
                (x) => String(x.value) === data?.fundFund
              )}
              rules={{ ...requiredRule }}
              required={true}
              control={control}
            />
          </div>
        )}
      </div>
    </FormContainer>
  );
};

ProgramInfoSecondary.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
};
