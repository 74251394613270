import React from 'react';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import { FormContainer, FormTextInput } from '@common/form-inputs';

export const ProgramPrimeBroker = ({ control }) => {
  const { rowStyles, colStyle } = useLayoutForm();

  const fields = {
    firm: <FormTextInput control={control} name="primeBroker" label="Firm" />,
    contact: (
      <FormTextInput control={control} name="primeContact" label="Contact" />
    ),
    phone: (
      <FormTextInput control={control} name="primeContactPhone" label="Phone" />
    ),
    email: (
      <FormTextInput control={control} name="primeContactEmail" label="Email" />
    ),
  };

  return (
    <FormContainer title="Prime Broker">
      <div className={rowStyles}>
        <div className={colStyle}>{fields.firm}</div>
        <div className={colStyle}>{fields.phone}</div>
        <div className={colStyle}>{fields.contact}</div>
        <div className={colStyle}>{fields.email}</div>
      </div>
    </FormContainer>
  );
};

ProgramPrimeBroker.propTypes = {
  control: PropTypes.object.isRequired,
};
