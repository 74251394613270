import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const ProgramNameFormatter = ({ value, row }) => {
  const name2 = row.name2 ? ` (${row.name2})` : '';
  return <Link to={`/program/${row.id}/progInfo`}>{value + name2}</Link>;
};

ProgramNameFormatter.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};
