import React from 'react';
import { InlineMessageBlock } from '@backstop/react-core';

export const CommonUploadMessage = ({ entityString }) => {
  return (
    <>
      If replacing all {entityString} information, click{' '}
      <strong>Replace</strong>.
      <br />
      If adding to {entityString} historical information, click
      <strong> Update</strong>. <br />
      If you would like to modify this submission, click <strong>Cancel</strong>
      .
    </>
  );
};

export const getWarningMessageFromData = ({ warningData, entityString }) => {
  let count = 0;
  let jsx = warningData.map((element, index) => {
    //if there ends up being a lot of errors, only show the first 5
    if (count > 4) {
      if (count === 100) {
        return;
      }
      count = 100;
      return (
        <>
          ....
          <br />
        </>
      );
    }
    let rawData = element[Object.keys(element)[0]];
    if (rawData.length > 0) {
      count++;
      return (
        <InlineMessageBlock key={index} kind="caution">
          {element[Object.keys(element)[0]]}
        </InlineMessageBlock>
      );
    }
  });
  return jsx.concat(<CommonUploadMessage entityString={entityString} />);
};

export const UploadCompleteMessage = ({ entityString }) => {
  return Array.of(
    <InlineMessageBlock kind="success">Upload Complete!</InlineMessageBlock>
  ).concat(<CommonUploadMessage entityString={entityString} />);
};

export const getFatalErrorMessage = (errData) => {
  return (
    <>
      <InlineMessageBlock kind="error">
        {typeof errData === 'object' ? '' : errData}
      </InlineMessageBlock>
      <>Please close this window and modify your submission</>
    </>
  );
};
