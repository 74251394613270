import { Button } from '@backstop/react-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TempAccessTable } from './table';

export const TempAccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <TempAccessTable />
      <Button version="v3" onClick={() => navigate('/admin/tempAccess/create')}>
        Create
      </Button>
    </>
  );
};
