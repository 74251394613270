import React, { useState } from 'react';
import {
  FormTextInputDisabled,
  FormTextInput,
  FormSelectInput,
  FormContainer,
  FormTextAreaInput,
} from '@common/form-inputs';
import {
  PROGRAM_TYPES,
  PROGRAM_TYPES_ARRAY,
  getSubTypes,
  PROGRAM_SUB_TYPES,
} from '@core/constants';
import { useProgramInformation } from './hooks';
import {
  maxLengthRule,
  requiredRule,
  unrestrictedDecimalPatternRule,
} from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@backstop/react-core';
import { Loader } from '@common/loader';
import { Message } from '@common/generic-message/message';

export const ProgramInfoGeneral = ({ control, data, isDirty }) => {
  const programId = useParams().id;
  const {
    frequencyOptions,
    denominations,
    dataUsageOptions,
    progStatusOptions,
    isLoadingSelect,
  } = useProgramInformation(programId);
  const { rowStyles, colStyle } = useLayoutForm();
  const subTypes = getSubTypes(data);
  const navigate = useNavigate();
  const [showDirtyMessage, setShowDirtyMessage] = useState(false);

  if (isLoadingSelect()) {
    return <Loader />;
  }

  const handleStatusChangeClick = () => {
    if (!isDirty) {
      navigate(`/program/${programId}/status`);
    } else {
      setShowDirtyMessage(true);
    }
  };

  const fields = {
    changeStatusMessage: (
      <Message
        kind="caution"
        message="You must save your changes before changing the status"
        trigger={showDirtyMessage}
      />
    ),
    active: (
      <>
        <FormTextInputDisabled
          name="active"
          label="Active Status"
          value={
            progStatusOptions?.find((x) => x.value === Number(data.active))
              ?.label
          }
        />
        <Button version="v3" kind="text" onClick={handleStatusChangeClick}>
          Change Status
        </Button>
      </>
    ),
    name: (
      <FormTextInput
        name="name"
        label="Fund Name"
        rules={{ ...maxLengthRule(128), ...requiredRule }}
        required={true}
        control={control}
      />
    ),
    browseName: (
      <FormTextInput
        name="browseName"
        label="Browse Name"
        rules={{ ...maxLengthRule(38), ...requiredRule }}
        required={true}
        control={control}
      />
    ),
    type: (
      <FormTextInputDisabled
        name="type"
        label="Type"
        value={PROGRAM_TYPES_ARRAY.find((x) => x.value === data.type).label}
      />
    ),
    name2: (
      <FormTextInput
        name="name2"
        label="Fund Name 2"
        rules={{ ...maxLengthRule(128) }}
        control={control}
      />
    ),
    isin: (
      <FormTextInput
        name="isin"
        label="ISIN/CUSIP #"
        control={control}
        rules={{ ...maxLengthRule(12) }}
      />
    ),
    mgtFee: (
      <FormTextInput
        name="mgtFee"
        label="Management Fee"
        control={control}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    incentiveFee: (
      <FormTextInput
        name="incentiveFee"
        label="Incentive Fee"
        control={control}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
    dataUsage: (
      <FormSelectInput
        name="dataUsage"
        label="Data Usage"
        control={control}
        options={dataUsageOptions}
        fauxRequired={true}
        defaultValue={dataUsageOptions.find((x) => x.value === data.dataUsage)}
      />
    ),
    dailyData: (
      <FormSelectInput
        name="dataUsageDaily"
        label="Daily Data Usage"
        control={control}
        options={dataUsageOptions}
        defaultValue={dataUsageOptions.find(
          (x) => x.value === data.dataUsageDaily
        )}
      />
    ),
    minAcct: (
      <FormTextInput
        name="minAccount"
        label="Minimum Investment"
        rules={{
          ...unrestrictedDecimalPatternRule,
          ...requiredRule,
        }}
        required={true}
        control={control}
      />
    ),
    subseqInvestSize: (
      <FormTextInput
        name="subsequentInvestmentSize"
        label="Subsequent Investment Size"
        control={control}
      />
    ),
    tradeMethod: (
      <FormTextAreaInput
        name="tradingMethod"
        label="Trading Method"
        control={control}
        rules={{ ...requiredRule }}
        required={true}
      />
    ),
  };

  return (
    <FormContainer title="General Fund Info">
      {fields.changeStatusMessage}
      <div className={rowStyles}>
        <div className={colStyle}>
          {fields.active}
          {fields.name}
          {fields.browseName}
        </div>
        <div className={colStyle}>
          {fields.type}
          {fields.name2}
        </div>
      </div>
      <div className={rowStyles}>
        <div className={colStyle}>
          {(subTypes.includes(PROGRAM_SUB_TYPES.MULTI_ADVISOR) ||
            subTypes.includes(PROGRAM_SUB_TYPES.SINGLE_MANAGER) ||
            data.type === PROGRAM_TYPES.HedgeFund) && (
            <FormTextInput
              name="capacity"
              label="Fund Capacity"
              control={control}
            />
          )}

          {fields.isin}
        </div>
        <div className={colStyle}>
          {fields.mgtFee}
          {fields.incentiveFee}

          {data.type === PROGRAM_TYPES.HedgeFund && (
            <FormSelectInput
              name="incentiveFeeFrequency"
              control={control}
              label="Incentive Fee Frequency"
              options={frequencyOptions}
              defaultValue={frequencyOptions.find(
                (x) => x.value === data.incentiveFeeFrequency
              )}
            />
          )}
        </div>
        <div className={colStyle}>
          {fields.dataUsage}
          {fields.dailyData}
        </div>
        <div className={colStyle}>
          {fields.minAcct}

          {(subTypes.includes(PROGRAM_SUB_TYPES.FUTURES) ||
            subTypes.includes(PROGRAM_SUB_TYPES.MULTI_ADVISOR) ||
            subTypes.includes(PROGRAM_SUB_TYPES.SINGLE_MANAGER)) && (
            <FormTextInput
              name="minAccountFund"
              label="Fund Minimum Investment"
              control={control}
              rules={{
                ...unrestrictedDecimalPatternRule,
              }}
            />
          )}

          {fields.subseqInvestSize}
        </div>
      </div>
      <div className={rowStyles}>
        <div className={colStyle}>{fields.tradeMethod}</div>
      </div>
    </FormContainer>
  );
};

ProgramInfoGeneral.propTypes = {
  control: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
};
