import { generateKeyValueMirrorList } from '@common/form-inputs/form-select-input';

export const YES_NO_OPTIONS = generateKeyValueMirrorList(['Yes', 'No']);

export const COMPANY_TYPES = {
  HedgeFund: 'HedgeFund',
  CTA: 'CTA',
};

export const PROGRAM_TYPES = {
  HedgeFund: 'HedgeFund',
  CTA: 'CTA',
  Benchmark: 'Benchmark',
};

export const INVESTOR_OPTIONS = generateKeyValueMirrorList([
  'US',
  'US - Non-US',
  'Non-US',
]);

export const RETURN_TYPE_OPTIONS = generateKeyValueMirrorList([
  'N/A',
  'Gross',
  'Net All Fees',
  'Net Mgmt.',
]);

export const REGISTRATION_OPTIONS = generateKeyValueMirrorList([
  '',
  'No',
  '3c1',
  '3c7',
  'Other',
]);

export const MONTH_OPTIONS_WITH_BLANK = generateKeyValueMirrorList([
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]);

/**
 * Definition of types under CTA/HF
 */
export const PROGRAM_SUB_TYPES = {
  FUND_OF_FUNDS: 'FUND_OF_FUNDS',
  SINGLE_MANAGER: 'SINGLE_MANAGER',
  MULTI_ADVISOR: 'MULTI_ADVISOR',
  FUTURES: 'FUTURES',
};

export const PROGRAM_SUB_TYPES_LABELS = [
  { label: 'Fund Of Funds', value: PROGRAM_SUB_TYPES.FUND_OF_FUNDS },
  { label: 'Single Manager', value: PROGRAM_SUB_TYPES.SINGLE_MANAGER },
  { label: 'Multi-Advisor', value: PROGRAM_SUB_TYPES.MULTI_ADVISOR },
  { label: 'Futures', value: PROGRAM_SUB_TYPES.FUTURES },
];

export const getSubTypes = (progData) => {
  let subTypes = [];
  if (progData?.fundFund === '1') {
    subTypes.push(PROGRAM_SUB_TYPES.FUND_OF_FUNDS);
  }
  if (progData?.multiAdvisor === '1') {
    subTypes.push(PROGRAM_SUB_TYPES.MULTI_ADVISOR);
  }
  if (progData?.ctaFund === '1') {
    subTypes.push(PROGRAM_SUB_TYPES.SINGLE_MANAGER);
  }
  if (
    progData?.type === PROGRAM_TYPES.CTA &&
    progData?.ctaFund !== '1' &&
    progData?.multiAdvisor !== '1' &&
    progData?.fundFund !== '1' &&
    progData?.fCrypto !== '1' &&
    progData?.tCrypto !== '1'
  ) {
    subTypes.push(PROGRAM_SUB_TYPES.FUTURES);
  }
  return subTypes;
};

export const PROGRAM_TYPES_ARRAY = [
  { label: 'HedgeFund', value: 'HedgeFund' },
  { label: 'CTA', value: 'CTA' },
  { label: 'Benchmark', value: 'Benchmark' },
];

export const COMPANY_TYPES_ARRAY = [
  { label: 'HedgeFund', value: 'HedgeFund' },
  { label: 'CTA', value: 'CTA' },
];

export const PROGRAM_TYPE_LABELS_SHORT = {
  HedgeFund: 'HF',
  CTA: 'CTA',
};

export const PROGRAM_TYPE_LABELS_FULL = {
  HedgeFund: 'Hedge Fund',
  CTA: 'Commodity Trading Advisor Profile',
};

export const PROGRAM_EQUALIZATION_METHOD_OPTIONS = generateKeyValueMirrorList([
  '',
  'Equalization Series',
  'Equalization/Depreciation Factor',
  'Equalization Adjustment Shares',
  'Other',
]);

export const PROGRAM_LEVERAGE_TYPE_OPTIONS = [
  { value: 'Unspecified', label: 'Unspecified' },
  { value: 'Gross', label: 'Gross' },
  { value: 'Net', label: 'Net' },
];

export const PROGRAM_UCITS_OPTIONS = generateKeyValueMirrorList([
  '',
  'Investment Company',
  'Unit Trust',
  'Common Contractual Fund',
]);

export const WINDOW_SIZES = {
  LG: '4',
  MD: '3',
  SM: '2',
  XS: '1',
};

export const WINDOW_SIZE_BREAKPOINTS = {
  [WINDOW_SIZES.LG]: 1920,
  [WINDOW_SIZES.MD]: 1280,
  [WINDOW_SIZES.SM]: 848,
};

export const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
  NO_SORT: 'NO_SORT',
};

export const PAGE_SIZE_OPTIONS = {
  50: { value: 50, label: '50' },
  100: { value: 100, label: '100' },
  150: { value: 150, label: '150' },
  200: { value: 200, label: '200' },
  250: { value: 250, label: '250' },
  300: { value: 300, label: '300' },
};

export const FILTER_TYPES = {
  equal: 'eq',
  notEqual: 'neq',
  in: 'in',
  notIn: 'nin',
  blank: 'blank',
  lessThanOrEqual: 'le',
  greatherThanOrEqual: 'ge',
  JOIN: 'JOIN',
};
