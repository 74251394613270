import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDailyDataForm } from './hooks';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { FormContainer, FormTextInput } from '@common/form-inputs';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import { FormDatePicker } from '@common/form-inputs/form-date-picker';
import { decimalMaxRule, requiredRule } from '@common/form-inputs/constants';
import { Loader } from '@common/loader';
import { InlineMessageBlock } from '@backstop/react-core';

export const DailyDataForm = () => {
  const { dstamp, id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, updateOneDailyData, createOneDailyData, qcData } =
    useDailyDataForm(dstamp, id, state?.data?.date || dstamp);
  const { handleSubmit, control, reset, watch } = useForm();

  const watchRor = watch('ror');
  const [warnRor, setWarnRor] = useState(false);
  const [warnRorMessage, setWarnRorMessage] = useState('');

  useEffect(() => {
    const rorUpperLimit = qcData?.rorUpperWarning;
    const rorLowerLimit = qcData?.rorLowerWarning;

    if (watchRor > rorUpperLimit || watchRor < rorLowerLimit) {
      setWarnRor(true);
      setWarnRorMessage(
        `Large data discrepancy detected: Prior RoR ${qcData.ror.toLocaleString()}`
      );
    } else {
      setWarnRor(false);
      setWarnRorMessage('');
    }
  }, [watchRor, qcData]);

  const formCancel = () => {
    navigate(`/program/${id}/dailyData`);
  };

  const onSubmit = (data) => {
    if (dstamp === 'new') {
      createOneDailyData(data);
    } else {
      updateOneDailyData(data);
    }

    navigate(`/program/${id}/dailyData`);
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  useEffect(() => {
    //if this is a new entry and we were provided last years data, populate the form with it
    if (state && dstamp === 'new') {
      reset(state.data);
    }
  }, []);

  if (!data && dstamp !== 'new') {
    return <Loader />;
  }

  const fields = {
    date: (
      <FormDatePicker
        name="date"
        label="Date"
        control={control}
        view="month"
        rules={{ ...requiredRule }}
        required={true}
        views={['month', 'year']}
      />
    ),
    ror: (
      <FormTextInput
        name="ror"
        label="RoR"
        control={control}
        rules={{ ...decimalMaxRule, ...requiredRule }}
        required={true}
      />
    ),
  };

  return (
    <>
      <FormContainer title={dstamp === 'new' ? 'Create Record' : 'Edit Record'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.date}
          {fields.ror}
          {warnRor && (
            <InlineMessageBlock kind="caution" version="v3">
              {warnRorMessage}
            </InlineMessageBlock>
          )}
          <FormSubmitCancelButton cancelFunction={formCancel} />
        </form>
      </FormContainer>
    </>
  );
};
