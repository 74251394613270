import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import React from 'react';
import { Table as BsgTable } from '@backstop/grid-view';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useInvestablePlatformTable } from './hooks';
import { Button } from '@backstop/react-core';
import { FormContainer } from '@common/form-inputs';

export const InvestablePlatformTable = () => {
  const progId = useParams().id;

  const {
    data,
    meta,
    loading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = useInvestablePlatformTable(progId);

  if (loading) {
    return <Loader />;
  }

  return (
    <FormContainer>
      <GenericTable
        rows={data}
        cols={{
          contactName: {
            value: 'contactName',
            label: 'Contact Name',
            columnLabel: 'Name',
          },
          contactPhone: {
            value: 'contactPhone',
            label: 'Contact Phone',
            columnLabel: 'Phone',
          },
          contactEmail: {
            value: 'contactEmail',
            label: 'Contact Email',
            columnLabel: 'Email',
          },
          provider: {
            value: 'provider',
            label: 'Provider',
            columnLabel: 'Provider',
          },
          action: {
            value: 'action',
            label: '',
            columnLabel: '',
          },
        }}
        cellComponent={InvestablePlatformTableCell}
        totalElements={meta?.totalElements}
        pageSize={pageSize}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        showPagination={true}
        isLoading={loading}
      />
    </FormContainer>
  );
};

const InvestablePlatformTableCell = (props) => {
  let progId = props.row.progId;
  let id = props.row.id;
  if (props.column.name === 'contactName') {
    return (
      <BsgTable.Cell>
        <Link to={`/program/${progId}/investPlat/${id}`}>
          {props.row.contactName}
        </Link>
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
