import { setCompNotesDataState } from '@redux/actions/company-actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useBasePost } from '~/api/base';
import { useCompanyData } from '../../hooks';

export const useCompanyNotes = () => {
  const id = useParams().id;
  const { data, loading, getOneCompany } = useCompanyData(id);
  const dispatch = useDispatch();

  const companyDataState = useSelector(
    (state) => state.company.compNotesDataState
  );

  const { basePost } = useBasePost();

  const addNewAdminNote = (adminNote) => {
    basePost(
      `/company/${id}/adminNote`,
      { data: { adminNotes: adminNote } },
      {},
      () => dispatch(setCompNotesDataState('refresh'))
    );
  };

  useEffect(() => {
    if (companyDataState === 'refresh') {
      getOneCompany(id);
    }
    dispatch(setCompNotesDataState('loaded'));
  }, [companyDataState]);

  return { data, loading, addNewAdminNote };
};
