import React from 'react';
import { FormTextInput, FormContainer } from '@common/form-inputs';
import { maxLengthRule } from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';

export const ProgramDetails40Act = ({ control, data }) => {
  const { rowStyles, colStyle } = useLayoutForm();
  return (
    <FormContainer title="40 Act Fields">
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormTextInput
            name="ticker"
            label="Ticker"
            control={control}
            rules={{ ...maxLengthRule(24) }}
          />
        </div>
        <div className={colStyle}>
          <FormTextInput
            name="grossExpenseRatio"
            label="Exp Ratio Gross"
            control={control}
          />
        </div>
        <div className={colStyle}>
          <FormTextInput name="netExpenseRatio" label="Net" control={control} />
        </div>
        <div className={colStyle}>
          <FormTextInput name="expenseCap" label="Cap" control={control} />
        </div>
      </div>
    </FormContainer>
  );
};

ProgramDetails40Act.propTypes = {
  data: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
};
