import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUploadDailyData } from './hooks';
import {
  UploadCompleteMessage,
  getFatalErrorMessage,
  getWarningMessageFromData,
} from '@common/upload/upload-messages';
import { setDailyDataState } from '@redux/actions/program-actions';
import { UploadBoxAndModals } from '@common/upload';

export const UploadDailyData = () => {
  const progId = useParams().id;
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [superModalOpen, setSuperModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(<></>);
  const [fatalErr, setFatalErr] = useState(false);

  const {
    uploadDailyDataPost,
    uploadDailyDataPatch,
    validateUploadFile,
    postData,
    postStatus,
  } = useUploadDailyData();

  useEffect(() => {
    if (postStatus === 400) {
      setFatalErr(true);
      setModalMessage(getFatalErrorMessage(postData));
    } else if (postStatus !== 202 && postData?.data) {
      setModalMessage(
        getWarningMessageFromData({
          warningData: postData.data,
          entityString: 'Daily Data',
        })
      );
    } else if (postStatus === 202) {
      setModalMessage(<UploadCompleteMessage entityString="Daily Data" />);
    }
  }, [postData, postStatus]);

  const handleFileAdd = (file) => {
    setFile(file);
    validateUploadFile(progId, file);
    setModalOpen(true);
  };

  const submitFile = (shouldReplace) => {
    if (fatalErr) {
      return;
    }
    if (shouldReplace) {
      uploadDailyDataPost(progId, file);
    } else {
      uploadDailyDataPatch(progId, file);
    }
    dispatch(setDailyDataState('upload'));
    setModalOpen(false);
  };

  const clickReplace = () => {
    setModalOpen(false);
    setSuperModalOpen(true);
  };

  const clickUpdate = () => {
    submitFile(false);
  };

  const clickCancel = () => {
    setFatalErr(false);
    setFile(null);
    setModalMessage(null);
    setModalOpen(false);
  };

  const clickCancelSuperModal = () => {
    setSuperModalOpen(false);
  };

  const clickSuperReplace = () => {
    setSuperModalOpen(false);
    submitFile(true);
  };

  return (
    <UploadBoxAndModals
      entityString="Daily Data"
      handleFileAdd={handleFileAdd}
      clickCancel={clickCancel}
      clickReplace={clickReplace}
      clickSuperReplace={clickSuperReplace}
      clickUpdate={clickUpdate}
      clickCancelSuperModal={clickCancelSuperModal}
      modalMessage={modalMessage}
      modalOpen={modalOpen}
      superModalOpen={superModalOpen}
      fatalErr={fatalErr}
    />
  );
};
