import React, { useEffect, useState } from 'react';
import { Outlet, useParams, Link, useLocation } from 'react-router-dom';
import { Tabs, Tab, TabList, TabPanel } from '@backstop/react-core';
import { useCompanyData } from './hooks';
import styles from './company-tabs-container.module.scss';

export const CompanyTabsContainer = () => {
  const companyId = useParams().id;
  const { pathname } = useLocation();

  const { data } = useCompanyData(companyId);

  const calculateLocationIndex = () => {
    if (pathname.includes('compInfo')) {
      return 0;
    }
    if (pathname.includes('contacts')) {
      return 1;
    }
    if (pathname.includes('notes')) {
      return 2;
    }
    if (pathname.includes('clientBase')) {
      return 3;
    }

    if (pathname.includes('fum')) {
      return 4;
    }
    if (pathname.includes('funds')) {
      return 5;
    }
  };

  const [index, setIndex] = useState(calculateLocationIndex());

  useEffect(() => {
    setIndex(calculateLocationIndex());
  }, [pathname]);

  const tabLinks = [
    { url: `/company/${companyId}/compInfo`, text: 'Company Information' },
    { url: `/company/${companyId}/contacts`, text: 'Company Contacts' },
    { url: `/company/${companyId}/notes`, text: 'Company Notes' },
    { url: `/company/${companyId}/clientBase`, text: 'Client Base' },
    { url: `/company/${companyId}/fum`, text: 'Funds Under Management' },
    { url: `/company/${companyId}/funds`, text: 'Company Funds' },
  ];

  return (
    <div className={styles['body']}>
      <h1 className={styles['company-name']}>{data?.name}</h1>

      <Tabs
        className={`${styles[('tabs', 'scrollable-tab')]}`}
        version="v3"
        selectedIndex={index}
      >
        <TabList>
          {tabLinks.map((tab, index) => (
            <Link
              to={tab.url}
              onClick={() => {
                setIndex(index);
              }}
            >
              <Tab>{tab.text}</Tab>
            </Link>
          ))}
        </TabList>

        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
};
