import { setCompClientBaseDataState } from '@redux/actions/company-actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useBaseDelete,
  useBaseGet,
  useBasePatch,
  useBasePost,
} from '~/api/base';

export const useCompanyClientBaseForm = (compId, clientId) => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getOneClientBase = () => {
    baseGet(`/company/${compId}/clientBase/${clientId}`);
  };

  useEffect(() => {
    if (clientId !== 'new') {
      getOneClientBase();
    }
  }, []);

  const { basePatch } = useBasePatch();
  const dispatch = useDispatch();

  const updateOneClientBase = (data) => {
    basePatch(
      `/company/${compId}/clientBase/${clientId}`,
      { data: data },
      undefined,
      () => dispatch(setCompClientBaseDataState('refresh'))
    );
  };

  const { basePost } = useBasePost();

  const createOneClientBase = (data) => {
    basePost(`/company/${compId}/clientBase`, { data: data }, undefined, () =>
      dispatch(setCompClientBaseDataState('refresh'))
    );
  };

  const { baseDelete } = useBaseDelete();

  const deleteOneClientBase = () => {
    baseDelete(`/company/${compId}/clientBase/${clientId}`, () =>
      dispatch(setCompClientBaseDataState('refresh'))
    );
  };

  return {
    createOneClientBase,
    updateOneClientBase,
    deleteOneClientBase,
    data: data?.data,
  };
};
