import { Modal } from '@backstop/react-core';
import React from 'react';
import { InstrumentsForm } from './instruments-form';

export const InstrumentsFormModal = () => {
  return (
    <Modal version="v3" open={true} footerVisible={false}>
      <InstrumentsForm />
    </Modal>
  );
};
