import {
  DecimalFormatter,
  MoneyMillionsFormatter,
  DecimalPercentageFormatter,
  ProgramNameFormatter,
  ProgramTypeFormatter,
  MoneyThousandsFormatter,
  DataUnavailableFormatter,
  BooleanValueFormatter,
  StructModifiersFormatter,
  StrategiesFormatter,
  CategoryFormatter,
  ThreeDigitDecimalFormatter,
  DaysFormatter,
  DateFormatter,
  SubscriptionsFormatter,
  RedemptionsFormatter,
  CompanyNameFormatter,
  ActiveStatusFormatter,
} from '@core/formatters';
import { COLUMNS } from './columns';

export const DATA_FORMATTERS = [
  {
    id: 'DataUnavailableFormatter',
    for: Object.keys(COLUMNS).map((key) => COLUMNS[key].value),
    formatterComponent: DataUnavailableFormatter,
  },
  {
    id: 'ProgramStatusFormatter',
    for: [COLUMNS.active.value],
    formatterComponent: ActiveStatusFormatter,
  },
  {
    id: 'ProgramNameFormatter',
    for: [COLUMNS.name.value],
    formatterComponent: ProgramNameFormatter,
  },
  {
    id: 'CompanyNameFormatter',
    for: ['compName'],
    formatterComponent: CompanyNameFormatter,
  },
  {
    id: 'ProgramTypeFormatter',
    for: [COLUMNS.type.value],
    formatterComponent: ProgramTypeFormatter,
  },
  {
    id: 'StructModifiersFormatter',
    for: [COLUMNS.us.value],
    formatterComponent: StructModifiersFormatter,
  },
  // {
  //   id: 'StrategiesFormatter',
  //   for: [COLUMNS.strategy.value],
  //   formatterComponent: StrategiesFormatter,
  // },
  {
    id: 'CategoryFormatter',
    for: [COLUMNS.primaryCat.value],
    formatterComponent: CategoryFormatter,
  },
  {
    id: 'SubscriptionsFormatter',
    for: [COLUMNS.subscriptions.value],
    formatterComponent: SubscriptionsFormatter,
  },
  {
    id: 'RedemptionsFormatter',
    for: [COLUMNS.redemptions.value],
    formatterComponent: RedemptionsFormatter,
  },
  {
    id: 'DecimalPercentageFormatter',
    for: [
      COLUMNS.annualStandardDev.value,
      COLUMNS.incentiveFee.value,
      COLUMNS.monthlyStandardDev.value,
      COLUMNS.sharpeRatio.value,
      COLUMNS.managementFee.value,
      COLUMNS.skewness.value,
      COLUMNS.worstDrawDown.value,
      COLUMNS.compoundAnnualReturn3year.value,
      COLUMNS.compoundAnnualReturn5year.value,
      COLUMNS.compoundAnnualReturn7year.value,
      COLUMNS.compoundAnnualReturn10year.value,
      COLUMNS.compoundAnnualReturn.value,
      COLUMNS.yearToDate.value,
      COLUMNS.return1Year.value,
      COLUMNS.return2Year.value,
      COLUMNS.return3Year.value,
      COLUMNS.return4Year.value,
      COLUMNS.return5Year.value,
      COLUMNS.totalReturn.value,
      COLUMNS.upCapVsUsTreasury.value,
      COLUMNS.upCapVsCta.value,
      COLUMNS.upCapVsUsTreasury.value,
      COLUMNS.upCapVsEquity.value,
      COLUMNS.upCapVsFof.value,
      COLUMNS.upCapVsHf.value,
      COLUMNS.upCapVsDow.value,
      COLUMNS.upCapVsFtSe.value,
      COLUMNS.upCapVsJpMorgan.value,
      COLUMNS.upCapVsEafe.value,
      COLUMNS.upCapVsMsciWorld.value,
      COLUMNS.upCapVsNasdaq.value,
      COLUMNS.upCapVsNikkei.value,
      COLUMNS.upCapVsRussel.value,
      COLUMNS.upCapVsSp500.value,
      COLUMNS.downCapVsUsTreasury.value,
      COLUMNS.downCapVsCta.value,
      COLUMNS.downCapVsEquity.value,
      COLUMNS.downCapVsFof.value,
      COLUMNS.downCapVsHf.value,
      COLUMNS.downCapVsDow.value,
      COLUMNS.downCapVsFtSe.value,
      COLUMNS.downCapVsJpMorgan.value,
      COLUMNS.downCapVsLehman.value,
      COLUMNS.downCapVsEafe.value,
      COLUMNS.downCapVsMsciWorld.value,
      COLUMNS.downCapVsNasdaq.value,
      COLUMNS.downCapVsNikkei.value,
      COLUMNS.downCapVsRussel.value,
      COLUMNS.downCapVsSp500.value,
      COLUMNS.alphaVsUsTreasury.value,
      COLUMNS.alphaVsCta.value,
      COLUMNS.alphaVsEquity.value,
      COLUMNS.alphaVsFof.value,
      COLUMNS.alphaVsHf.value,
      COLUMNS.alphaVsDow.value,
      COLUMNS.alphaVsFtSe.value,
      COLUMNS.alphaVsJpMorgan.value,
      COLUMNS.alphaVsLehman.value,
      COLUMNS.alphaVsEafe.value,
      COLUMNS.alphaVsMsciWorld.value,
      COLUMNS.alphaVsNasdaq.value,
      COLUMNS.alphaVsNikkei.value,
      COLUMNS.alphaVsRussel.value,
      COLUMNS.alphaVsSp500.value,
    ],
    formatterComponent: DecimalPercentageFormatter,
  },
  {
    id: 'MoneyMillionsFormatter',
    for: [COLUMNS.managerAssets.value, COLUMNS.aum.value],
    formatterComponent: MoneyMillionsFormatter,
  },
  {
    id: 'MoneyThousandsFormatter',
    for: [
      COLUMNS.minAccount.value,
      COLUMNS.minInvest.value,
      COLUMNS.subsequentInvestmentSize.value,
    ],
    formatterComponent: MoneyThousandsFormatter,
  },
  {
    id: 'DecimalFormatter',
    for: [COLUMNS.leverage.value],
    formatterComponent: DecimalFormatter,
  },
  {
    id: 'ThreeDigitDecimalFormatter',
    for: [
      COLUMNS.skewness.value,
      COLUMNS.sharpeRatio.value,
      COLUMNS.betaVsUsTreasury.value,
      COLUMNS.betaVsCta.value,
      COLUMNS.betaVsEquity.value,
      COLUMNS.betaVsFof.value,
      COLUMNS.betaVsHf.value,
      COLUMNS.betaVsDow.value,
      COLUMNS.betaVsFtSe.value,
      COLUMNS.betaVsJpMorgan.value,
      COLUMNS.betaVsLehman.value,
      COLUMNS.betaVsEafe.value,
      COLUMNS.betaVsMsciWorld.value,
      COLUMNS.betaVsNasdaq.value,
      COLUMNS.betaVsNikkei.value,
      COLUMNS.betaVsRussel.value,
      COLUMNS.betaVsSp500.value,
      COLUMNS.rSqVsUsTreasury.value,
      COLUMNS.rSqVsCta.value,
      COLUMNS.rSqVsEquity.value,
      COLUMNS.rSqVsFof.value,
      COLUMNS.rSqVsHf.value,
      COLUMNS.rSqVsDow.value,
      COLUMNS.rSqVsFtSe.value,
      COLUMNS.rSqVsJpMorgan.value,
      COLUMNS.rSqVsLehman.value,
      COLUMNS.rSqVsEafe.value,
      COLUMNS.rSqVsMsciWorld.value,
      COLUMNS.rSqVsNasdaq.value,
      COLUMNS.rSqVsNikkei.value,
      COLUMNS.rSqVsRussel.value,
      COLUMNS.rSqVsSp500.value,
      COLUMNS.corrVsUsTreasury.value,
      COLUMNS.corrVsCta.value,
      COLUMNS.corrVsEquity.value,
      COLUMNS.corrVsFof.value,
      COLUMNS.corrVsHf.value,
      COLUMNS.corrVsDow.value,
      COLUMNS.corrVsFtSe.value,
      COLUMNS.corrVsJpMorgan.value,
      COLUMNS.corrVsLehman.value,
      COLUMNS.corrVsEafe.value,
      COLUMNS.corrVsMsciWorld.value,
      COLUMNS.corrVsNasdaq.value,
      COLUMNS.corrVsNikkei.value,
      COLUMNS.corrVsRussel.value,
      COLUMNS.corrVsSp500.value,
    ],
    formatterComponent: ThreeDigitDecimalFormatter,
  },
  {
    id: 'BooleanValueFormatter',
    for: [
      COLUMNS.proprietaryAccount.value,
      COLUMNS.managedAccount.value,
      COLUMNS.sec13f.value,
      COLUMNS.leadFund.value,
    ],
    formatterComponent: BooleanValueFormatter,
  },
  {
    id: 'DaysFormatter',
    for: [COLUMNS.lockup.value],
    formatterComponent: DaysFormatter,
  },
  {
    id: 'DateFormatter',
    for: [
      COLUMNS.createDate.value,
      COLUMNS.inceptionDate.value,
      COLUMNS.lastModified.value,
    ],
    formatterComponent: DateFormatter,
  },
];
