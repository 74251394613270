import {
  FormContainer,
  FormContainerRequiredHeader,
} from '@common/form-inputs';
import { FormControlHybridInput } from '@common/form-inputs/form-control-hybrid-input';
import { useLayoutForm } from '@core/layout/layout-form';
import React from 'react';
import PropTypes from 'prop-types';

export const ProgramTimeFrameAllocation = (props) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { data } = props;

  return (
    <FormContainer>
      <FormContainerRequiredHeader title="Time Frame Allocation" />
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormControlHybridInput
            name="tfShort"
            label="Short-Term (30 days or less)"
            value={data.tfShort}
            {...props}
          />

          <FormControlHybridInput
            name="tfMedium"
            label="Medium-Term (31-90 days)"
            value={data.tfMedium}
            {...props}
          />

          <FormControlHybridInput
            name="tfLong"
            label="Long-Term (90+ days)"
            value={data.tfLong}
            {...props}
          />
        </div>
      </div>
    </FormContainer>
  );
};

ProgramTimeFrameAllocation.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
};
