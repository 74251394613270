import React from 'react';
import { Button } from '@backstop/react-core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTempAccessForm } from './hooks';
import { FormContainer } from '@common/form-inputs/form-container';

export const RevokeAccessForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { deleteTempAccessEntry } = useTempAccessForm();

  const revokeAccess = () => {
    deleteTempAccessEntry({ id: state.id });
    navigate('/admin/tempAccess');
  };

  return (
    <>
      <FormContainer title={`Revoke access for user ${state.email}?`}>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button version="v3" onClick={() => revokeAccess()}>
            Yes
          </Button>
          <Button
            version="v3"
            kind="delete"
            onClick={() => navigate('/admin/tempAccess')}
          >
            No
          </Button>
        </div>
      </FormContainer>
    </>
  );
};
