import { Modal } from '@backstop/react-core';
import { FormSelectInput } from '@common/form-inputs';
import React, { useEffect } from 'react';
import { useProgramInformation } from '../program-information/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { saveProgramData } from '~/api/program';
import { useForm } from 'react-hook-form';
import { useProgramData } from '../../hooks';
import { Loader } from '@common/loader';
import { FormSubmitCancelButton } from '@common/form-inputs/form-submit-cancel-button';
import './dropdown-zindex-adjust.css';
import { ErrorMessage } from '@common/generic-message';

export const ChangeStatusPopup = () => {
  const programId = useParams().id;
  const { progStatusOptions } = useProgramInformation(programId);
  const { saveProgram, patchData, patchError } = saveProgramData();
  const { data } = useProgramData(programId);
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();

  const submitForm = (submitData) => {
    let tempProg = {
      ...data,
      active: submitData.active.value,
    };
    saveProgram(tempProg);
  };

  useEffect(() => {
    if (patchData !== undefined) {
      navigate(`/program/${programId}/progInfo`);
    }
  }, [patchData]);

  if (!data) {
    return <Loader />;
  }

  return (
    <Modal
      version="v3"
      open={true}
      footerVisible={false}
      title="Change Program Status"
    >
      <ErrorMessage trigger={patchError} message={patchError?.message} />
      <form onSubmit={handleSubmit(submitForm)}>
        <FormSelectInput
          name="active"
          control={control}
          label="Active Status"
          options={progStatusOptions}
          defaultValue={progStatusOptions.find(
            (x) => x.value === Number(data.active)
          )}
        />
        <FormSubmitCancelButton
          cancelFunction={() => navigate(`/program/${programId}/progInfo`)}
        />
      </form>
    </Modal>
  );
};
