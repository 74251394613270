import { useCallback, useEffect } from 'react';
import { useBaseGet } from '~/api/base';

export const useCompanyData = (id) => {
  const { baseGet, data, loading, error, ...rest } = useBaseGet();

  const getOneCompany = useCallback((compId) => {
    if (compId === undefined) {
      return;
    }
    baseGet(`/company/${compId}/`);
  }, []);

  useEffect(() => {
    getOneCompany(id);
  }, [id]);

  return { data: data?.data, loading, error, getOneCompany };
};
