export const invalidTypeErrorMessage =
  'This input does not support that input type';

export const betweenZeroAndHundred = {
  validate: {
    isPositive: (v) => parseInt(v) >= 0 || 'Number cannot be negative',
    isAboveHundred: (v) =>
      parseInt(v) <= 100 || 'Number cannot be greater than 100',
  },
};

export const noNegative = {
  validate: {
    isPositive: (v) => parseInt(v) >= 0 || 'Number cannot be negative',
  },
};

export const unrestrictedDecimalPatternRule = {
  pattern: {
    value: /^[0-9]\d*(\.\d+)?$/,
    message: invalidTypeErrorMessage,
  },
};

export const decimalMaxRule = {
  pattern: {
    value: /^-?\d{1,14}(\.\d{1,4})?$/,
    message: 'Decimal Entered is too large',
  },
};

export const requiredRule = {
  required: 'This field is required',
};

export const maxLengthRule = (max) => {
  return {
    maxLength: {
      value: max,
      message: 'Current input exceeds maximum characters allowed',
    },
  };
};

export const maxValueRule = (maxValue) => {
  return {
    max: {
      value: maxValue,
      message: 'Current input exceeds maximum value allowed',
    },
  };
};

export const convertSelectInputsToValues = (data) => {
  let tempData = {};
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'object') {
      tempData[key] = value.value;
    } else {
      tempData[key] = value;
    }
  }
  return tempData;
};

export const YES_NO_FLAG_OPTIONS = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];
