import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import React from 'react';
import { Table as BsgTable } from '@backstop/grid-view';
import { Link, useParams } from 'react-router-dom';
import { useCompanyContactsTable } from './hooks';
import { Button, Tooltip } from '@backstop/react-core';
import { useCompanyContactForm } from '../form/hooks';
import { FormContainer } from '@common/form-inputs';

export const CompanyContactsTable = () => {
  const compId = useParams().id;
  const {
    getData,
    getMeta,
    getLoading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useCompanyContactsTable(compId);

  if (getLoading) {
    return <Loader />;
  }

  return (
    <FormContainer>
      <GenericTable
        rows={getData}
        cols={{
          sequence: { value: 'sequence', label: 'Order', columnLabel: 'Order' },
          name: { value: 'name', label: 'Name', columnLabel: 'Name' },
          phone: { value: 'phone', label: 'Phone', columnLabel: 'Phone' },
          fax: { value: 'fax', label: 'Fax', columnLabel: 'Fax' },
          email: { value: 'email', label: 'Email', columnLabel: 'Email' },
          age: { value: 'age', label: 'Age', columnLabel: 'Age' },
          position: {
            value: 'position',
            label: 'Position',
            columnLabel: 'Position',
          },
          education: {
            value: 'education',
            label: 'Education',
            columnLabel: 'Education',
          },
          bio: { value: 'bio', label: 'Bio', columnLabel: 'Bio' },
          public: {
            value: 'isPublic',
            label: 'Exclude',
            columnLabel: 'Exclude',
          },
          action: { value: 'action', label: '', columnLabel: '' },
        }}
        cellComponent={CompanyContactsTableCell}
        totalElements={getMeta?.totalElements}
        pageSize={pageSize}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        showPagination={false}
        isLoading={getLoading}
      />
      <Link to={`/company/${compId}/contacts/new`}>
        <Button version="v3">Create</Button>
      </Link>
    </FormContainer>
  );
};

const CompanyContactsTableCell = (props) => {
  const compId = useParams().id;
  const { sendContactToTopSequence } = useCompanyContactForm(compId);
  if (props.column.name === 'isPublic') {
    return (
      <BsgTable.Cell>
        {Number(props.row.isPublic) === 0 ? 'No' : 'Yes'}
      </BsgTable.Cell>
    );
  }
  if (props.column.name === 'name') {
    return (
      <BsgTable.Cell>
        <Link
          to={`/company/${props.row.compId}/contacts/${props.row.id}`}
          style={{ paddingRight: '0.25rem' }}
        >
          {props.row.name}
        </Link>
      </BsgTable.Cell>
    );
  }
  if (props.column.name === 'sequence') {
    return (
      <BsgTable.Cell>
        {props.row.sequence !== '0' && (
          <ButtonWithTooltip
            message="Send To Top"
            buttonContent="&uarr;"
            onClick={() => sendContactToTopSequence(props.row.id)}
          />
        )}

        {Number(props.row.sequence) + 1}
      </BsgTable.Cell>
    );
  }
  if (props.column.name === 'action') {
    return (
      <BsgTable.Cell>
        <Link
          to={`/company/${props.row.compId}/contacts/${props.row.id}/delete`}
        >
          <Button version="v3" kind="text">
            Delete
          </Button>
        </Link>
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};

const ButtonWithTooltip = ({ message, buttonContent, onClick }) => {
  const btnRef = React.useRef();
  return (
    <>
      <Tooltip version="v3" target={btnRef} aria-label={message}>
        {message}
      </Tooltip>
      <span className="iconButton-container" tabIndex="0" ref={btnRef}>
        <Button
          version="v3"
          kind="text"
          aria-hidden
          tabIndex="-1"
          onClick={onClick}
        >
          {buttonContent}
        </Button>
      </span>
    </>
  );
};
