import React from 'react';
import { Modal } from '@backstop/react-core';
import { DailyDataForm } from './daily-data-form';

export const DailyDataFormModal = () => {
  return (
    <Modal version="v3" open={true} footerVisible={false}>
      <DailyDataForm />
    </Modal>
  );
};
