import * as types from './action-types';

export function setTrackRecordDataState(state) {
  return {
    type: types.SET_TRACK_RECORD_DATA_REFRESH_STATE,
    state,
  };
}

export function setInvestPlatDataState(state) {
  return {
    type: types.SET_INVEST_PLAT_DATA_REFRESH_STATE,
    state,
  };
}

export function setProgramDataState(state) {
  return {
    type: types.SET_PROGRAM_DATA_REFRESH_STATE,
    state,
  };
}

export function setDailyDataState(state) {
  return {
    type: types.SET_DAILY_DATA_REFRESH_STATE,
    state,
  };
}

export function setInstrumentDataState(state) {
  return {
    type: types.SET_INSTRUMENT_DATA_REFRESH_STATE,
    state,
  };
}

export function setHoldingDataState(state) {
  return {
    type: types.SET_HOLDING_DATA_REFRESH_STATE,
    state,
  };
}
