import { WINDOW_SIZES } from '@core/constants';
import { getWindowSize } from '@core/layout';

const windowSize = getWindowSize();

export default {
  request: {
    pendingRequests: [],
  },

  cache: {},

  cacheLoading: {},

  userData: {
    roles: [],
  },

  layout: {
    windowSize,
    sidebarCollapsed: windowSize === WINDOW_SIZES.XS,
  },

  selectOptions: {
    stateOptions: [],
    countryOptions: [],
    dataUsageOptions: [],
    exchangeOptions: [],
    subscriptionOptions: [],
    redemptionOptions: [],
    activeOptions: [],
    frequencyOptions: [],
    usOptions: [],
    positionOptions: [],
    localeOptions: [],
    strategyOptions: [],
    categoryOptions: [],
    categoryStyleOptions: [],
    subscriptionFrequencyOptions: [],
    benchmarkOptions: [],
    denominationOptions: [],
    dayOptions: [],
    mumDenomOptions: [],
    investorTypeOptions: [],
  },

  program: {},
  company: {},
};
