import React, { useEffect, useState } from 'react';
import { ProgramHistoryTable } from './table';
import { useParams } from 'react-router-dom';
import { useProgramNotes } from './hooks';
import { useForm } from 'react-hook-form';
import { FormSaveButton, FormTextAreaInputDisabled } from '@common/form-inputs';
import { TextArea } from '@backstop/react-core';
import { FormControl } from '@common/form-control';

export const ProgramNotes = () => {
  const progId = useParams().id;
  const { data, addNewAdminNote } = useProgramNotes(progId);
  const { handleSubmit, reset } = useForm();
  const [newNote, setNewNote] = useState();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = () => {
    addNewAdminNote(newNote);
    setNewNote('');
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl label="Add Note">
          <TextArea
            version="v3"
            name="noteToAppend"
            label="Add Note"
            onChange={setNewNote}
            value={newNote}
          />
        </FormControl>
        <FormTextAreaInputDisabled
          name="adminNotes"
          label="Admin Notes"
          value={data?.adminNotes}
        />
        <FormSaveButton />
      </form>
      <ProgramHistoryTable />
    </>
  );
};
