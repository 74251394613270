import { Modal } from '@backstop/react-core';
import React from 'react';
import { CompanyContactForm } from './company-contact-form';

export const CompanyContactModal = () => {
  return (
    <Modal version="v3" open={true} footerVisible={false}>
      <CompanyContactForm />
    </Modal>
  );
};
