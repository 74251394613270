import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyHistoryTable } from './hooks';
import { Table as BsgTable } from '@backstop/grid-view';

export const CompanyHistoryTable = () => {
  const compId = useParams().id;
  const {
    data,
    loading,
    meta,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useCompanyHistoryTable(compId);
  if (loading) {
    return <Loader />;
  }
  return (
    <div style={{ marginLeft: '0.25rem' }}>
      <GenericTable
        rows={data}
        cols={{
          date: { value: 'date', label: 'Date', columnLabel: 'Date' },
          adminId: { value: 'user', label: 'User', columnLabel: 'User' },
          text: { value: 'text', label: 'Action', columnLabel: 'Action' },
        }}
        cellComponent={CompanyHistoryTableCell}
        totalElements={meta?.totalElements}
        pageSize={pageSize}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        showPagination={true}
        isLoading={loading}
      />
    </div>
  );
};

const CompanyHistoryTableCell = (props) => {
  if (props.column.name === 'text') {
    return (
      <BsgTable.Cell style={{ wordWrap: 'normal', whiteSpace: 'normal' }}>
        {props.value}
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
