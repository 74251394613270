import { Button, Modal } from '@backstop/react-core';
import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCompaniesTable } from '~/components/company/table/hooks';
import { Table as BsgTable } from '@backstop/grid-view';
import { useProgramChangeComp } from './hooks';

export const ProgramChangeComp = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const progId = useParams().id;

  const {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    setTypeVal,
  } = useCompaniesTable();

  useEffect(() => {
    setTypeVal({ value: state.progType });
  }, []);

  const translateData = (data) => {
    return data?.map((obj) => ({ ...obj, compName: obj.name }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      open={true}
      footerVisible={false}
      version="v3"
      title="Change Company"
    >
      <GenericTable
        cellComponent={CompanyChangeCellComponent}
        rows={translateData(data)}
        totalElements={meta?.totalElements}
        isLoading={loading}
        showPagination={true}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        cols={{
          compName: { value: 'compName', label: 'Name', columnLabel: 'Name' },
          action: { value: 'action', label: '', columnLabel: '' },
        }}
      />
      <Button
        version="v3"
        onClick={() => navigate(`/program/${progId}/progInfo`)}
      >
        Cancel
      </Button>
    </Modal>
  );
};

const CompanyChangeCellComponent = (props) => {
  const progId = useParams().id;
  const { changeCompany } = useProgramChangeComp(progId);
  const navigate = useNavigate();
  const changeCompanyAndRedirect = (compId) => {
    changeCompany(compId);
    navigate(`/program/${progId}/progInfo`);
  };
  if (props.column.name === 'action') {
    return (
      <BsgTable.Cell>
        <Button
          version="v3"
          kind="text"
          onClick={() => changeCompanyAndRedirect(props.row.id)}
        >
          Assign
        </Button>
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
