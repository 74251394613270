import { generateKeyValueMirrorList } from '@common/form-inputs/form-select-input';

export const SINGLE_ADVISOR = 'Single Advisor';

export const MULTI_ADVISOR = 'Multiadvisor';

export const NEITHER = 'Neither';

export const ADVISOR_STATUS_OPTIONS = generateKeyValueMirrorList([
  SINGLE_ADVISOR,
  MULTI_ADVISOR,
  NEITHER,
]);
