import React from 'react';
import {
  FormTextInput,
  FormSelectInput,
  FormContainer,
  FormControlHybridInput,
} from '@common/form-inputs';
import { YES_NO_OPTIONS, PROGRAM_LEVERAGE_TYPE_OPTIONS } from '@core/constants';
import { maxLengthRule, requiredRule } from '@common/form-inputs/constants';
import { useLayoutForm } from '@core/layout/layout-form';
import PropTypes from 'prop-types';

export const ProgramDetailsLeverage = ({ control, data, watch, setValue }) => {
  const { rowStyles, colStyle } = useLayoutForm();

  return (
    <FormContainer title="Leverage">
      <div className={rowStyles}>
        <div className={colStyle}>
          <FormTextInput
            name="hurdle"
            label="Hurdle"
            control={control}
            rules={{ ...maxLengthRule(24) }}
            fauxRequired={true}
          />
        </div>
        <div className={colStyle}>
          <FormSelectInput
            name="highwater"
            control={control}
            label="Highwater"
            options={YES_NO_OPTIONS}
            defaultValue={YES_NO_OPTIONS.find(
              (x) => x.value === data.highwater
            )}
            fauxRequired={true}
          />
        </div>
        <div className={colStyle}>
          <FormSelectInput
            name="leverageType"
            label="Leverage Type"
            control={control}
            options={PROGRAM_LEVERAGE_TYPE_OPTIONS}
            defaultValue={PROGRAM_LEVERAGE_TYPE_OPTIONS.find(
              (x) => x.value === data.leverageType
            )}
          />
        </div>
        <div className={colStyle}>
          <FormControlHybridInput
            name="leverage"
            label="Leverage"
            control={control}
            watch={watch}
            setValue={setValue}
            value={data.leverage}
          />
        </div>
      </div>
    </FormContainer>
  );
};

ProgramDetailsLeverage.propTypes = {
  data: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
