import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiContext } from '@api';
import { loginFailure, loginSuccess } from '@redux/actions/login-actions';
import { useBasePost } from './base';

export const isLoggedIn = () => {
  return useSelector((state) => !!state.request.expire);
};

export const useLogin = () => {
  const { unauthorizedApiHttpClient } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const endpointUrl = '/api/auth/signin';

  const login = useCallback(
    ({ email, password }) => {
      const body = {
        email,
        password,
      };

      setLoading(true);
      setError(null);

      unauthorizedApiHttpClient
        .post(endpointUrl, body)
        .then((response) => {
          if (
            response.data.roles.includes('ROLE_ADMIN') ||
            response.data.roles.includes('ROLE_SALES')
          ) {
            dispatch(loginSuccess(response.data));
          } else {
            dispatch(loginFailure('Missing Role'));
            setError('Missing Role');
          }
        })
        .catch((error) => {
          dispatch(loginFailure(error));
          setError(error);
        })
        .finally(() => setLoading(false));
    },
    [endpointUrl]
  );

  return {
    login,
    loading,
    error,
  };
};

export const useLogout = () => {
  const { basePost, data, loading, ...rest } = useBasePost();

  const logout = () => {
    basePost('/api/auth/logout');
  };

  return { logout, data, loading, rest };
};
