import * as admin from './admin';
import * as auth from './auth';
import * as user from './user';
import * as select from './select';
import * as program from './program';
import * as company from './company';

export default {
  admin,
  auth,
  user,
  select,
  program,
  company,
};
export { APIContextProvider, useApiContext } from './context';
