import React from 'react';
import { useLocation } from 'react-router-dom';
import { CreateProgramForm } from './create-program-form';

export const CreateProgram = () => {
  const { state } = useLocation();
  return (
    <>
      <CreateProgramForm companyData={state} />
    </>
  );
};
