import { setHoldingDataState } from '@redux/actions/program-actions';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlWithPageParams } from '~/api/api-utils';
import { useBaseGet } from '~/api/base';

export const useHoldingsTable = (progId) => {
  const { baseGet, data, ...rest } = useBaseGet();
  const dispatch = useDispatch();
  const holdingDataState = useSelector(
    (state) => state.program.holdingDataState
  );

  const getHoldings = useCallback((params) => {
    baseGet(getUrlWithPageParams(`/program/${progId}/holding`, params));
  }, []);

  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getHoldings({ pageNumber: currentPage, pageSize: pageSize.value });
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (holdingDataState === 'refresh') {
      getHoldings({ pageNumber: currentPage, pageSize: pageSize.value });
    }
    dispatch(setHoldingDataState('loaded'));
  }, [holdingDataState]);

  return {
    data: data?.data,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  };
};
