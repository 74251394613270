import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@common/form-control';
import { TextInput } from '@backstop/react-core';

/**
 * A component to only allow reading of data
 * @param {string} value - the value to display
 * @param {string} label - the label to display with the disabled input
 */
export const FormTextInputDisabled = ({ value, label, ...rest }) => {
  let defaultValue = rest.defaultValue;
  return (
    <FormControl label={label} disabled={true} {...rest}>
      <TextInput
        version="v3"
        disabled={true}
        value={value}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};

FormTextInputDisabled.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
