import { generateKeyValueMirrorList } from '@common/form-inputs/form-select-input';

export const EXEMPT_STATUS_OPTIONS = generateKeyValueMirrorList([
  '4.13(a)(2) Exempt CPO',
  '4.13(a)(3) Exempt CPO',
  '4.13(a)(4) Exempt CPO',
  '4.7 Exempt (QEPs only)',
  '4.7 Exempt (QEPs only) and 4.13(a)(2) Exempt CPO',
  '4.14(a)(8)',
]);
