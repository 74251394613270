import { setCompClientBaseDataState } from '@redux/actions/company-actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlWithPageParams } from '~/api/api-utils';
import { useBaseGet } from '~/api/base';

export const useCompanyClientBaseTable = (compId) => {
  const { baseGet, data, meta, loading } = useBaseGet();
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);
  const clientBaseDataState = useSelector(
    (state) => state.company.compClientBaseDataState
  );
  const dispatch = useDispatch();

  const getCompanyClientBase = (params) => {
    baseGet(getUrlWithPageParams(`/company/${compId}/clientBase/`, params));
  };

  useEffect(() => {
    getCompanyClientBase({ pageNumber: currentPage, pageSize: pageSize.value });
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (clientBaseDataState === 'refresh') {
      getCompanyClientBase({
        pageNumber: currentPage,
        pageSize: pageSize.value,
      });
    }
    dispatch(setCompClientBaseDataState('loaded'));
  }, [clientBaseDataState]);

  return {
    data: data?.data,
    meta: data?.meta,
    loading,
    setCurrentPage,
    currentPage,
    pageSize,
    setPageSize,
  };
};
