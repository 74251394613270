import { setInvestPlatDataState } from '@redux/actions/program-actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProgramInvestmentPlatform } from '~/api/program';

export const useInvestablePlatformTable = (progId) => {
  const { getInvestmentPlatforms, data, meta, loading } =
    useProgramInvestmentPlatform(progId);
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const [currentPage, setCurrentPage] = useState(1);
  const investPlatDataState = useSelector(
    (state) => state.program.investPlatDataState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getInvestmentPlatforms({
      pageNumber: currentPage,
      pageSize: pageSize.value,
    });
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (investPlatDataState === 'refresh') {
      getInvestmentPlatforms({
        pageNumber: currentPage,
        pageSize: pageSize.value,
      });
    }
    dispatch(setInvestPlatDataState('loaded'));
  });

  return {
    data,
    meta,
    loading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  };
};
