import React from 'react';

import { AuthPageBase } from '@auth/auth-page-base';
import { LoginForm } from './login-form';

export const Login = () => {
  return (
    <AuthPageBase>
      <LoginForm />
    </AuthPageBase>
  );
};
