import React, { useEffect } from 'react';
import { ProgramTransferAgent } from './program-transfer-agent';
import { useForm } from 'react-hook-form';
import { useProgramData } from '../../hooks';
import { useLocation, useParams } from 'react-router-dom';
import { Loader } from '@common/loader';
import { saveProgramData } from '~/api/program';
import { FormSaveButton } from '@common/form-inputs';
import { ProgramAdministration } from './program-administration';
import { ProgramLegal } from './program-legal';
import { ProgramAudit } from './program-audit';
import { ProgramPrimeBroker } from './program-prime-broker';
import { ProgramCustodian } from './program-custodian';
import { useProgramAdministrative } from './hooks';
import {
  ErrorMessage,
  SuccessMessage,
  UnsavedChangesMessage,
} from '@common/generic-message';
import { ProgramAdvisor } from './program-advisor';

export const ProgramAdministrative = () => {
  const programId = useParams().id;
  const { data } = useProgramData(programId);
  const { saveProgram, patchData, patchError } = saveProgramData();
  const {
    isLoadingSelect,
    convertAuditDateFromDatePicker,
    convertAuditDateToDatePicker,
  } = useProgramAdministrative();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm();

  useEffect(() => {
    if (data) {
      if (data.lastAudit) {
        data.lastAudit = convertAuditDateToDatePicker(data.lastAudit);
      }
      reset(data);
    }
  }, [data]);

  const onSubmit = (data) => {
    const tempProg = {
      ...data,
    };
    if (programId !== undefined) {
      tempProg.id = programId;
    }
    if (tempProg.lastAudit) {
      tempProg.lastAudit = convertAuditDateFromDatePicker(tempProg.lastAudit);
    }
    saveProgram(tempProg);
    reset(tempProg);
  };

  if (!data) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UnsavedChangesMessage trigger={isDirty} />
      <ErrorMessage trigger={patchError} />
      <SuccessMessage trigger={patchData} />
      <ProgramAdministration control={control} data={data} />
      <ProgramTransferAgent control={control} data={data} />
      <ProgramLegal control={control} data={data} />
      <ProgramAudit control={control} data={data} />
      <ProgramPrimeBroker control={control} data={data} />
      <ProgramCustodian control={control} data={data} />
      <ProgramAdvisor control={control} />
      <FormSaveButton />
    </form>
  );
};
