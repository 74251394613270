import {
  FormContainer,
  FormContainerRequiredHeader,
} from '@common/form-inputs';
import { FormControlHybridInput } from '@common/form-inputs/form-control-hybrid-input';
import { useLayoutForm } from '@core/layout/layout-form';
import React from 'react';
import PropTypes from 'prop-types';

export const ProgramAveragePortfolioExposure = (props) => {
  const { rowStyles, colStyle } = useLayoutForm();
  const { data } = props;

  const fields = {
    avgLong: (
      <FormControlHybridInput
        name="avgLong"
        label="Long"
        value={data.avgLong}
        {...props}
      />
    ),
    avgShort: (
      <FormControlHybridInput
        name="avgShort"
        label="Short"
        value={data.avgShort}
        {...props}
      />
    ),
    avgNet: (
      <FormControlHybridInput
        name="avgNet"
        label="Net"
        value={data.avgNet}
        {...props}
      />
    ),
  };

  return (
    <FormContainer>
      <FormContainerRequiredHeader title="Average Portfolio Exposure" />
      <div className={rowStyles}>
        <div className={colStyle}>{fields.avgLong}</div>
      </div>
      <div className={rowStyles}>
        <div className={colStyle}>{fields.avgShort}</div>
      </div>
      <div className={rowStyles}>
        <div className={colStyle}>{fields.avgNet}</div>
      </div>
    </FormContainer>
  );
};

ProgramAveragePortfolioExposure.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
