import { Button, Checkbox } from '@backstop/react-core';
import { GenericTable } from '@common/table/generic-table';
import React, { useState } from 'react';
import { useTempAccessTable } from './hooks';

import { Table as BsgTable } from '@backstop/grid-view';
import { Navigate } from 'react-router-dom';
import { stringToJsDate } from '@api/helperFunctions';

export const TempAccessTable = () => {
  const {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    setCheckVal,
  } = useTempAccessTable();

  return (
    <>
      <Checkbox
        name="showExpired"
        label="Show expired subscriptions"
        onChange={setCheckVal}
      />
      <GenericTable
        rows={data}
        cols={{
          email: { value: 'email', label: 'Email', columnLabel: 'Email' },
          product: {
            value: 'product',
            label: 'Product',
            columnLabel: 'Product',
          },
          expire: {
            value: 'expire',
            label: 'Expiration Date',
            columnLabel: 'Expiration Date',
          },
          action: {
            value: 'action',
            label: 'action',
            columnLabel: '',
          },
        }}
        cellComponent={TempAccessTableCell}
        totalElements={meta?.totalElements}
        isLoading={loading}
        showPagination={true}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        currentPage={currentPage}
      />
    </>
  );
};

const TempAccessTableCell = (props) => {
  const [r, setR] = useState(false);

  if (props.column.name === 'action') {
    if (r) {
      return (
        <Navigate
          to="/admin/tempAccess/revoke"
          state={{ id: props.row.id, email: props.row.email }}
        />
      );
    }
    let expireDate = stringToJsDate(props.row.expire, 'YYYY-MM-DD', '-');
    let currentDate = Date.now();
    let isExpired = currentDate > expireDate;
    if (isExpired) {
      return <BsgTable.Cell>Expired</BsgTable.Cell>;
    }
    return (
      <BsgTable.Cell>
        <Button version="v3" kind="text" onClick={() => setR(true)}>
          Revoke Access
        </Button>
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
