import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from '@backstop/react-core';
import { useHoldingsForm } from './hooks';

export const HoldingDeleteModal = () => {
  const { id: progId, holdId } = useParams();

  const navigate = useNavigate();

  const { deleteHolding } = useHoldingsForm();

  const confirmDelete = () => {
    deleteHolding(progId, holdId);
    navigate(`/program/${progId}/composition`);
  };

  return (
    <Modal
      version="v3"
      open={true}
      footer={[
        <Button
          version="v3"
          onClick={confirmDelete}
          kind="secondary"
          className="bsg-mr3"
        >
          Yes
        </Button>,
        <Button
          version="v3"
          onClick={() => navigate(`/program/${progId}/composition`)}
          kind="cancel"
          className="bsg-mr3"
        >
          Cancel
        </Button>,
      ]}
    >
      Are you sure you want to delete this holding?
    </Modal>
  );
};
