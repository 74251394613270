import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Loader } from '@common/loader';
import { GenericTable } from '@common/table/generic-table';
import { Table as BsgTable } from '@backstop/grid-view';
import { useDailyDataTable } from './hooks';

export const DailyDataTable = () => {
  const progId = useParams().id;

  const {
    data,
    meta,
    loading,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  } = useDailyDataTable(progId);
  if (loading) {
    return <Loader />;
  }

  return (
    <GenericTable
      rows={data}
      cols={{
        date: { value: 'date', label: 'Date', columnLabel: 'Date' },
        ror: { value: 'ror', label: 'ROR', columnLabel: 'ROR' },
        vami: { value: 'vami', label: 'VAMI', columnLabel: 'VAMI' },
      }}
      cellComponent={DailyDataTableCell}
      totalElements={meta?.totalElements}
      pageSize={pageSize}
      currentPage={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      showPagination={true}
      isLoading={loading}
    />
  );
};

const DailyDataTableCell = (props) => {
  let progId = props.row.progId;
  let date = props.row.date;
  if (props.column.name === 'date') {
    return (
      <BsgTable.Cell>
        <Link to={`/program/${progId}/dailyData/${date}`}>{date}</Link>
      </BsgTable.Cell>
    );
  }
  return <BsgTable.Cell {...props} />;
};
