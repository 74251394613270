import React from 'react';
import { CompanyInformationForm } from '../tabs/tabs/company-information/company-information-form';

export const CreateCompanyForm = () => {
  return (
    <>
      <h1 style={{ fontSize: '24px', padding: '24px' }}>Create New Company</h1>
      <CompanyInformationForm />
    </>
  );
};
